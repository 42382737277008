import styled from "styled-components";

export const TrainingStepsStyle = styled.div`

  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 58px;
    padding: 11.5px 24px;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: -1.5px;
  }
`;
