import React from "react";
import {Route, Routes} from "react-router-dom";
import UserConfirm from "./screen/UserConfirm";
import RegisterMaster from "./screen/RegisterMaster";
import RegisterDoctor from "./screen/RegisterDoctor";
import PreRegistrationBachelor from "./screen/PreRegistrationBachelor";
import UserHeader from "../components/PrivateHeader/UserHeader";
import Footer from "../../public/components/Footer/Footer";


export default function AdminRegisterRoutes() {

    return (
        <>
            <UserHeader/>
            {/*<div className="header-image">*/}
            {/*    <div className="header-shadow">*/}
            {/*        <div className="header-image-text">*/}
            {/*            Элсэлт*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="page-content">
                <div className="content pb-4">
                    <div className="container">
                        <Routes>
                            <Route path=":admitId/bachelor/pre" element={<PreRegistrationBachelor/>}/>
                            <Route path=":admitId/info" element={<UserConfirm/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
