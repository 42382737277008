import { Form, Input, Button, Select } from "antd";
import { RiDeleteBin5Line, RiAddCircleFill } from "react-icons/ri";
import { UserDisability, UserLanguageUI } from "../entity/admit.model";
import { admitService } from "../service/admit.service";
import { useEffect, useState } from "react";
import CustomLoader from "../../../public/components/CustomLoader";

const { Option } = Select;

export default function LanguageLevel() {
  const [language, setLanguage] = useState<any>();
  const [userLanguage, setUserLanguage] = useState<UserLanguageUI[]>([{}]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    admitService.getLanguage().then((res) => {
      setLanguage(res.countries);
      admitService.getUserLanguage().then((res) => {
        if (res.userLanguages.length !== 0){
          setUserLanguage(
              res.userLanguages.map((val: any) => {
                return {
                  ...val,
                  language: val.language.id,
                };
              })
          );
        }

        setLoading(false);
      });
    });
  }, []);

  const onFinish = (values: any) => {
    admitService.updateUserLanguage(values).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form name="userLanguage" layout="vertical" onFinish={onFinish}>
          <div className="register-box">
            <div className="row">
              <Form.List name="languages" initialValue={userLanguage}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "language"]}
                            label="Гадаад хэл"
                          >
                            <Select placeholder="Дарж сонгоно уу" allowClear>
                              {language.map((element: any) => (
                                <Option value={element.id} key={element.id}>
                                  {element.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "speaking"]}
                            label="Ярих"
                          >
                            <Select placeholder="Дарж сонгоно уу" allowClear>
                              <Option value={3}>Сайн</Option>
                              <Option value={2}>Дунд</Option>
                              <Option value={1}>Муу</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "reading"]}
                            label="Унших"
                          >
                            <Select placeholder="Дарж сонгоно уу" allowClear>
                              <Option value={3}>Сайн</Option>
                              <Option value={2}>Дунд</Option>
                              <Option value={1}>Муу</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "writing"]}
                            label="Бичих"
                          >
                            <Select placeholder="Дарж сонгоно уу" allowClear>
                              <Option value={3}>Сайн</Option>
                              <Option value={2}>Дунд</Option>
                              <Option value={1}>Муу</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "listening"]}
                            label="Сонсох"
                          >
                            <Select placeholder="Дарж сонгоно уу" allowClear>
                              <Option value={3}>Сайн</Option>
                              <Option value={2}>Дунд</Option>
                              <Option value={1}>Муу</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <RiDeleteBin5Line
                            size={32}
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      </>
                    ))}
                    <div className="col-md-12 d-flex">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<RiAddCircleFill size={20} />}
                          className="d-flex"
                        >
                          Нэмэх
                        </Button>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.List>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
