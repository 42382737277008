import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { GlobalUI, UserFamilyUI } from "../../entity/admit.model";
import { validations } from "../../../../../utils/validation/validations";
import { RiAddCircleFill, RiDeleteBin5Line } from "react-icons/ri";
import { paymentService } from "../../../payment/service/payment.service";
import { admitService } from "../../service/admit.service";
import CustomLoader from "../../../../public/components/CustomLoader";

const { Option } = Select;

export default function BachelorFamilyInformation() {
  const [initialInput, setInitialInput] = useState<UserFamilyUI[]>([{}]);
  const [familyType, setFamilyType] = useState<GlobalUI[]>();
  const [loading, setLoading] = useState(true);

  const onFinish = (values: any) => {
    admitService
      .updateUserFamilyInfo(values.family_information)
      .then((response) => {
        console.log(response);
      });
  };

  useEffect(() => {
    paymentService
      .getGlobalConstants("family_relation_type")
      .then((response) => {
        setFamilyType(response.globalConstants);
        admitService.getUserFamilyInfo().then((response) => {
            console.log(response.userFamilyInfos)
            if (response.userFamilyInfos.length !== 0){
                setInitialInput(
                    response.userFamilyInfos.map((element: any) => {
                        return {
                            ...element,
                            relationType: element.relationType.id,
                        };
                    })
                );
            }
          setLoading(false);
        });
      });
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form name="familyInformation" layout="vertical" onFinish={onFinish}>
          <div className="register-box">
            <div className="row">
              <Form.List name="family_information" initialValue={initialInput}>
                {(fields, { add, remove }) => {
                    console.log("initialInput", initialInput)
                  return (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <div className="col-md-12" key={index}>
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Item
                                  label="Харилцаа"
                                  {...fields}
                                  name={[field.name, "relationType"]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    placeholder="Дарж сонгоно уу"
                                    allowClear
                                  >
                                    {familyType?.map((element, index) => (
                                      <Option value={element.id} key={index}>
                                        {element.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "firstname"]}
                                  label="Овог"
                                  rules={[
                                    { required: true },
                                    ...validations.NAME_MN,
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "lastname"]}
                                  label="Нэр"
                                  rules={[
                                    { required: true },
                                    ...validations.NAME_MN,
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "registerNumber"]}
                                  label="Регистрийн дугаар"
                                  rules={[
                                    { required: true },
                                    ...validations.REGISTRATION_NUMBER,
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "email"]}
                                  label="Цахим шуудан"
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "mobile"]}
                                  label="Холбоо барих дугаар"
                                  rules={[
                                    { required: true },
                                    ...validations.ENTER_PHONE_NUMBER,
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "workState"]}
                                  label="Байгууллагын нэр, албан тушаал"
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="my-2 col-md-6 d-flex align-items-center">
                                <RiDeleteBin5Line
                                  size={32}
                                  onClick={() => {
                                    if (fields.length !== 1) {
                                      remove(field.name);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="col-md-12 d-flex">
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<RiAddCircleFill size={20} />}
                            className="d-flex"
                          >
                            Нэмэх
                          </Button>
                        </Form.Item>
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
