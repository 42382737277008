import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Radio,
  notification,
  Modal,
  Table,
  Divider,
  message,
  Alert,
} from "antd";
import { RiSaveFill, RiFileCopy2Fill, RiRefreshFill, RiCheckboxCircleLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { paymentService } from "../service/payment.service";
import { admitService } from "../../admit/service/admit.service";
import FloatButton from "../../../public/components/FloatButton";

import { IOModal } from "../../../../utils/Modal/Modal";

const copyToClipboard = (content: any) => {
  const el = document.createElement("textarea");
  el.value = content;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(el);
  message.success("Амжилттай хуулагдлаа");
};

const tableData = [
  {
    key: "1",
    bank: "ХХБанк",
    account: "2612007984",
    name: "СЭЗИСургууль",
  },
];

const columns = [
  {
    title: "Банк",
    dataIndex: "bank",
    key: "bank",
  },
  {
    title: "Дансны дугаар",
    dataIndex: "account",
    key: "account",
  },
  {
    title: "Дансны нэр",
    dataIndex: "name",
    key: "name",
  },
];
class QrImage extends Component<{ data: any }> {
  render() {
    let { data } = this.props;
    return (
      <img
        src={`data:image/jpeg;base64,${data}`}
        alt="Qr"
        style={{ width: "100%", maxWidth: "350px" }}
      />
    );
  }
}

export default function Payment() {
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const [qr, setQr] = useState();
  const [shortLink, setShortLink] = useState();
  const [invoiceCode, setInvoiceCode] = useState();
  const [sat, setSat] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [admissionGuide, setAdmissionGuide]: any = useState([]);
  const [admissionCreditGuide, setAdmissionCreditGuide]: any = useState([]);
  const [userBulkData, setUserBulkData] = useState<any>()

  const location = useLocation() as any;
  const admitId = location.state.admitId;
  const satCode = location.state.satCode;

  useEffect(() => {
    paymentService.currentInvoiceQpay(paymentId).then((response) => {
      if (response.invoiceData) {
        setQr(response.invoiceData.qr_image);
        setShortLink(response.invoiceData.qPay_shortUrl);
        setInvoiceCode(response.paymentInvoice.invoiceCode);
      } else {
        paymentService.createInvoiceQpay(paymentId).then((response) => {
          setQr(response.invoiceData.qr_image);
          setShortLink(response.invoiceData.qPay_shortUrl);
          setInvoiceCode(response.paymentInvoice.invoiceCode);
        });
      }
    });

    admitService.getAdmissionGuide(admitId).then((response) => {
      setAdmissionGuide(response.admissionGuide);
    });

    admitService.getAdmissionCreditGuide(admitId).then((response) => {
      setAdmissionCreditGuide(response.admissionGuide);
    });

    admitService.getUserInfo(admitId).then((response) => {
      setUserBulkData(response)
      setSat(response.admissionUser.satCode);
      setFirstName(response.userInfo.firstnameMn);
      setLastName(response.userInfo.lastnameMn);
      setMobile(response.userInfo.mobile);
      setEmail(response.admissionUser.user.email);
    });
  }, []);

  const handleCopy = () => {
    let exampleData =
      "" + sat + " " + lastName + " " + firstName + " " + mobile;

    copyToClipboard(exampleData);
  };

  const onChange = () => {
    setState(true);
  };

  const OnClick = () => {
    paymentService.checkInvoiceQpay(invoiceCode).then((response) => {
      if (userBulkData.admissionUser.state==="R" && response.checkResult.payment_status === "PAID") {
        IOModal.show({
          title: "Таны төлбөр амжилттай төлөгдлөө.",
          content: (
            <div className="mt-2 text-center">
              Таны урьдчилгаа сургалтын төлбөр амжилттай төлөгдлөө.
            </div>
          ),
          onCancel: () => {
            Modal.destroyAll();
            navigate("/user/home");
          },
          okButtonProps: { style: { display: "none" } },
        });
      } else {
        if (response.checkResult.payment_status === "PAID") {
          IOModal.show({
            title: "Та СЭЗИС-д амжилттай бүртгэгдлээ.",
            content: (
              <div className="mt-2 text-center">
                Та мэйл хаягаа шалгана уу
              </div>
            ),
            onCancel: (args) => {
              Modal.destroyAll();
              navigate("/user/home");
            },
            okButtonProps: { style: { display: "none" } },
          });
        } else {
          notification.error({
            message: "Та дансаар төлбөр төлсөн бол буцах товчыг дарна уу.",
            description: response.message,
          });
        }
      }
    });
  };

  const OnRefreshInvoice = () => {
    paymentService.createInvoiceQpay(paymentId).then((response) => {
      setQr(response.invoiceData.qr_image);
      setShortLink(response.invoiceData.qPay_shortUrl);
      setInvoiceCode(response.paymentInvoice.invoiceCode);
    });

    notification.info({
      message: "Амжилттай",
      description: "Таны Qpay амжилттай шинэчлэгдлээ",
    });
  };
  return (
    <div className="container">
      {userBulkData?.admission?.satExam && userBulkData?.admissionUser?.state === "R" ? (
        <>
          <h3 className="mb-4">Суудал баталгаажуулах сургалтын төлбөрийг төлөх заавар:</h3>
          <div className="row">
            <div className="mt-2 col-md-6">
              <div className="payment-card">
                <div className="payment-card-body">
                  <h5 className="mb-3">Төлбөр төлөх алхам:</h5>
                  <ol>
                    <li>Банкны аппликейшн нээх</li>
                    <li>Qpay хэсэг сонгох</li>
                    <li>
                      “Үйлчилгээний нөхцөлийг зөвшөөрч байна” гэсэн товч дарах
                    </li>
                    <li>QR кодыг уншуулах</li>
                    <li>Төлбөр төлсний дараа Баталгаажуулах товчийг дарна уу.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="mt-2 col-md-6">
              <div className="payment-card">
                <div className="payment-card-body">
                  <ul>
                    {admissionCreditGuide.length !== 0 ? (
                      admissionCreditGuide?.map((guide: any, index: number) => {
                        return guide?.type === "T" ? (
                          <h5 key={index}>{guide?.text}:</h5>
                        ) : (
                          <li key={index}>{guide?.text}</li>
                        );
                      })
                    ) : (
                      <>
                        <h5 className="mb-3">
                          Цагийн хѳтѳлбѳрт элсэгчдийн анхааралд:
                        </h5>
                        <ol>
                          <li>
                            Цагийн хѳтѳлбѳрт элсэхдээ бүртгэл баталгаажуулах
                            төлбөр төлнө.
                          </li>
                          <li>
                            Бүртгэл баталгаажуулах төлбөр 1'467'000 тѳгрѳг
                          </li>
                          <li>
                            Элсэлттэй холбоотой дэлгэрэнгүй мэдээллийг 70008085
                            - 1020 (Дотуур дугаар) утсаар авна уу.
                          </li>
                          <li>
                            Цагийн хѳтѳлбѳртэй холбоотой ерѳнхий мэдээллийг
                            Санхүү эдийн засгийн их сургуулийн Facebook пэйж
                            хуудасны Чатбот-с авна уу.
                          </li>
                        </ol>
                      </>
                    )}
                  </ul>
                  ЖИЧ: Элсэлтийн журамд зааснаар сургалтын төлбөрийг хувь хүний
                  данс руу хийхгүй байхгүй анхаарна уу. СЭЗИС-ийн албан ёсны
                  дансруу ороогүй гүйлгээг хувь хүн өөрөө хариуцхыг анхаарна уу.
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="col-md-6" />
            <div className="col-md-6 d-flex flex-column align-items-end ">
              <Radio
                onClick={onChange}
                checked={state}
                style={{ fontSize: "large" }}
              >
                Үйлчилгээний нөхцөлийг зөвшөөрч байна.
              </Radio>
            </div>

            <div className="col-12">
              <h5>Бүртгэлийн хураамж төлөх Qpay:</h5>
              {state ? (
                <>
                  <div className="row">
                    <div className="mt-2 col-md-6">
                      <div className="payment-card">
                        <div className="payment-card-body">
                          <h5 className="mb-3">Qpay-ээр төлбөр төлөх: </h5>
                          <QrImage data={qr} />
                          <p className="mt-3">
                            Qpay уншуулах боломжтой {" "}
                            <a href={shortLink}> банкууд</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 col-md-6">
                      <div className="payment-card">
                        <div className="payment-card-body">
                          <h5>Дансаар төлбөр төлөх:</h5>
                          <p className="mt-3">
                            СЭЗИС-ийн дараах дансанд урьдчилгаа төлбөрийг төлөх
                            боломжтой. Гүйлгээний утга дээр "БҮТ-ийн дугаар зай аваад ОВОГ НЭР, УТАСНЫ дугаар" заавал бичнэ үү.
                            <div className="p-2 my-3 bg-white">
                              <Divider className="mt-0 mb-3">
                                Гүйлгээний утга
                              </Divider>

                              <div className="mt-2 d-flex justify-content-between">
                                {sat && sat} {lastName && lastName}{" "}
                                {firstName && firstName} {mobile && mobile}
                                <a onClick={handleCopy}>
                                  <RiFileCopy2Fill size={24} />
                                </a>
                              </div>
                            </div>
                          </p>
                          <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            size="small"
                            scroll={{ x: "max-content", y: 500 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-4" />
            <div className="pt-3 col-md-2 d-flex">
              <Button
                className={` ${state ? "submit-btn" : "back-btn"}`}
                onClick={() => OnClick()}
                disabled={state ? false : true}
              >
                <RiSaveFill size={24} className="mr-2" /> Баталгаажуулах
              </Button>
            </div>
            <div className="pt-3 col-md-2 d-flex">
              {sat ? (
                <Link to="/user/home/score" className="card-link">
                  <a>
                    <Button
                      type="primary"
                      className={`mx-2 ${state ? "submit-btn" : "back-btn"}`}
                      disabled={state ? false : true}

                      // onClick={() => navigate(-1)}
                      // onClick={()=>}
                    >
                      Буцах
                    </Button>
                  </a>
                </Link>
              ) : (
                <Link to="/user/home" className="card-link">
                  <a>
                    <Button
                      type="primary"
                      className={`mx-2 ${state ? "submit-btn" : "back-btn"}`}
                      disabled={state ? false : true}

                      // onClick={() => navigate(-1)}
                      // onClick={()=>}
                    >
                      Буцах
                    </Button>
                  </a>
                </Link>
              )}
            </div>
            <div className="col-md-4" />
          </div>
        </>
      ) : (
        <>
          <h3 className="mb-4">Хураамж төлөх</h3>
          <div className="row">
            <div className="mt-2 col-md-6">
              <div className="payment-card">
                <div className="payment-card-body">
                  <h5>Хураамж төлөх алхам:</h5>
                  <ul>
                    <li>Банкны аппликейшн нээх</li>
                    <li>Qpay хэсэг сонгох</li>
                    <li>
                      “Үйлчилгээний нөхцөлийг зөвшөөрч байна” гэсэн товч дарах
                    </li>
                    <li>QR кодыг уншуулах</li>
                    <li>Төлбөр төлсний дараа Баталгаажуулах товчийг дарна уу.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-2 col-md-6">
              <div className="payment-card">
                <div className="payment-card-body">
                  <ul>
                    {admissionGuide.length !== 0 ? (
                      admissionGuide?.map((guide: any, index: number) => {
                        return guide?.type == "T" ? (
                          <h5 key={index}>{guide?.text}:</h5>
                        ) : (
                          <li key={index}>{guide?.text}</li>
                        );
                      })
                    ) : (
                      <>
                        <h5>Бүртгэлийн хураамж:</h5>
                        <li>
                          2022.05.16-аас өмнө 50 хувь хөнгөлөлттэй буюу 25,000
                          төгрөг (Хураамж 50,000 төгрөг)
                        </li>
                        <li>
                          Бүртгэлээ цуцлах тохиолдолд бүртгэлийн хураамж буцааж
                          олгогдохгүй.
                        </li>
                        <li>
                          Цахим зөвлөх үйлчилгээнд бүртгүүлснээр СЭЗИС-ийн
                          2022-2023 оны хичээлийн жилийн бакалаврын зэрэг олгох
                          үндсэн хөтөлбөрийн бүртгэлд үнэ төлбөргүй хамрагдана.
                        </li>
                      </>
                    )}
                  </ul>
                  {/* <h5>Бүртгэлийн хураамж:</h5>
                  <ul>
                    <li>
                      2022.05.16-аас өмнө 50 хувь хөнгөлөлттэй буюу 25,000
                      төгрөг (Хураамж 50,000 төгрөг)
                    </li>
                    <li>
                      Бүртгэлээ цуцлах тохиолдолд бүртгэлийн хураамж буцааж
                      олгогдохгүй.
                    </li>
                    <li>
                      Цахим зөвлөх үйлчилгээнд бүртгүүлснээр СЭЗИС-ийн 2022-2023
                      оны хичээлийн жилийн бакалаврын зэрэг олгох үндсэн
                      хөтөлбөрийн бүртгэлд үнэ төлбөргүй хамрагдана.
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="col-md-6">
              <h5>Бүртгэлийн хураамж төлөх Qpay:</h5>
              {state ? (
                <>
                  <QrImage data={qr} />
                  <p>
                    Qpay уншуулах боломжтой {" "}
                    <a href={shortLink}> банкууд</a>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-6 d-flex flex-column align-items-end">
              <Radio
                onClick={onChange}
                checked={state}
                style={{ fontSize: "large" }}
              >
                Үйлчилгээний нөхцөлийг зөвшөөрч байна.
              </Radio>
            </div>
            <div className="col-md-3" />
            <div className="col-md-3">
              <Button className="mt-3 submit-btn" onClick={() => OnClick()}>
                <RiSaveFill size={24} className="mr-2" /> Баталгаажуулах
              </Button>
            </div>
            <div className="col-md-3 ">
              <Button
                className="mt-3 secondary-btn"
                onClick={() => OnRefreshInvoice()}
              >
                <RiRefreshFill size={24} className="mr-2" /> Qpay шинэчлэх
              </Button>
            </div>
            <div className="col-md-3" />
            <div className="mt-3 col-12">
              <Alert
                message="Санамж"
                description="Хэрэв 15 минутын дотор төлбөр төлөөгүй тохиолдолд Qpay шинэчлэх товчийг дарна уу."
                type="info"
                showIcon
                className="mx-2 mb-3 w-100"
              />
            </div>
            <div className="col-md-5" />
            <div className="col-md-3">
              <Button className="mt-3 submit-btn" style={{opacity: 0.8}} onClick={() => {navigate("/user/home");}}>
                <RiCheckboxCircleLine size={24} className="mr-2" /> Дуусгах
              </Button>
            </div>

          </div>
        </>
      )}
      <FloatButton />
    </div>
  );
}
