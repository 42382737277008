import { Button, DatePicker, Form, Input, Select, InputNumber } from "antd";
import { useEffect, useMemo, useState } from "react";
import { RiAddCircleFill, RiDeleteBin5Line } from "react-icons/ri";
import { paymentService } from "../../payment/service/payment.service";
import {
  Addressing,
  CountriesUI,
  Country,
  GlobalUI,
  UserEducationUI,
} from "../entity/admit.model";
import { admitService } from "../service/admit.service";
import moment from "moment";
import { validations } from "../../../../utils/validation/validations";
import { useBootstrapBreakpoints } from "react-bootstrap/ThemeProvider";
import CustomLoader from "../../../public/components/CustomLoader";

const { Option } = Select;

export default function GraduationInformation() {
  const [degrees, setDegrees] = useState<GlobalUI[]>();
  const [professionVisible, setProfessionVisible] = useState([false]);
  const [profession, setProfession] = useState();
  const [countries, setCountries] = useState<Country[]>();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState([false]);
  const [mn, setMn] = useState<number>();
  const [addressing, setAddressing] = useState<Addressing[]>();
  const [initialInput, setInitialInput] = useState<UserEducationUI[]>([{}]);

  async function onFinish(values: any) {
    // let date = moment(values.userEducation[0].graduatedYear).format("YYYY-MM-DD")
    admitService.updateUserEducation(values.user_education).then((response) => {
      console.log(response);
    });
  }

  useEffect(() => {
    paymentService
      .getGlobalConstants("user_education_degree_type")
      .then((response) => {
        setDegrees(response.globalConstants);
        let professionId = 0;
        response.globalConstants.forEach((element: any) => {
          if (element.name === "Бүрэн дунд") {
            setProfession(element.id);
            professionId = element.id;
          }
        });
        admitService.getCountries().then((response) => {
          setCountries(response.countries);
          let mnId = 0;
          response.countries.forEach((element) => {
            if (element.shortName === "mn") {
              setMn(element.id);
              mnId = element.id;
            }
          });
          admitService.getAddressing("0").then((response) => {
            setAddressing(response.addressings);
            admitService.getUserEducation().then((response) => {
              console.log(response);
              if (response.userEducations.length !== 0) {
                setInitialInput(
                  response.userEducations.map((element: any, index: number) => {
                    if (element.country.id === mnId) {
                      let userCountryCheck = visible;
                      userCountryCheck[index] = true;
                      setVisible([...userCountryCheck]);
                    }
                    if (element.degree.id !== professionId) {
                      let element = professionVisible;
                      element[index] = true;
                      setProfessionVisible([...element]);
                    }
                    return {
                      ...element,
                      country: element.country.id,
                      degree: element.degree.id,
                      addressing: element.addressing?.id,
                    };
                  })
                );
              }
              setLoading(false);
            });
          });
        });
      });
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form name="userEducation" layout="vertical" onFinish={onFinish}>
          <div className="register-box">
            <div className="row">
              <Form.List name="user_education" initialValue={initialInput}>
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => {
                        const onChange = (value: number) => {
                          if (value === mn) {
                            let element = visible;
                            element[index] = true;
                            setVisible([...element]);
                          } else {
                            let element = visible;
                            element[index] = false;
                            setVisible([...element]);
                          }
                        };

                        const onChangeProfession = (value: number) => {
                          if (value !== profession) {
                            let element = professionVisible;
                            element[index] = true;
                            setProfessionVisible([...element]);
                          } else {
                            let element = professionVisible;
                            element[index] = false;
                            setProfessionVisible([...element]);
                          }
                        };
                        return (
                          <div className="col-md-12" key={index}>
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "country"]}
                                  label="Төгссөн сургуулийн улс сонгох"
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    placeholder="Дарж сонгоно уу"
                                    allowClear
                                    onSelect={onChange}
                                  >
                                    {countries?.map((country) => (
                                      <Option
                                        value={country.id}
                                        key={country.id}
                                      >
                                        {country.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                              {visible[index] && (
                                <>
                                  <div className="col-md-6">
                                    <Form.Item
                                      name={[field.name, "addressing"]}
                                      {...fields}
                                      label="Аймаг,хот сонгох"
                                      rules={[{ required: true }]}
                                    >
                                      <Select
                                        placeholder="Дарж сонгоно уу"
                                        allowClear
                                      >
                                        {addressing?.map((address) => {
                                          return (
                                            <Option value={address.id}>
                                              {address.name}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </>
                              )}
                              <div className="col-md-6">
                                <Form.Item
                                  name={[field.name, "eduOrgName"]}
                                  {...fields}
                                  label="Сургууль"
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "certificateNumber"]}
                                  label="Гэрчилгээний дугаар"
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "degree"]}
                                  label="Зэрэг"
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    placeholder="Дарж сонгоно уу"
                                    allowClear
                                    onSelect={onChangeProfession}
                                  >
                                    {degrees?.map((degree) => {
                                      return (
                                        <Option value={degree.id}>
                                          {degree.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                              {professionVisible[index] ? (
                                <div className="col-md-6">
                                  <Form.Item
                                    {...fields}
                                    name={[field.name, "profession"]}
                                    label="Мэргэжил"
                                    rules={[{ required: true }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "graduatedYear"]}
                                  label="Төгссөн он"
                                  rules={[
                                    { required: true },
                                    ...validations.GRADUATION_YEAR,
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "gradeAvg"]}
                                  label="Голч дүн /бүхэл тоо оруулна уу!/"
                                  rules={[{ required: true }]}
                                >
                                  <InputNumber precision={0} />
                                </Form.Item>
                              </div>
                              <div className="my-2 col-md-6 d-flex align-items-center">
                                <RiDeleteBin5Line
                                  size={32}
                                  onClick={() => {
                                    if (fields.length !== 1) {
                                      remove(field.name);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="col-md-12 d-flex">
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              setVisible([...visible, false]);
                            }}
                            block
                            icon={<RiAddCircleFill size={20} />}
                            className="d-flex"
                          >
                            Нэмэх
                          </Button>
                        </Form.Item>
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
