import styled from "styled-components";

export const UserScoreTableStyle = styled.div`
  .ant-table-thead > tr > th {
    background-color: #fded72;
    color: #0132a6;
    font-weight: bold;
    overflow-wrap: initial;
  }
  .ant-table-content {
    border-radius: 8px;
  }
  .ant-table-container,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table-container,
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
  }

  .ant-table-cell {
    text-align: center;
  }
  .ant-table-tbody {
    background-color: #fafafc;
  }
  .ant-table-summary {
    background-color: #ccd6ed;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 4px;
  }
  .ant-statistic-content {
    font-size: 14px;
  }
`;
