import styled from "styled-components";

export const LoginStyle = styled.div`
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
  }

  .ant-col-24.ant-form-item-label>label, .ant-col-xl-24.ant-form-item-label>label, .ant-form-vertical .ant-form-item-label>label {
    margin: 0;
    font-weight: 700;
    font-size: 12px;
  }
  .text-info {
    color: #0132a6 !important;
    font-weight: 500;
    font-size: 11px;
  }

  .register-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgba(52, 91, 184, 1) !important;
    background-color: #ffffff !important;
    height: 48px !important;
    border-radius: 8px !important;
    border-color: rgba(52, 91, 184, 1);
    font-weight: 700;
    font-size: 15px;
  }
  .submit-btn{
    font-weight: 700;
    font-size: 15px;
  }

  .ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #C2C9D1;
    border-radius: 8px;
    transition: all .3s;
  }

  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #C2C9D1;
    border-radius: 8px;
    transition: all .3s;
    display: inline-flex;
  }
`;
