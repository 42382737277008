import { Image, Space } from "antd";
import { constants } from "../../../../utils/constant/constants";
import { FilePdfOutlined } from "@ant-design/icons";

export default function ImageList(props: { data: any }) {
  const baseUrl = constants.BASE_URL.slice(0, -1);

  return (
    <div className="row">
      {props.data.map((image: any, index: number) => (
        <div className="col-lg-4" key={index}>
          {image.isPdf ? (
            <div style={{ textAlign: "center" }}>
              <iframe
                src={baseUrl + image.filePath}
                height="282px"
                width="100%"
                title="PDF"
                style={{ border: 0 }}
              ></iframe>
              <a
                href={baseUrl + image.filePath}
                target="_blank"
                rel="noreferrer"
              >
                <FilePdfOutlined
                  style={{
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                />{" "}
                Харах
              </a>
            </div>
          ) : (
            <Image width={200} src={baseUrl + image.filePath} />
          )}
        </div>
      ))}
    </div>
  );
}
