import {
  RiGitRepositoryLine,
  RiBankLine,
  RiBookMarkLine,
  RiCoinsLine,
  RiFileTextLine,
  RiTimeLine,
  RiTranslate2,
  RiArrowRightCircleLine,
  RiArrowLeftCircleLine,
} from "react-icons/ri";
import { useEffect, useState } from "react";
import { Profession } from "../entity/information.model";
import { constants } from "../../../../utils/constant/constants";
import { Select } from "antd";
const { Option } = Select;

export default function RegistrationInformationCard(props: {
  profession: Profession;
}) {
  let { profession } = props;
  const [current, setCurrent] = useState(0);
  const [imageUrl, setImageUrl] = useState<string>();
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  let degreeType = "";
  let degreeTypeCount = 0;

  profession.degreeStudyPrograms.forEach((element) => {
    if (!degreeType.includes(element.degreeProgram.name)) {
      degreeType += element.degreeProgram.name + "/";
    }
    degreeTypeCount += 1;
  });

  useEffect(() => {
    if (profession.images.length !== 0) {
      setImageUrl(constants.IMAGE_URL + profession.images[0].filePath);
    }
  }, []);

  const handleChange = (value: string) => {
    setCurrent(parseInt(value));
  };

  return (
    <div className="border-3 border-blue-400 rounded mt-5 ">
      {profession.degreeStudyPrograms.length !== 0 ? (
        <>
          <div className="row m-0">
            <div className="col-md-5 d-flex justify-content-between flex-column pt-3 pb-4 px-4">
              <h2 className="font-weight-bold">{profession.name}</h2>
              <div>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-icon">
                    <RiGitRepositoryLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2">
                    Боловсролын зэрэг: {profession.degree.name}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-icon">
                    <RiBankLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2">
                    Хөтөлбөрийн тоо: {degreeTypeCount}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-icon">
                    <RiBankLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2">
                    Хөтөлбөрийн төрөл:
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <Select
                  defaultValue={
                    profession.degreeStudyPrograms[current].degreeProgram.name
                  }
                  style={{ width: "100%" }}
                  onChange={handleChange}
                >
                  {profession.degreeStudyPrograms.map((element, index) => (
                    <Option value={index}>{element.degreeProgram.name}</Option>
                  ))}
                </Select>
                {/*{current > 0 ? (*/}
                {/*    <div className="register-icon" onClick={() => prev()}>*/}
                {/*        <RiArrowLeftCircleLine size={24}/>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div className="register-icon-disabled">*/}
                {/*        <RiArrowLeftCircleLine size={24}/>*/}
                {/*    </div>*/}
                {/*)*/}
                {/*}*/}
                {/*<div className="body1 font-weight-bold text-blue-400 mx-2">*/}
                {/*    {*/}
                {/*        profession.degreeStudyPrograms[current].degreeProgram.name*/}
                {/*    }*/}
                {/*</div>*/}
                {/*{current < profession.degreeStudyPrograms.length - 1 ? (*/}
                {/*    <div className="register-icon" onClick={() => next()}>*/}
                {/*        <RiArrowRightCircleLine size={24}/>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div className="register-icon-disabled">*/}
                {/*        <RiArrowRightCircleLine size={24}/>*/}
                {/*    </div>*/}
                {/*)}*/}
              </div>
            </div>
            <div
              className="col-md-7 px-4 p-0 register-card-image"
              style={{
                minHeight: "351px",
                backgroundImage: `url(${imageUrl}`,
                backgroundPosition: "center",
              }}
            >
              <>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-sub-icon">
                    <RiBookMarkLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2 text-white">
                    {
                      profession.degreeStudyPrograms[current].briefInfos
                        .description
                    }
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-sub-icon">
                    <RiCoinsLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2 text-white">
                    {profession.degreeStudyPrograms[current].briefInfos.payment}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-sub-icon">
                    <RiFileTextLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2 text-white">
                    {
                      profession.degreeStudyPrograms[current].briefInfos
                        .requirement
                    }
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="register-sub-icon">
                    <RiTimeLine size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2 text-white">
                    {profession.degreeStudyPrograms[current].briefInfos.program}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3 mb-3">
                  <div className="register-sub-icon">
                    <RiTranslate2 size={32} />
                  </div>
                  <div className="body1 font-weight-bold ml-2  text-white">
                    {
                      profession.degreeStudyPrograms[current].briefInfos
                        .language
                    }
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
