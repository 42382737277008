import { notification } from "antd";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { constants } from "../constant/constants";
import { authService } from "../../module/public/auth/service/auth.service";
import storeService from "./store";
import {TResponse} from "../service/response.model";

export const api = axios.create({
  baseURL: constants.API_URL,
});

const excludeUrls: string[] = ["/register", "/register/confirm", '/login', '/_security/password/reset', '/open/*'];
const errorExcludeUrls: string[] = ["/auth/login", "/auth/recover", "/register/user"];

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = storeService.getAuth();
    if (config.headers != null && !excludeUrls.includes(config.url!)) {
      config.headers.Authorization = `Bearer ${token?.token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error.response);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse<TResponse<any> | any>) => {
    if (response.config.responseType === 'arraybuffer'){
      return Promise.resolve(response.data);
    }

    if (response.data.status_code === 200 || response.data.status_code === 201) {
        // notification.success({
        //     message: "Амжилттай",
        //     description: response.data.message,
        // });
      return Promise.resolve(response.data.data);
    } else {
        if (
            response.data.status_code === 403 ||
            response.data.status_code === 401
        ) {
            authService.logout();
        } else if (
            response.data.status_code !== 404 &&
            response.config.url &&
            !errorExcludeUrls.includes(response.config.url)
        ) {
            notification.error({
                message: "Алдаа",
                description: response.data.message,
            });

        } else if (
            response.data.status_code === 404
        ) {
            notification.error({
                message: "Алдаа",
                description: response.data.message,
          });

      }
      return Promise.reject(response.data);
    }
  },
  (error: AxiosError) => {
    return Promise.reject(error.response?.data);
  }
);
