import {useForm} from "antd/lib/form/Form";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Form, Input, Modal} from "antd";
import React, {useState} from "react";
import {authService} from "../service/auth.service";
import {PasswordReset} from "../entity/auth.model";
import {IOModal} from "../../../../utils/Modal/Modal";
import {RiRefreshLine} from "react-icons/ri"
import {validations} from "../../../../utils/validation/validations";

export const loginRoute = "/login";

export default function RecoverPassword() {
    let {state} = useLocation() as any;
    let email = state?.email as string;
    const [form] = useForm();
    const [form1] = useForm();
    const navigate = useNavigate();

    const RecoverForm = (token: any) => {
        const onFinishChangeForm = (values: any) => {
            let passwordRecovery: PasswordReset = {
                reset:{
                    password: values.password,
                    token: token.token
                }
            }
            authService.resetPassword(passwordRecovery).then((response)=>{
                Modal.destroyAll()
                navigate('/login')
            })
        }
        return(
            <Form name="change-password-form" onFinish={onFinishChangeForm} form={form1}>
                <div className="row mt-3">
                    <div className="form-group has-feedback has-feedback-left">
                        <Form.Item
                            label="Шинэ нууц үг"
                            name="password"
                            rules={[...validations.ENTER_PASSWORD, ...validations.PASSWORD]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </div>

                    <div className="form-group has-feedback has-feedback-left">
                        <Form.Item
                            label="Нууц үг давтах"
                            name="repeatPassword"
                            rules={[
                                ...validations.ENTER_PASSWORD,
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Нууц үг таарахгүй байна!"));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        )
    }

    const onFinish = (values: any) => {
        let passwordRecovery = {
            otp:{
                email: email,
                code: values.otp
            }
        }
        authService.recoverPassword(passwordRecovery).then((response)=>{
            IOModal.confirmForm({
                title: "Нууц үг сэргээх",
                okText: "Илгээх",
                content: <RecoverForm token={response.token}/>,
                okButtonProps: {form:'change-password-form', htmlType: 'submit'},
            })
        })
    };

    const recoverPasswordClick = () => {
        let passwordRecovery = {
            otp:{
                email: email,
                code: -1
            }
        }
        authService.recoverPassword(passwordRecovery).then((response)=>{
            navigate('/recover/password', {state:{email: email}})
        })
    }

    return (
        <Form className="d-flex justify-content-center" layout="vertical" name="pre-register-bachelor-form" onFinish={onFinish} form={form}>
            <div className="recover-card">
                <div className="register-card-body">
                    <p className="text-center">Таны бүртгэлтэй {email}-д ирсэн 4 оронтой нууц үгийг оруулж баталгаажуулана уу.</p>
                    <div className="panel panel-body login-form mt-3">
                        <div className="form-group has-feedback has-feedback-left">
                            <Form.Item name="otp" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                        </div>
                        <Button className="submit-btn mt-3" htmlType="submit">Илгээх</Button>
                        <a onClick={recoverPasswordClick}>
                            <h5 className="d-flex align-items-center justify-content-center text-info font-weight-bold mt-2">
                                <RiRefreshLine size={24} className="mr-2" />Дахин код авах
                            </h5>
                        </a>
                    </div>
                </div>

            </div>
        </Form>
    )
}