import { api } from "../../../../utils/helper/api";

const getNews = (degreeId: string | undefined): Promise<any> => {
  return api.get(`/_open/post/${degreeId}/widget/list`);
};

const getNews3 = (
  degreeId: string | undefined,
  admissionId: string | undefined
): Promise<any> => {
  return api.get(`/_open/post/${degreeId}/widget/list/${admissionId}`);
};

const getNewsAll = (degreeId: string | undefined): Promise<any> => {
  return api.get(`/_open/post/${degreeId}/widget/list2/all`);
};

const getNewsDetail = (id: string | undefined): Promise<any> => {
  return api.get("/_open/post/widget/detail/" + id);
};

export const widgetService = {
  getNews,
  getNews3,
  getNewsDetail,
  getNewsAll,
};
