import { Collapse } from "antd";
import { RegistrationAccordionStyleV1 } from "./RegistrationAccordionStyleV1";
import ImageCarousel from "./ImageCarousel";
import ImageList from "./ImageList";
import {useEffect, useState} from "react";
import { ReactComponent as DefaultImage } from "../../../../assets/default-image.svg";
import { constants } from "../../../../utils/constant/constants";

const { Panel } = Collapse;

const baseUrl = constants.BASE_URL.slice(0, -1);

export default function RegistrationAccordion(props: { data: any; type: any }) {
  const [key, setKey] = useState<number>(99);

  useEffect(()=>{
      setKey(0)
  },[])

  const onChange = (key: string | string[]) => {
    setKey(parseInt(key[key.length - 1]));
  };

  return (
    <div className="row">
      <div
        className="col-md-12"
      >
        <RegistrationAccordionStyleV1>
          <Collapse
              onChange={onChange}
              expandIconPosition={"right"}
          >
            {props.data.map((element: any, index: number) => {
                console.log(element.htmlContent)
              return (
                <Panel header={element.name} key={index}>
                    {props.type !== "Асуулт,Хариулт" &&
                    props.type !== "Мэргэжлийн танилцуулга" &&
                    props.type !== "Түгээмэл асуулт, хариулт" ? (
                        <>
                            {element === undefined ? (
                                <div className="col-md-12">
                                    <DefaultImage style={{ width: "100%" }} />
                                </div>
                            ) : (
                                <div className="col-md-12">
                                    {element.images &&
                                    element.images[0] &&
                                    element.images[0].filePath.substr(
                                        element.images[0].filePath.length - 3
                                    ) == "pdf" ? (
                                        <iframe
                                            className="w-100"
                                            style={{ height: "600px" }}
                                            src={baseUrl + element.images[0].filePath}
                                        />
                                    ) : (
                                        <>
                                            {element.images ? (
                                                <ImageCarousel data={element.images} />
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    ) :
                        <>
                        <ImageList data={element.images} />
                        <div
                            dangerouslySetInnerHTML={{ __html: element.htmlContent }}
                        />
                    </>}

                </Panel>
              );
            })}
          </Collapse>
        </RegistrationAccordionStyleV1>
      </div>

    </div>
  );
}
