import styled from "styled-components";

export const UserProfessionSelectTableStyle = styled.div`
  .ant-table-thead>tr>th{
    background-color: #FDED72;
    color: #0132A6;
    font-weight: bold;
  }
  .ant-table-content{
    border-radius: 8px;
  }
  .ant-table-container, .ant-table-container table>thead>tr:first-child th:first-child{
    border-top-left-radius: 8px;
  }

  .ant-table-container, .ant-table-container table>thead>tr:first-child th:last-child{
    border-top-right-radius: 8px;
  }
  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before{
    display: none;
  }
  
  .ant-table-cell{
    text-align: center;
  }
  .ant-table-tbody{
    background-color: #FAFAFC;
  }
  .ant-table-summary{
    background-color: #CCD6ED;
  }
`;