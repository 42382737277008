import {Form, Radio} from "antd";
import { admitService } from "../service/admit.service";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomLoader from "../../../public/components/CustomLoader";
import {constants} from "../../../../utils/constant/constants";

export default function Regulation(props: { setCheckBox: any, satExam: any }) {
    const { admitId } = useParams();
    const [state, setState] = useState<boolean>(false);
    const [filePath, setFilePath] = useState<string|null>("");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        props.setCheckBox(true)
        admitService.getAdmissionConfig(admitId).then((response) => {
            if (response.admissions.filePath === "" || response.admissions.filePath === undefined){
                props.setCheckBox(false)
            }
            setFilePath(response.admissions.filePath)
        })
        setLoading(false);
    }, []);

    const onChange = () => {
        setState(true);
        props.setCheckBox(false)
    }

    return (
        <>
            {loading ? (
                <CustomLoader />
            ) : (
                <Form name="regulation" layout="vertical">
                    <div className="register-box">
                        <div className="row">
                            {
                                filePath !== "" && (
                                    <div className="col-md-12">
                                        <embed src={constants.BASE_URL + filePath} width="100%" height="700px"/>
                                        <div className="col-md-12 py-2">
                                            <Radio
                                                onClick={onChange}
                                                checked={state}
                                                style={{ fontSize: "large" }}
                                            >
                                                {props.satExam !== true ? "Та төлбөрөө төлөхөөсөө өмнө гэрээтэй танилцаж зөвшөөрсөн тохиолдолд check дээр дарна уу. /Цахим гэрээ бичмэл хувьтай дүйцнэ/": "Та элсэлтийн журамтай уншиж танилцан зөвшөөрсөн товчийг дарна уу"}
                                            </Radio>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </Form>
            )}
        </>
    );
}


