import { RiArrowRightLine } from "react-icons/ri";
import React from "react";
import { MoreButtonStyle } from "./style";
import { useParams } from "react-router-dom";

let nowDat = new Date("2021-05-23");
const nowDate = `${nowDat.toLocaleDateString("en-ca")}`;

const MoreButton = ({ uri, id }: any) => {
  // const { id } = useParams();

  return (
    <MoreButtonStyle>
      <>
        <a
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "regular",
          }}
          className="d-flex align-items-center justify-content-end"
          href={"/information/news/"+id}
        >
          Бүгдийг харах
           <RiArrowRightLine style={{ color: "#345BB8", marginLeft: "8px"}} />
        </a>
      </>
    </MoreButtonStyle>
  );
};
export default MoreButton;
