import { Table, Typography  } from "antd";
import { ColumnsType } from "antd/lib/table";

import { UserScoreTableStyle } from "./UserScoreTableStyle";
const { Text } = Typography;

export default function PublicScoreTable(props: { data: any }) {
  let columns: ColumnsType<any> = [
    {
      title: "Мэргэжил, чиглэл",
      dataIndex: "Мэргэжил",
      key: "Мэргэжил",
    },
    {
      title: "Хөтөлбөр",
      dataIndex: "Хөтөлбөр",
      key: "Хөтөлбөр",
    },

    {
      title: "Элссэн",
      dataIndex: "Элссэн",
      key: "Элссэн",
      width: "100px",
      render: (Элссэн, record) => {
        return {
          children: <>{Элссэн >= record.Нийт ? record.Нийт : Элссэн}</>,
        };
      },
    },
    {
      title: "Үлдсэн",
      dataIndex: "Үлдсэн",
      key: "Үлдсэн",
      width: "100px",
      render: (Үлдсэн, record) => {
        return {
          children: <>{Үлдсэн <= 0 ? 0 : Үлдсэн}</>,
        };
      },
    },
    {
      title: "Хяналтын тоо",
      dataIndex: "Нийт",
      key: "Нийт",
      width: "100px",
    },
  ];

  return (
    <UserScoreTableStyle>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={props.data}
        className="table table-responsive mb-5"
        pagination={false}
        rowClassName="table-row"
        summary={(pageData) => {
          let total = 0;
          let totalLeft = 0;
          let totalRegistered = 0;

          pageData.forEach(({ Нийт, Үлдсэн, Элссэн }) => {
            total += Нийт;
            totalLeft += Үлдсэн <= 0 ? 0 : Үлдсэн;
            totalRegistered += Элссэн >= Нийт ? Нийт : Элссэн;
          });

          return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Text>{totalRegistered}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text>{totalLeft}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text>{total}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
          );
        }}
      />
    </UserScoreTableStyle>
  );
}
