import {Route, Routes} from "react-router-dom";
import Payment from "./screen/Payment";
import Footer from "../../public/components/Footer/Footer";
import React from "react";
import UserHeader from "../components/PrivateHeader/UserHeader";
import PaymentTraining from "./screen/PaymentTraining";

export default function PaymentRoutes() {
    return (
        <>
            <UserHeader/>
            <div className="header-image-private">
                <div className="header-shadow">
                    <div className="header-image-text">
                        Төлбөр төлөлт
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="content pb-4">
                    <Routes>
                        <Route path=":paymentId/qpay" element={<Payment/>}/>
                        <Route path="training/:id" element={<PaymentTraining />}/>
                    </Routes>
                </div>
            </div>
            <Footer/>
        </>
    )
}
