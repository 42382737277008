import DateCard from "../date-card";
import ReactHtmlParser from "react-html-parser";
// import Slider from "../slider";
import { NewsStyle } from "../widget/style";
import Slider from "../slider";

export const WidgetDetailId = (props: any) => {
  const { pageData } = props;
  // console.log(pageData, "pageDatapageDatapageDatapageDatapageDatapageData");
  const imgBase = process.env.REACT_APP_FILE_HOST;
  const translate: any = pageData.Posts[0].translates[0];

  const imgSrc =
    pageData?.Posts[0].images[0] && pageData.Posts[0].images[0].filePath
      ? imgBase + pageData.Posts[0].images[0].filePath
      : null;
  return (
    <NewsStyle>
      <section id="news_list" className="news_list section">
        <div className="container mt-5">
          <div className="row gx-5">
            <div>
              <a>
                <img
                  style={{
                    display: "block",
                    objectFit: "cover",
                    borderRadius: "16px",
                    maxWidth: "100%",
                    objectPosition: "center center",
                  }}
                  src={`${imgSrc}`}
                  width="100%"
                  alt="image-alt-text"
                />
              </a>
              <br />
              <h1 style={{ fontSize: "3vh" }}>{translate.title}</h1>
              <DateCard newsDate={pageData?.Posts[0].timePublished.date} />
            </div>

            {pageData?.Posts[0]?.youtube && (
              <>
                <iframe
                  style={{ marginBottom: "20px" }}
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${pageData?.Posts[0]?.youtube}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </>
            )}

            <br />
            {pageData.Posts[0].modes.map((postMode: any, index: number) => {
              return (
                <div className={"mb-4"} key={index}>
                  {postMode.modeType == "G" && (
                    <>{<Slider postMode={postMode} />}</>
                  )}
                  {postMode.modeType == "A" && (
                    <>{ReactHtmlParser(postMode.translates[0].htmlContent)}</>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </NewsStyle>
  );
};
