import { Image } from "antd";
import { useState } from "react";
import { constants } from "../../../../utils/constant/constants";
import { ReactComponent as DefaultImage } from "../../../../assets/default-image.svg";

export default function ImageCarousel(props: { data: any }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      {props.data !== undefined ? (
        <Image
          preview={{ visible: false }}
          src={constants.IMAGE_URL + props.data[0]?.filePath}
          onClick={() => setVisible(true)}
        />
      ) : (
        <>
          <DefaultImage />
        </>
      )}
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {props.data.map((image: any, index: number) => {
            return (
              <Image src={constants.IMAGE_URL + image?.filePath} key={index} />
            );
          })}
        </Image.PreviewGroup>
      </div>
    </>
  );
}
