import React from "react";
import {Route, Routes} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Login from "./auth/screen/Login";
import RegisterUser from "./auth/screen/RegisterUser";
import RecoverPassword from "./auth/screen/RecoverPassword";
import UserHeader from "../private/components/PrivateHeader/UserHeader";
import InformationRoutes from "./information/Routes";
import Main from "./home/screen/Main";
import Info from "./home/screen/Info";

export const publicRoute = "/auth";

export const getPublicRoute = (path: string): string => `${publicRoute}${path}`;

export default function PublicRoutes() {
    return (
        <>
            <UserHeader/>
            {/*<div className="header-image">*/}
            {/*    <div className="header-shadow">*/}
            {/*        <div className="header-image-text">*/}
            {/*            Элсэлт*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="page-content">
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/info" element={<Info />}/>
                    <Route path="/information/*" element={<InformationRoutes/>}/>
                </Routes>
            </div>

            <Routes>
                <Route path="/login" element={
                    <div className="page-content-login">
                        <div className="content">
                            <div className="row">
                                <div className="col-lg-4"/>
                                <div className="col-lg-4">
                                    <Login/>
                                </div>
                                <div className="col-lg-4"/>
                            </div>
                        </div>
                    </div>
                }/>
                <Route path="/recover/password" element={
                    <div className="page-content-login">
                        <div className="content">
                            <div className="row">
                                <div className="col-lg-4"/>
                                <div className="col-lg-4">
                                    <RecoverPassword/>
                                </div>
                                <div className="col-lg-4"/>
                            </div>
                        </div>
                    </div>
                }/>
                <Route path="/register" element={
                    <div className="page-content-login">
                        <div className="content">
                            <div className="row">
                                <div className="col-lg-4"/>
                                <div className="col-lg-4">
                                    <RegisterUser/>
                                </div>
                                <div className="col-lg-4"/>
                            </div>
                        </div>
                    </div>
                }/>
                <Route path="/register/:tokenId" element={
                    <div className="page-content-login">
                        <div className="content">
                            <div className="row">
                                <div className="col-lg-4"/>
                                <div className="col-lg-4">
                                    <RegisterUser/>
                                </div>
                                <div className="col-lg-4"/>
                            </div>
                        </div>
                    </div>
                }/>
            </Routes>
            <Footer/>
        </>

    );
}
