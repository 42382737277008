import { InstructionAccordionStyle } from "./InstructionAccordionStyle";
import { useState } from "react";
import { IFrameStyle } from "./IFrameStyle";
import { useNavigate } from "react-router-dom";

export default function InstructionAccordion(props: { data: any }) {
  const [key, setKey] = useState<number>(0);
  const navigate = useNavigate();

  const onChange = (key: number) => {
    setKey(key);
  };

  return (
    <div className="row">
      {props.data && (
        <>
          <div className="col-md-4">
            <InstructionAccordionStyle>
              {props.data?.map((element: any, index: number) => (
                <a onClick={() => onChange(index)} key={index}>
                  <div
                    className={`${index === key ? "text-enabled" : " text"}`}
                  >
                    {element.name}
                  </div>
                </a>
              ))}
              <div
                onClick={() => {
                  onChange(-1);
                  navigate("/info");
                }}
              >
                <div className="text">Шинэ элсэгчийн төлбөрийн мэдээлэл</div>
              </div>
            </InstructionAccordionStyle>
          </div>
          <div className="col-md-8">
            {props.data[key] === undefined ? (
              <></>
            ) : (
              <IFrameStyle>
                <div>{props.data[key].content}</div>
              </IFrameStyle>
            )}
          </div>
        </>
      )}
    </div>
  );
}
