import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { helper } from "../../utils/helper/helper";
import PaymentRoutes from "./payment/Routes";
import HomeRoutes from "./home/Routes";
import AdminRegisterRoutes from "./admit/Routes";

export default function PrivateRoutes() {
  const navigate = useNavigate();
  useEffect(() => {
    let isLoggedIn = helper.isLoggedIn();
    if (!isLoggedIn) {
      navigate("/login", { state: { fromLogin: false } });
    }
  }, []);

  return (
    <Routes>
      <Route path="/home/*" element={<HomeRoutes />} />
      <Route path="/payment/*" element={<PaymentRoutes />} />
      <Route path="/admit/*" element={<AdminRegisterRoutes />} />
    </Routes>
  );
}
