import styled from "styled-components";

export const ProfessionInformationStyle = styled.div`
  @media screen and (max-width: 918px) {
    .mobile-responsive{
      display: block !important;
    }
  }
  h2 {
    font-size: 16px;
  }
  
  .selector{
    max-width: 250px;
    min-width: 250px;
  }

  .register-icon {
    background-color: #ffffff;
    color: #345bb8;
    min-width: 24px;
    height: 24px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .body1{
    font-size: 12px;
  }

  .border-bottom-1 {
    border-bottom: 1px solid #E4E4EB;
  }
`;
