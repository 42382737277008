import { api } from "../../../../utils/helper/api";
import { AdmissionList } from "../entity/home.model";

const admissionActiveList = (): Promise<AdmissionList> => {
  return api.get("/admission/active/list");
};

const admissionSatCheck = (): Promise<any> => {
  return api.get("/_open/admission/sat/check");
};

const getAdmissionRegisterList = (
  id: string | undefined,
  admissionId: string | undefined
): Promise<any> => {
  return api.get(`/_open/ranking/${id}/admission-register/${admissionId}/list`);
};

const getAdmissionProfessionList = (
  rankingId: string | undefined
): Promise<any> => {
  return api.get(`/admission/register/for/ranking/kind/${rankingId}`);
};

const getRankingKindTiming = (
  admissionId: number | undefined
): Promise<any> => {
  return api.get(`/_open/ranking/kind/timing/details/${admissionId}`);
};

export const homeService = {
  admissionActiveList,
  admissionSatCheck,
  getAdmissionRegisterList,
  getRankingKindTiming,
  getAdmissionProfessionList,
};
