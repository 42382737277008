import styled from "styled-components";

export const MainStyle = styled.div`
  @media screen and (max-width: 918px) {
    h1 {
      font-size: 24px;
    }
  }
  .ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: 0px solid #d9d9d9;
  }
  .ant-collapse
    > .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header {
    color: #0132a6;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 14px;
  }
  .ant-collapse-content {
    color: #141719;
    background-color: #fff;
    border-top: 0px solid #d9d9d9;
    font-weight: 400;
    font-size: 12px;
  }

  .ant-radio-group {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    margin-bottom: 16px;
  }

  .ant-radio {
    top: 0;
  }

  .ant-radio-wrapper {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 8px 0 0;
    cursor: pointer;
  }

  .ant-radio-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #0132a6;
    border-radius: 50%;
    visibility: hidden;
    -webkit-animation: antRadioEffect 0.36s ease-in-out;
    animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    content: "";
  }

  .ant-radio-inner {
    border: 2px solid #e4e4eb !important;
  }

  .ant-radio-inner::after {
    background-color: #ffffff;
    width: 16px !important;
    height: 16px !important;
    margin-top: -8px !important;
    margin-left: -8px !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border: 4px solid #0132a6 !important;
  }
  .fixed-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #facc15;
    border: none;
    color: #141719;
    padding: 16px 24px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 999;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
  }

  .fixed-button:hover {
    background-color: #eab308;
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;
