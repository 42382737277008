import React, { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { Button, Form, Input, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { validations } from "../../../../utils/validation/validations";
import { authService } from "../service/auth.service";
import { RegisterConfirm, RegisterUserUI } from "../entity/auth.model";
import { IOModal } from "../../../../utils/Modal/Modal";
import { LoginStyle } from "./LoginStyle";

export default function RegisterUser() {
  const [form] = useForm();
  const navigate = useNavigate();
  const { tokenId } = useParams();

  useEffect(() => {
    if (tokenId !== undefined) {
      let RegisterConfirm: RegisterConfirm = {
        register: {
          token: tokenId,
        },
      };
      authService.registerConfirm(RegisterConfirm);
      navigate("/login");
    }
  }, []);

  const onFinish = (values: any) => {
    let credentials: RegisterUserUI = {
      register: {
        email: values.email,
        password: values.password,
        url:
          window.location.protocol + "//" + window.location.host + "/register",
      },
    };
    authService.register(credentials).then((response) => {
      IOModal.show({
        title: "Амжилттай бүртгэгдлээ",
        content: (
          <div className="text-center">
            Таны бүтгүүлсэн энэ <div className="text-info">{values.email}</div>{" "}
            и-мэйл хаягт баталгаажуулах холбоос очсон тул холбоос дээр дарж
            баталгаажуулна уу.
            <div><b>/Мэйл хаягийн spam хэсэгт орсон байж магадгүйг анхаарна уу./</b></div>
          </div>
        ),
        onCancel: (args) => {
          Modal.destroyAll();
          navigate("/");
        },
        okButtonProps: { style: { display: "none" } },
      });
    });
  };
  return (
      <LoginStyle>
        <Form
            className="d-flex justify-content-center"
            layout="vertical"
            name="pre-register-bachelor-form"
            onFinish={onFinish}
            form={form}
        >
          <div className="register-card">
            <div className="register-card-body">
              <h3>Бүртгүүлэх</h3>
              <div className="panel panel-body login-form mt-3">
                <div className="form-group has-feedback has-feedback-left">
                  <Form.Item
                      name="email"
                      label="Имэйл хаяг"
                      rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="form-group has-feedback has-feedback-left">
                  <Form.Item
                      label="Шинэ нууц үг"
                      name="password"
                      rules={[...validations.ENTER_PASSWORD, ...validations.PASSWORD]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>

                <div className="form-group has-feedback has-feedback-left">
                  <Form.Item
                      label="Нууц үг давтах"
                      name="repeatPassword"
                      rules={[
                        ...validations.ENTER_PASSWORD,
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error("Нууц үг таарахгүй байна!")
                            );
                          },
                        }),
                      ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
                <Button className="submit-btn mt-3" htmlType="submit">
                  Бүртгүүлэх
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </LoginStyle>

  );
}
