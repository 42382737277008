import { Button, Form, Input, notification, Select } from "antd";
import {
  RiAddCircleFill,
  RiArticleLine,
  RiCameraLine,
  RiDeleteBin5Line,
  RiCheckLine,
} from "react-icons/ri";
import UploadImage from "../../../../utils/uploadImage/UploadImage/UploadImage";
import { validations } from "../../../../utils/validation/validations";
import { useEffect, useState } from "react";
import { paymentService } from "../../payment/service/payment.service";
import { GlobalUI, UserFileUI } from "../entity/admit.model";
import { utilityService } from "../../../../utils/service/utility.service";
import { admitService } from "../service/admit.service";
import CustomLoader from "../../../public/components/CustomLoader";
import {useParams} from "react-router-dom";

const uploadButton = (value: string) => (
  <div className="upload-button">
    <RiArticleLine size={20} className="mr-2" />
    <div className="body2">{value}</div>
    <RiCameraLine size={20} className="ml-auto" color="#0132A6" />
  </div>
);

function getDifference(array1:any, array2:any) {
  return array1.filter((object1:any) => {
    return !array2.some((object2:any) => {
      return object1.fileType === object2.fileType;
    });
  });
}
export default function UploadInformation() {
  const [fileType, setFileType] = useState<GlobalUI[]>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [initial, setInitial] = useState<any>()
  const { admitId } = useParams();

  useEffect(() => {
    paymentService.getGlobalConstantsWithAdmissionId("user_file_type", admitId?.toString() ? admitId.toString() : "").then((response) => {
      setFileType(response.globalConstants);
      admitService.getUserFile().then((res) => {
        let testData = {};
        res.userFiles.forEach((element: any) => {
          testData = {
            ...testData,
            [element.fileType.id.toString()]: element.filePath,
          };
        });
        setData(testData);
        setInitial(res.userFiles)
        setLoading(false);
      });
    });
  }, []);

  const onFinish = async (values: any) => {
    console.log(values)
    let userFileRequest: UserFileUI[] = [];
    for (let i in values) {
      if (typeof values[i] === "string"){
        console.log(values[i])
      } else{
        console.log(typeof values[i])
        await utilityService.uploadFile(values[i]).then((res) => {
          userFileRequest.push({
            file: res.file.id,
            filePath: res.file.image_path,
            fileType: parseInt(i),
          });
        });
      }
    }

    let checkVal = initial.map((dataVal: any, index: number) => {
      return{
        file: dataVal.file.id,
        filePath: dataVal.filePath,
        fileType: dataVal.fileType.id,
      }
    })

    if(checkVal.length > userFileRequest.length){
      let diff = getDifference(checkVal, userFileRequest)
      userFileRequest = userFileRequest.concat(diff)
    }



    console.log(checkVal)
    console.log(userFileRequest)
    console.log(getDifference(checkVal, userFileRequest))

    admitService.updateUserFile(userFileRequest).then((res) => {
      notification.success({
        message: "Success",
      });
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form
          name="userFile"
          layout="vertical"
          onFinish={onFinish}
          initialValues={data}
        >
          <div className="register-box">
            <div className="mt-3 row">
              {fileType?.map((element, index) => {
                return (
                  <div className="col-md-6" key={index}>
                    <Form.Item
                      name={element.id}
                      rules={[...validations.REQUIRED_FIELD]}
                    >
                      <UploadImage uploadButton={uploadButton(element.name)} value={data[element.id]} />
                    </Form.Item>
                  </div>
                );
              })}
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
