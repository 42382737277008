import moment from "moment";
import {RiAlertLine, RiArrowRightSLine, RiCheckFill} from "react-icons/ri";
import {Admission} from "../entity/information.model";
import {Link} from "react-router-dom";
import {Row, Col} from "antd";
import {RegistrationTextStyle} from "./RegistrationTextStyle";
import storeService from "../../../../utils/helper/store";
import {useEffect, useState} from "react";

export default function RegistrationText(admission: Admission) {
    const [admissionIdExists, setAdmissionIdExists] = useState(false)
    let formLink = "";
    if (admission.satExam) {
        formLink = "/user/admit/" + admission.id + "/info";
    } else {
        formLink = "/user/admit/" + admission.id + "/info";
    }

    useEffect(() => {
        let array: any = storeService.getAuthRegister()
        if (array) {
            const admissionIdExists = array.some((obj: any) => obj?.admissionId === admission.id);
            setAdmissionIdExists(admissionIdExists);
        }
    }, [])


    return (
        <RegistrationTextStyle>
            <div className="ml-lg-5 mt-2 border-bottom pb-3">
                <div className="d-flex mobile-responsive-section">
                    <div className="body1 font-weight-bold">{admission.name}</div>
                    {
                        admission.state === "register" ?
                            <div className="registration-open mobile-responsive-status"><RiCheckFill size={16}/>Бүртгэл
                                явагдаж байна</div>
                            :
                            <>
                                {
                                    admission.state === "confirm" ?
                                        <div className="registration-open mobile-responsive-status"><RiCheckFill
                                            size={16}/>Элсэлт явагдаж байна</div>
                                        :
                                        <div className="registration-closed mobile-responsive-status"><RiAlertLine
                                            size={16}/>Бүртгэл дууссан</div>
                                }
                            </>
                    }
                </div>

                <Row className="mt-2" gutter={16}>
                    <Col xs={24} lg={8}>
                        <div className="d-flex flex-column">
                            <div className="body2">Суралцах хугацаа</div>
                            <div className="body2 font-weight-bold">{admission.studyYear}</div>
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className="d-flex flex-column">
                            <div className="body2">Суралцаж эхлэх огноо</div>
                            <div className="body2 font-weight-bold">
                                {moment(admission.confirmStart.date).format("YYYY.MM.DD")} -{" "}
                                {moment(admission.confirmEnd.date).format("YYYY.MM.DD")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={8}>
                        <div className="d-flex flex-column">
                            <div className="body2">Бүртгэл</div>
                            <div className="body2 font-weight-bold">
                                {moment(admission.registerStart.date).format("YYYY.MM.DD")} -{" "}
                                {moment(admission.registerEnd.date).format("YYYY.MM.DD")}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={24} className="pt-3">
                        {admission.state === "register" &&
                            <button>
                                {
                                    admissionIdExists ?
                                        <Link
                                            to={'/user/home'}
                                            className="d-flex"
                                        >
                                            <div>Хувийн мэдээлэл</div>
                                            <RiArrowRightSLine size={16}/>
                                        </Link>
                                        :
                                        <Link
                                            to={formLink}
                                            state={{title: admission.degree.description}}
                                            className="d-flex"
                                        >
                                            <div>Бүртгүүлэх</div>
                                            <RiArrowRightSLine size={16}/>
                                        </Link>
                                }

                            </button>
                        }
                        {admission.state === "confirm" &&
                            <button>
                                <Link
                                    to={`${admission.code.startsWith('BBAe') || admission.code.startsWith('МBA') || admission.code.startsWith('MBA') ? formLink : "/user/home"}`}
                                    state={{clickButtonType: `${admission.code.startsWith('BBAe') || admission.code.startsWith('МBA') || admission.code.startsWith('MBA') ? "confirm-registered" : "register"}`}}
                                    className="d-flex"
                                >
                                    <div>Элсэх</div>
                                    <RiArrowRightSLine size={16}/>
                                </Link>
                            </button>
                        }
                        {/*{admission.state === "finished" &&*/}
                        {/*    <button>*/}
                        {/*      <Link*/}
                        {/*          to={formLink}*/}
                        {/*          state={{title: admission.degree.description, clickButtonType: "confirm"}}*/}
                        {/*          className="d-flex"*/}
                        {/*      >*/}
                        {/*        <div>Дэлгэрэнгүй анкет</div>*/}
                        {/*        <RiArrowRightSLine size={16} />*/}
                        {/*      </Link>*/}
                        {/*    </button>*/}
                        {/*}*/}
                    </Col>
                    {/*<Col xs={24} md={4} className="text-center pt-3">*/}
                    {/*  {admission.state === "confirm" || admission.state === "register" ? (*/}
                    {/*    <>*/}
                    {/*      <Link*/}
                    {/*        to={formLink}*/}
                    {/*        state={{*/}
                    {/*          title: admission.degree.description,*/}
                    {/*          clickButtonType: "confirm",*/}
                    {/*        }}*/}
                    {/*        className="d-flex align-items-center text-blue justify-content-center"*/}
                    {/*      >*/}
                    {/*        <RiArrowRightCircleLine color="primary" size={30} />*/}
                    {/*        <div className="body1 font-weight-bold ml-1">*/}
                    {/*          Дэлгэрэнгүй анкет*/}
                    {/*        </div>*/}
                    {/*      </Link>*/}
                    {/*    </>*/}
                    {/*  ) : (*/}
                    {/*    <div className="d-flex align-items-center justify-content-center text-grey-300">*/}
                    {/*      <RiCloseCircleLine color="primary" size={30} />*/}
                    {/*      <div className="body1 font-weight-bold ml-1">*/}
                    {/*        Дэлгэрэнгүй анкет хаагдсан*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*</Col>*/}
                </Row>
                {/* <div className="d-flex justify-content-between mt-3"></div> */}
            </div>
        </RegistrationTextStyle>

    );
}
