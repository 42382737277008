import DateCard from "../information/components/date-card";
import CustomButton from "../information/components/custom-button";
import { Pagination } from 'antd';

export const TrainingCard = (props: any) => {

    const imgBase = process.env.REACT_APP_FILE_HOST;

    return (
        <>
            <div
                className="card m-lg-2"
                style={{width: "100%", borderRadius: "16px", height: "100%"}}
            >
                <div>
                    <div className="img">
                        <img
                            className="card-img-top"
                            src={`${imgBase}${props.training.imagePath}`}
                            alt="image-alt-text"
                            style={{
                                borderRadius: "16px 16px 0 0",
                                objectFit: "cover",
                                width: "100%",
                                height: "150px",
                            }}
                        />
                    </div>
                </div>
                <div className="card-body">
                    <p className="text fw-bold">{props.training.name}</p>
                    <div className="body1 font-weight-bold">Суралцах орчин:</div>
                    <p className="card-text">{props?.training.location}</p>
                    <h4 className="font-weight-bold">Эхлэх огноо:</h4>
                    <DateCard newsDate={props?.training.confirmStart.date}/>
                    <div className="body1 font-weight-bold">Сургалтын хугацаа:</div>
                    <p>{props?.training.studyYear}</p>
                    <a href={`/information/training/${props.training.id}`}>
                        <CustomButton/>
                    </a>
                </div>
            </div>
        </>
    );
};
