import { Collapse } from "antd";
import { RegistrationAccordionStyle } from "./RegistrationAccordionStyle";
import ImageCarousel from "./ImageCarousel";
import ImageList from "./ImageList";
import {useEffect, useState} from "react";
import { ReactComponent as DefaultImage } from "../../../../assets/default-image.svg";
import { constants } from "../../../../utils/constant/constants";

const { Panel } = Collapse;

const baseUrl = constants.BASE_URL.slice(0, -1);

export default function RegistrationAccordion(props: { data: any;}) {
  const [key, setKey] = useState<number>(99);

  useEffect(()=>{
      setKey(0)
  },[])

  const onChange = (key: string | string[]) => {
    setKey(parseInt(key[key.length - 1]));
  };

  return (
    <div className="row">
      <div
        className={"col-12"}
      >
        <RegistrationAccordionStyle>
          <Collapse expandIconPosition={"right"} onChange={onChange}>
            {props.data.map((element: any, index: number) => {
              return (
                <Panel header={element.name} key={index}>
                  <div
                    dangerouslySetInnerHTML={{ __html: element.htmlContent }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </RegistrationAccordionStyle>
      </div>
    </div>
  );
}
