import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoutes from "./module/public/Routes";
import PrivateRoutes from "./module/private/Routes";
import { ApplicationRequestProvider } from "./utils/context/ApplicationRequest";

function App() {
  return (
    <ApplicationRequestProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<PublicRoutes />} />
          <Route path="/user/*" element={<PrivateRoutes />} />
        </Routes>
      </BrowserRouter>
    </ApplicationRequestProvider>
  );
}

export default App;
