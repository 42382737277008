import styled from "styled-components";

export const UserHeaderStyle = styled.div`
  .mobile-response-header {
    display: none !important;
  }

  .header-text {
    border-bottom: 1px solid transparent;
    background-color: #facc15;
    padding: 4px 10px;
    border-radius: 5px;
    font-weight: bold;
    transition: border-color 0.3s ease;
    cursor: pointer;
  }

  .header-text:hover {
    border-bottom: 1px solid #007bff;
    color: #007bff;
  }

  .btn-primary:hover {
    background-color: #0056b3;
  }

  @media screen and (max-width: 918px) {
    .container-header {
      width: 100%;
      padding: 0 10px;
      margin: auto;
      flex-direction: column;
    }

    .mobile-response {
      display: none !important;
    }

    .mobile-response-header {
      display: block !important;
      width: 100%;
      text-align: center;
    }

    .navbar {
      padding: 1rem 0;
      height: auto;
    }

    .dropdown-menu {
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }
  }
`;
