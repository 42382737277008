import React from "react";
import { Route, Routes } from "react-router-dom";
import Instruction from "./screen/Instruction";
import VR from "./screen/VR";
import QA from "./screen/QA";
import Contact from "./screen/Contact";
import Registration from "./screen/Registration";
import Degree from "./screen/Degree";
import News from "./screen/News";
import NewsDetail from "./screen/NewsDetail";
import UserScore from "./screen/UserScore";
import Training from "./screen/Training";

export default function InformationRoutes() {
  return (
      <div className={"content"}>
        <div className="container">
          <Routes>
            <Route path="/instruction" element={<Instruction />} />
            <Route path="/vr" element={<VR />} />
            <Route path="/qa/:id" element={<QA />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/news/:id" element={<News />} />
            <Route path="/newsDetail/:newsId" element={<NewsDetail />} />
            <Route path="/registration/:id" element={<Registration />} />
            <Route path="/degree/:degreeGroupId" element={<Degree />} />
            <Route path="/score" element={<UserScore />} />
            <Route path="/training/:id" element={<Training />} />
          </Routes>
        </div>
      </div>
  );
}


