import { useNavigate } from "react-router-dom";
import { message, Form, Button } from "antd";
import React from "react";
import BachelorSatInformation from "../../admit/components/bachelor/BachelorSatInformation";

export default function Sat() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <Form.Provider
        onFormFinish={(name) => {
          if (name === "personalInformation") {
            message.success("Success");
          }
          if (name === "userSat") {
            message.success("Success");
          }
        }}
      >
        {/*<h1 className="mb-3">ЭЕШ-ын мэдээлэл</h1>*/}
        <BachelorSatInformation />
        <div className="row mt-3">
          <div className="col-lg-4" />
          <div className="col-lg-4">
            <div className="d-flex">
              <Button
                type="primary"
                className="submit-btn mx-2"
                onClick={() => navigate(-1)}
              >
                Буцах
              </Button>
              <Button
                type="primary"
                className="submit-btn mx-2"
                htmlType="submit"
                form="userSat"
              >
                Хадгалах
              </Button>
            </div>
          </div>
          <div className="col-lg-4" />
        </div>
      </Form.Provider>
    </div>
  );
}
