import { Form, Select, Input, DatePicker, Radio } from "antd";
import type { RadioChangeEvent } from 'antd';
import { useEffect, useState } from "react";
import { validations } from "../../../../utils/validation/validations";
import storeService from "../../../../utils/helper/store";
import { admitService } from "../service/admit.service";
import CustomLoader from "../../../public/components/CustomLoader";
import {
  AddressUI,
  Country,
  UserInfo,
  UserInfoUI,
} from "../entity/admit.model";
import moment from "moment";
import {useParams} from "react-router-dom";
import {informationService} from "../../../public/information/service/information.service";

const { Option } = Select;

export default function PersonalInformation() {
  const userAuth = storeService.getAuth();
  const [countries, setCountries] = useState<Country[]>();
  const [mn, setMn] = useState<any>();
  const [mnCheck, setMnCheck] = useState<any>(false);
  const [info, setInfo] = useState<UserInfoUI>();
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState<AddressUI[]>();
  const [lang, setLang] = useState<any>(true);
  const [degreeProfessionGroup, setDegreeProfessionGroup] = useState<any>()
  const [studyProgram, setStudyProgram] = useState<any>()
  const { admitId } = useParams();

  useEffect(() => {
    admitService.getCountries().then((countries) => {
      admitService.getUserInfoDetail().then((response) => {
        setCountries(countries.countries);
        let mongoliaId: any;
        countries.countries.forEach((country) => {
          if (country.shortName === "mn") {
            setMn(country.id);
            mongoliaId = country.id;
          }
        });
        if (mongoliaId === response.userInfo?.country?.id) {
          setMnCheck(true);
        }
        setInfo({
          ...response.userInfo,
          country: response.userInfo?.country?.id,
          province: response.userInfo?.province?.id,
          birthDate: moment(response.userInfo?.birthDate?.date),
        });
        admitService.getAddress().then((response) => {
          setAddress(response.addressings);
          setLoading(false);
        });
      });
    });
  }, []);

  useEffect(()=>{
    console.log(admitId, "admit")
    informationService.getAdmissionDegreeProfessionGroup(admitId ? admitId : "0").then((res)=>{
      setDegreeProfessionGroup(res)
    })
  },[])

  const onFinish = (values: any) => {
    delete values.email;
    admitService
        .updateUserInfoDetail({
          ...values,
          birthDate: moment(values.birthDate).format("YYYY-MM-DD"),
        })
        .then((response) => {
          console.log(response);
        });
  };

  const handleChange = (value: any) => {
    if (value === mn) {
      setMnCheck(true);
    } else {
      setMnCheck(false);
    }
  };

  const handleDegreeProfessionGroupChange = (value: any) => {
    const result = degreeProfessionGroup.find((obj:any) => obj.name === value);
    setStudyProgram(result.degreeStudyPrograms)
  };

  const handleLangChange = (e: RadioChangeEvent) => {
    setLang(e.target.value);
  };


  return (
      <>
        {loading ? (
            <CustomLoader />
        ) : (<>

              <Form
                  name="personalInformation"
                  initialValues={info}
                  layout="vertical"
                  onFinish={onFinish}
              >
                <div className="register-box">
                  <div className="row">
                    <div className="col-md-12">
                      <Radio.Group
                          onChange={handleLangChange}
                          value={lang}
                          style={{ fontSize: "small" }}
                      >
                        <Radio value={true}>Mn</Radio>
                        <Radio value={false}>En</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  {lang ?
                      (<>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item
                                name="country"
                                label="Иргэний харъяалал"
                                rules={[...validations.REQUIRED_FIELD]}
                            >
                              <Select
                                  showSearch
                                  placeholder="Дарж сонгоно уу"
                                  allowClear
                                  optionFilterProp="children"
                                  onChange={handleChange}
                              >
                                {countries?.map((country) => {
                                  return (
                                      <Option value={country.id} key={country.id}>
                                        {country.name}({country.shortName})
                                      </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          {mnCheck && (
                              <div className="col-md-6">
                                <Form.Item
                                    name="province"
                                    label="Орон нутгийн харъяалал"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Орон нутгийн харъяалал оруулна уу",
                                      },
                                    ]}
                                >
                                  <Select
                                      placeholder="Дарж сонгоно уу"
                                      optionFilterProp="children"
                                  >
                                    {address?.map((option) => {
                                      return (
                                          <Option key={option.id} value={option.id}>
                                            {option.name}
                                          </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                          )}
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="familyName"*/}
                          {/*      label="Ургийн овог"*/}
                          {/*      rules={[...validations.REQUIRED_FIELD]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="lastnameMn"
                                label="Овог"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  ...validations.NAME_MN,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="lastnameEn"*/}
                          {/*      label="Овог /Латин галигаар/"*/}
                          {/*      rules={[*/}
                          {/*        ...validations.REQUIRED_FIELD,*/}
                          {/*        ...validations.NAME_EN,*/}
                          {/*      ]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="firstnameMn"
                                label="Нэр"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  ...validations.NAME_MN,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="firstnameEn"*/}
                          {/*      label="Нэр /Латин галигаар/"*/}
                          {/*      rules={[*/}
                          {/*        ...validations.REQUIRED_FIELD,*/}
                          {/*        ...validations.NAME_EN,*/}
                          {/*      ]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="regNum"
                                label="Регистрийн дугаар"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  ...validations.REGISTRATION_NUMBER,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*    name="ethnicity"*/}
                          {/*    label="Яс үндэс"*/}
                          {/*    rules={[...validations.REQUIRED_FIELD]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="gender"
                                label="Хүйс"
                                rules={[...validations.REQUIRED_FIELD]}
                            >
                              <Select placeholder="Дарж сонгоно уу" allowClear>
                                <Option value={1}>Эрэгтэй</Option>
                                <Option value={0}>Эмэгтэй</Option>
                              </Select>
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="birthDate"*/}
                          {/*      label="Төрсөн огноо"*/}
                          {/*      rules={[...validations.REQUIRED_FIELD]}*/}
                          {/*  >*/}
                          {/*    /!*<Input placeholder={"YYYY/MM/DD"}/>*!/*/}
                          {/*    <DatePicker format={"YYYY/MM/DD"} />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="mobile"
                                label="Утасны дугаар 1"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  ...validations.ENTER_PHONE_NUMBER,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                                name="phoneHome"
                                label="Утасны дугаар 2"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  ...validations.ENTER_PHONE_NUMBER,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                                name="phoneWork"
                                label="Утасны дугаар 3"
                                // rules={[
                                //   ...validations.REQUIRED_FIELD,
                                //   ...validations.ENTER_PHONE_NUMBER,
                                // ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="interestedDegreeProfessionGroup"*/}
                          {/*      label="Сонирхож буй мэргэжил"*/}
                          {/*      // rules={[*/}
                          {/*      //   ...validations.REQUIRED_FIELD,*/}
                          {/*      // ]}*/}
                          {/*  >*/}
                          {/*    <Select*/}
                          {/*        showSearch*/}
                          {/*        placeholder="Дарж сонгоно уу"*/}
                          {/*        allowClear*/}
                          {/*        optionFilterProp="children"*/}
                          {/*        onChange={handleDegreeProfessionGroupChange}*/}
                          {/*    >*/}
                          {/*      {degreeProfessionGroup?.map((value: any) => {*/}
                          {/*        return (*/}
                          {/*            <Option value={value.name} key={value.id}>*/}
                          {/*              {value.name}*/}
                          {/*            </Option>*/}
                          {/*        );*/}
                          {/*      })}*/}
                          {/*    </Select>*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="interestedDegreeStudyProgram"*/}
                          {/*      label="Сонирхож буй хөтөлбөр"*/}
                          {/*      // rules={[*/}
                          {/*      //   ...validations.REQUIRED_FIELD,*/}
                          {/*      // ]}*/}
                          {/*  >*/}
                          {/*    <Select*/}
                          {/*        showSearch*/}
                          {/*        placeholder="Дарж сонгоно уу"*/}
                          {/*        allowClear*/}
                          {/*        optionFilterProp="children"*/}
                          {/*    >*/}
                          {/*      {studyProgram?.map((value: any) => {*/}
                          {/*        return (*/}
                          {/*            <Option value={value.description} key={value.id}>*/}
                          {/*              {value.description}*/}
                          {/*            </Option>*/}
                          {/*        );*/}
                          {/*      })}*/}
                          {/*    </Select>*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                initialValue={userAuth?.email}
                                name="email"
                                label="Цахим шуудан"
                                // rules={[...validations.REQUIRED_FIELD]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                          </div>
                        </div>
                      </>) :
                      (<>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item
                                name="country"
                                label="Nationality"
                                rules={[...validations.REQUIRED_FIELD]}
                            >
                              <Select
                                  showSearch
                                  placeholder="Click to select..."
                                  allowClear
                                  optionFilterProp="children"
                                  onChange={handleChange}
                              >
                                {countries?.map((country) => {
                                  return (
                                      <Option value={country.id} key={country.id}>
                                        {country.name}({country.shortName})
                                      </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          {mnCheck && (
                              <div className="col-md-6">
                                <Form.Item
                                    name="province"
                                    label="Province"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Орон нутгийн харъяалал оруулна уу",
                                      },
                                    ]}
                                >
                                  <Select
                                      placeholder="Дарж сонгоно уу"
                                      optionFilterProp="children"
                                  >
                                    {address?.map((option) => {
                                      return (
                                          <Option key={option.id} value={option.id}>
                                            {option.name}
                                          </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                          )}
                          <div className="col-md-6">
                            <Form.Item
                                name="lastnameMn"
                                label="Lastname"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  // ...validations.NAME_MN,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="lastnameEn"*/}
                          {/*      label="Эцэг /эх/-ийн нэр /Англиар/"*/}
                          {/*      rules={[*/}
                          {/*        ...validations.REQUIRED_FIELD,*/}
                          {/*        ...validations.NAME_EN,*/}
                          {/*      ]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="firstnameMn"
                                label="Firstname"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  // ...validations.NAME_MN,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="firstnameEn"*/}
                          {/*      label="Өөрийн нэр /Англиар/"*/}
                          {/*      rules={[*/}
                          {/*        ...validations.REQUIRED_FIELD,*/}
                          {/*        ...validations.NAME_EN,*/}
                          {/*      ]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="regNum"
                                label="Passport number"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*    name="ethnicity"*/}
                          {/*    label="Яс үндэс"*/}
                          {/*    rules={[...validations.REQUIRED_FIELD]}*/}
                          {/*  >*/}
                          {/*    <Input />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="gender"
                                label="Gender"
                                rules={[...validations.REQUIRED_FIELD]}
                            >
                              <Select placeholder="Click to select" allowClear>
                                <Option value={1}>Male</Option>
                                <Option value={0}>Female</Option>
                              </Select>
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="birthDate"*/}
                          {/*      label="Төрсөн огноо"*/}
                          {/*      rules={[...validations.REQUIRED_FIELD]}*/}
                          {/*  >*/}
                          {/*    /!*<Input placeholder={"YYYY/MM/DD"}/>*!/*/}
                          {/*    <DatePicker format={"YYYY/MM/DD"} />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="col-md-6">
                            <Form.Item
                                name="mobile"
                                label="Phone number in Mongolia"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  ...validations.ENTER_PHONE_NUMBER,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                                name="phoneHome"
                                label="Phone number in Foreign"
                                rules={[
                                  ...validations.REQUIRED_FIELD,
                                  // ...validations.ENTER_PHONE_NUMBER,
                                ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                                name="phoneWork"
                                label="Phone number 3"
                                // rules={[
                                //   ...validations.REQUIRED_FIELD,
                                //   ...validations.ENTER_PHONE_NUMBER,
                                // ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="interestedDegreeProfessionGroup"*/}
                          {/*      label="Career interest"*/}
                          {/*      // rules={[*/}
                          {/*      //   ...validations.REQUIRED_FIELD,*/}
                          {/*      // ]}*/}
                          {/*  >*/}
                          {/*    <Select*/}
                          {/*        showSearch*/}
                          {/*        placeholder="Дарж сонгоно уу"*/}
                          {/*        allowClear*/}
                          {/*        optionFilterProp="children"*/}
                          {/*        onChange={handleDegreeProfessionGroupChange}*/}
                          {/*    >*/}
                          {/*      {degreeProfessionGroup?.map((value: any) => {*/}
                          {/*        return (*/}
                          {/*            <Option value={value.name} key={value.id}>*/}
                          {/*              {value.name}*/}
                          {/*            </Option>*/}
                          {/*        );*/}
                          {/*      })}*/}
                          {/*    </Select>*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          {/*<div className="col-md-6">*/}
                          {/*  <Form.Item*/}
                          {/*      name="interestedDegreeStudyProgram"*/}
                          {/*      label="Interest program"*/}
                          {/*      // rules={[*/}
                          {/*      //   ...validations.REQUIRED_FIELD,*/}
                          {/*      // ]}*/}
                          {/*  >*/}
                          {/*    <Select*/}
                          {/*        showSearch*/}
                          {/*        placeholder="Дарж сонгоно уу"*/}
                          {/*        allowClear*/}
                          {/*        optionFilterProp="children"*/}
                          {/*    >*/}
                          {/*      {studyProgram?.map((value: any) => {*/}
                          {/*        return (*/}
                          {/*            <Option value={value.description} key={value.id}>*/}
                          {/*              {value.description}*/}
                          {/*            </Option>*/}
                          {/*        );*/}
                          {/*      })}*/}
                          {/*    </Select>*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                        </div>
                      </>)
                  }
                </div>
              </Form>
            </>
        )}
      </>
  );
}
