import styled from "styled-components";

export const SuccessComponentStyle = styled.div`
  
  @media screen and (max-width: 918px) {
    h4{
      font-size: 16px;
    }
    .success-image{
      width: 180px !important;
      height: 180px !important;;
    }
  }
  
  .success-image{
    width: 320px;
    height: 320px;
  }
  
`;
