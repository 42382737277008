import {ColumnsType} from "antd/lib/table";
import {Button, Modal, Table, Tooltip} from "antd";
import React from "react";
import {UserProfessionSelectTableStyle} from "./UserProfessionSelectTableStyle";
import {UserProfessionSelectTableUI} from "../../entity/home.model";
import Circle from "../../../../public/information/components/circle/Circle";
import {IOModal} from "../../../../../utils/Modal/Modal";
import CustomLoader from "../../../../public/components/CustomLoader";
import {admitService} from "../../../admit/service/admit.service";
import {useNavigate} from "react-router-dom";
import success from "../../../../../assets/images/success.png";

export default function UserProfessionSelectTable(props: {
    data: UserProfessionSelectTableUI[];
    userData: any;
    setProfession: any;
    profession: any;
    setProfessionId: any;
    professionId: any;
    scoreSat: any;
    loading: any;
    setLoading: any;
    admission: any
}) {
    const navigate = useNavigate();
    const {loading, profession, setLoading, userData, professionId, admission} = props;

    const handleClick = (selectedId: any) => {
        setLoading(true)
        admitService
            .confirmAdmissionUser(userData.id, selectedId).then((res) => {
            setLoading(false)
            window.location.reload()
        })
    }

    const onClick = (selectedId: any, professionName: any) => {
        IOModal.confirm({
            title: "Мэргэжил сонголт батлах",
            content: loading ? (
                <CustomLoader/>
            ) : (
                <div className="mt-2 text-center">
                    Ta {professionName} сонгох гэж байна итгэлтэй байна уу.
                    <div className="font-weight-bold text-danger">
                        Баталсан тохиолдолд солих боломжгүйг анхаарна уу.
                    </div>
                </div>
            ),

            okText: "Тийм",
            onOk: (args) => {
                handleClick(selectedId)
                Modal.destroyAll();
            },
        });
    };
    let columns: ColumnsType<UserProfessionSelectTableUI> = [
        {
            title: "Мэргэжил, чиглэл",
            dataIndex: "degreeProfessionGroupName",
            width: "30%",
            key: "degreeProfessionGroupName",
        },
        {
            title: "Хөтөлбөр",
            dataIndex: "degreeProgramName",
            width: "30%",
            key: "degreeProgramName",
        },
        {
            title: "Анги дүүргэлт",
            dataIndex: "progress",
            key: "progress",
            render: (count, record) => (
                <>
                    {count >= record.capacity ? (
                        <div className="text-danger">Энэ мэргэжлийн анги дүүрсэн</div>
                    ) : (
                        count + "/" + record.capacity
                    )}
                </>
            ),
        },
        {
            title: "ЭЕШ-ын дундаж оноо",
            dataIndex: "minScoreRanking",
            key: "minScoreRanking",
        },
        {
            title: "Сонголт",
            dataIndex: "select",
            width: "20%",
            key: "select",
            render: (select, record) => {
                let check = false;
                let selected = false;
                if (record.minScoreRanking <= props.userData.scoreSat) {
                    check = true;
                }
                if (record.progress >= parseInt(record.capacity)) {
                    check = false;
                }
                if (
                    props.userData.state === "accepted" &&
                    props.userData.admissionRegister.id === record.id
                ) {
                    selected = true;
                } else if (
                    props.userData.state === "accepted" &&
                    props.userData.admissionRegister.id !== record.id
                ) {
                    check = false;
                }
                console.log(record.progress >= parseInt(record.capacity));
                console.log(record.progress);
                console.log(parseInt(record.capacity));

                return (
                    <>
                        {selected ? (
                            <>
                                <>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Circle size={"12px"} color={"#05A660"}/>
                                        Сонгосон
                                    </div>
                                    {/*<p className="text-danger">Та мэргэжил сонгоод үүний дараа мэргэжил батлах товчийг дарна уу</p>*/}
                                </>
                            </>
                        ) : (
                            <>
                                {check && record.progress < parseInt(record.capacity) ? (
                                    <>
                                        <Button
                                            type="primary"
                                            className="submit-btn w-100"
                                            onClick={() => {
                                                props.setProfessionId(record.id);
                                                onClick(record.id, record.degreeProfessionGroupName + " - " + record.degreeProgramName)
                                            }}
                                        >
                                            Мэргэжил сонгох
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Circle size={"12px"} color={"#C7C9D9"}/>
                                            Сонгох боломжгүй
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <UserProfessionSelectTableStyle>
            <Table
                columns={columns}
                dataSource={props.data}
                className="table table-responsive"
                pagination={false}
                rowClassName="table-row"
            />
        </UserProfessionSelectTableStyle>
    );
}
