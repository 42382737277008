import {RiMailLine,RiPhoneLine} from "react-icons/ri";


export default function ContactContent(props: {data: any}) {
    return(
        <>
            <div>
                {
                    props.data.map((element: any, index:number)=>(
                        <div className="d-flex align-items-center text-blue my-3" key={index}>
                            {
                                element.type==="E-mail" ?
                                    <>
                                        <RiMailLine size={40} />
                                    </>
                                    :
                                    <>
                                        <RiPhoneLine size={40} />
                                    </>
                            }
                            <div className="body-1 ml-2 text-primary">{element.name}</div>
                        </div>
                    ))
                }
            </div>

        </>
    )
}