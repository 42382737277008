import { useEffect, useState } from "react";

import { informationService } from "../service/information.service";
import { AdmissionRegisterGraphUI } from "../entity/information.model";
import PublicScoreTable from "../components/UserScoreTable/PublicScoreTable";
import CustomLoader from "../../../public/components/CustomLoader";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from "recharts";

export default function UserScore() {
  const [admissionRegisterList, setAdmissionRegisterList] = useState<any>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    informationService.getAdmissionRegisterGraph().then((res) => {
      setAdmissionRegisterList(
        res.map((data: AdmissionRegisterGraphUI) => {
          return {
            id: data.id,
            Мэргэжил: data.name,
            Нийт: data.capacity,
            Элссэн: data.count >= data.capacity ? data.capacity : data.count,
            Үлдсэн:
              data.capacity - data.count <= 0 ? 0 : data.capacity - data.count,
            Хөтөлбөр: data.description,
          };
        })
      );
      setLoading(false);
    });
  }, []);

  return (
    <div className="container mt-5">
      <h1 className="my-4">Элсэгчдийн хөтөлбөрийн жагсаалт</h1>
      {loading ? (
        <CustomLoader />
      ) : (
        admissionRegisterList && (
          <>
            <PublicScoreTable data={admissionRegisterList} />

            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                barGap={-30}
                data={admissionRegisterList}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="Хөтөлбөр" xAxisId={0} />
                <XAxis dataKey="Хөтөлбөр" xAxisId={1} hide />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Нийт" xAxisId={1} fill="#9fc2fc" />
                <Bar
                  dataKey="Элссэн"
                  xAxisId={0}
                  fill="#0132A6"
                  fillOpacity={0.7}
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        )
      )}
    </div>
  );
}
