import { api } from "../../../../utils/helper/api";
import {
  AdmissionRegisterUI,
  AdmissionUI,
  RegistrationInformationUI,
  AdmissionRegisterGraphUI,
} from "../entity/information.model";

const getDegreeInfo = (
  degree: string | undefined
): Promise<RegistrationInformationUI> => {
  return api.get("/_open/degree/" + degree + "/profession/group");
};

const getAdmission = (degreeId: number | undefined): Promise<AdmissionUI> => {
  return api.post("/_open/admission/active/list", { degreeId });
};

const getDegreeInfoImages = (degree: string | undefined): Promise<any> => {
  return api.get("/_open/degree-info/" + degree + "/detail");
};

const getAdmissionRegister = (): Promise<AdmissionRegisterUI[]> => {
  return api.get("/_open/admission-register/list");
};

const getRankingList = (id: string | undefined): Promise<any> => {
  return api.get("/_open/ranking/" + id);
};

const getRankingListNew = (id: number | undefined): Promise<any> => {
  return api.get(`admission/ranking/kind/` + id + `/detail/list`);
};

const getInformation = (type: string): Promise<any> => {
  return api.get("/_open/information/" + type + "/list");
};

const getRankingKindMy = (): Promise<any> => {
  return api.get("/admission/ranking/kind/my");
};

const getRankingKind = (): Promise<any> => {
  return api.get("/_open/ranking-kind/list");
};

const getUserRankingInfo = (): Promise<any> => {
  return api.get("/admission/user/ranking/info");
};

const getDegreeGuide = (degreeId: string | undefined): Promise<any> => {
  return api.get(`/_open/degree-guide/${degreeId}/detail`);
};

const getDegreeInfoQA = (degreeId: string | undefined): Promise<any> => {
  return api.get(`/_open/degree-info/${degreeId}/qa`);
};

const getAdmissionRegisterGraph = (): Promise<AdmissionRegisterGraphUI[]> => {
  return api.get("/_open/ranking/admission-register/graph");
};

const getAdmissionInfo = (id: string): Promise<any> => {
  return api.get(`/_open/admission/info/${id}`);
};

const getAdmissionDegreeProfessionGroup = (id: string): Promise<any> => {
  return api.get(`/_open/admission/${id}/degree-profession-group`);
};

const sendBrochureData = (body: any): Promise<any> => {
  body['degree'] = "None"
  return api.post(`/_open/brochure/send`, body);
};

export const informationService = {
  getAdmissionInfo,
  getInformation,
  getRankingKind,
  getDegreeInfo,
  getRankingList,
  getDegreeInfoImages,
  getAdmission,
  getAdmissionRegister,
  getUserRankingInfo,
  getDegreeGuide,
  getAdmissionRegisterGraph,
  getRankingKindMy,
  getRankingListNew,
  getAdmissionDegreeProfessionGroup,
  getDegreeInfoQA,
  sendBrochureData
};
