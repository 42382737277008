import { Rule } from "antd/lib/form"

const PASSWORD: Rule[] = [{
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    message: "Том, жижиг үсэг, тоо орсон 8-с дээш оронтой нууц үг байх ёстой.",
}]

const ENTER_PASSWORD: Rule[] = [{
    required: true,
    message: "Нууц үгээ оруулна уу.",
}]

const ENTER_EMPLOYEE_NAME: Rule[] = [{
    required: true,
    message: "Ажилтны нэр оруулна уу.",
}]

const ENTER_PHONE_NUMBER: Rule[] = [
    {
        required: true,
        message: "Утасны дугаар оруулна уу.",
    },
    {
        pattern: /^[0-9]{8}$/,
        message: "8-н оронтой тоо оруулна уу.",
    },
]

const GRADUATION_YEAR: Rule[] = [
    {
        required: true,
        message: "Талбар оруулна уу.",
    },
    {
        pattern: /^[0-9]{4}$/,
        message: "4-н оронтой тоо оруулна уу.",
    },
]

const ENTER_EMAIL: Rule[] = [
    {
        required: true,
        message: "Имэйл хаягаа оруулна уу."
    },
    {
        type: "email",
        message: "Имэйл хаяг буруу байна.",
    },
]

const ENTER_CORPORATE_NAME: Rule[] = [{
    required: true,
    message: "Бизнесийн нэрээ оруулна уу.",
}]

const ENTER_COLOR: Rule[] = [{
    required: true,
    message: "Бренд өнгөний кодоо оруулна уу.",
}]

const COLOR: Rule[] = [{
    pattern: /^#[0-9A-F]{6}$/i,
    message: "#-р эхэлсэн 6 оронтой HEX код оруулах ёстой."
}]

const NAME_MN: Rule[] = [{
    pattern: /^[-А-Яа-я.ӨөҮүЁё]+$/,
    message: "Зөвхөн кирилл үсэг оруулна уу."
}]

const NAME_EN: Rule[] = [{
    pattern: /^[-A-Za-z]+$/,
    message: "Зөвхөн латин үсэг оруулна уу."
}]

const REGISTRATION_NUMBER: Rule[] = [{
    pattern: /^([А-Я.ӨҮ]{2})([0-9]{8})+$/,
    message: "Регистрийн дугаар оруулна уу.(Эхний 2 үсэг томоор)"
}]

const SAT_NUMBER: Rule[] = [{
    pattern: /^([0-9]{9})+$/,
    message: "БҮТ-ийн дугаар буруу байна."
}]

const DATE_FORMAT: Rule[] = [{
    pattern: /^([0-9]{4})([\/])([0-9]{2})([\/])([0-9]{2})+$/,
    message: "YYYY/MM/DD Форматын дагуу оруулна уу."
}]

const ARE_PASSWORDS_SAME: Rule[] = [
    ({getFieldValue}) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Нууц үг таарахгүй байна!'));
        },
    }),
];

const REQUIRED_FIELD: Rule[] = [
    {
        required: true,
        message: "Талбар дутуу байна оруулна уу.",
    },
]

export const validations = {
    PASSWORD,
    ENTER_PASSWORD,
    ENTER_EMAIL,
    ENTER_CORPORATE_NAME,
    ENTER_COLOR,
    COLOR,
    NAME_MN,
    ARE_PASSWORDS_SAME,
    ENTER_EMPLOYEE_NAME,
    ENTER_PHONE_NUMBER,
    REGISTRATION_NUMBER,
    SAT_NUMBER,
    REQUIRED_FIELD,
    DATE_FORMAT,
    NAME_EN,
    GRADUATION_YEAR
}
