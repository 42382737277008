import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WidgetDetailId } from "../components/widgetDetail";
import { widgetService } from "../service/widget.service";

export default function NewsDetail() {
  const { newsId } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    widgetService.getNewsDetail(newsId).then((response) => {
      setData(response);
    });
  }, []);

  return <div>{data && <WidgetDetailId pageData={data} />}</div>;
}
