import { Modal, ModalFuncProps } from "antd";
import "./Modal.less";

function show(props: ModalFuncProps) {
  Modal.confirm({
    ...props,
    cancelText: props.cancelText ?? "Хаах",
    icon: undefined,
    title: <h5 className="text-center card-title">{props.title}</h5>,
    closeIcon: <></>,
    centered: true,
    autoFocusButton: null,
    okType: props.okType ?? "default",
    onCancel: props.onCancel ?? Modal.destroyAll,
    onOk: (e) => {
      if (props.onOk) {
        props.onOk(e);
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
  });
}

function confirm(props: ModalFuncProps) {
  Modal.confirm({
    ...props,
    cancelText: props.cancelText ?? "Хаах",
    icon: undefined,
    centered: true,
    title: <h5 className="text-center card-title">{props.title}</h5>,
    closeIcon: <></>,
    autoFocusButton: null,
    okType: props.okType ?? "default",
    onCancel: props.onCancel ?? Modal.destroyAll,
    onOk: (e) => {
      if (props.onOk) {
        props.onOk(e);
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
  });
}

function confirmForm(props: ModalFuncProps) {
  Modal.confirm({
    ...props,
    cancelText: props.cancelText ?? "Хаах",
    icon: undefined,
    centered: true,
    title: <h5 className="text-center card-title">{props.title}</h5>,
    closeIcon: <></>,
    autoFocusButton: null,
    okType: props.okType ?? "default",
    onCancel: props.onCancel ?? Modal.destroyAll,
    onOk: (e) => {},
  });
}

function showOnly(props: ModalFuncProps) {
  Modal.destroyAll();

  setTimeout(() => {
    show(props);
  });
}

function info(props: ModalFuncProps) {
  Modal.confirm({
    ...props,
    cancelText: props.cancelText ?? "Хаах",
    icon: undefined,
    title: <h5 className="text-center card-title">{props.title}</h5>,
    content: <h6>{props.content}</h6>,
    closeIcon: <></>,
    centered: true,
    autoFocusButton: null,
    okType: props.okType ?? "default",
    okButtonProps: { style: { display: "none" } },
    onCancel: props.onCancel ?? Modal.destroyAll,
    onOk: (e) => {
      if (props.onOk) {
        props.onOk(e);
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
  });
}

export const IOModal = {
  show,
  showOnly,
  confirm,
  confirmForm,
  info,
};
