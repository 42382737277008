import { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useParams } from "react-router-dom";
import { admitService } from "../service/admit.service";
import { Professions } from "../entity/admit.model";
import CustomLoader from "../../../public/components/CustomLoader";

const { Option } = Select;

export default function UserProfessionSelectForm() {
  const { admitId } = useParams();
  const [initialValue, setInitialValue] = useState<any>();
  const [professions, setProfessions] = useState<Professions[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    admitService.getUserProfessionList(admitId).then((res) => {
      setProfessions(
        res.admissionRegister.map((val: any) => {
          return {
            id: val.id,
            description: val.studyProgram.description,
            isDeleted: val.studyProgram.isDeleted,
          };
        })
      );
      admitService.getUserProfession(admitId).then((res) => {
        console.log(res);
        setInitialValue({ ...res });
        setLoading(false);
      });
    });
  }, []);

  const onFinish = (values: any) => {
    console.log(values);
    admitService
      .updateUserProfessionList(admitId, values.admissionRegister.toString())
      .then((res) => {
        console.log(res);
      });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <Form
            initialValues={initialValue}
            name="userProfession"
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="register-box">
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="admissionRegister" label="Мэргэжил сонгох">
                    <Select placeholder="Дарж сонгоно уу" allowClear>
                      {professions?.map((element) => {
                        return (
                          <>
                            {element.isDeleted == false && (
                              <Option key={element.id} value={element.id}>
                                {element.description}
                              </Option>
                            )}
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </>
  );
}
