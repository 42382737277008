import styled from "styled-components";

export const RegistrationAccordionStyle = styled.div`
  .ant-collapse{
  font-size: 18px !important;
  font-weight: bold;
  background-color: transparent !important;
  border-left: none !important;
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
}
  .ant-collapse>.ant-collapse-item{
    border-bottom: none !important;
    font-weight: normal;
    border-left: 3px solid #0132A6 !important;
    margin-bottom: 40px;
    padding-left: 40px;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header{
    border-bottom: none;
    font-weight: bold;
    font-size: 24px;
  }

  .ant-collapse-content{
    border-top: none !important;
  }
`;
