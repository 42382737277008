import logo from "../../../../assets/images/logo.png";
import { RiUserFill } from "react-icons/ri";
import { useContext, useEffect, useState } from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "../../../public/auth/service/auth.service";
import { helper } from "../../../../utils/helper/helper";
import storeService from "../../../../utils/helper/store";
import { UserHeaderStyle } from "./UserHeaderStyle";
import applicationRequestContext from "../../../../utils/context/ApplicationRequest";

export default function UserHeader() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = storeService.getAuth();

  useEffect(() => {
    let checkLogin = helper.isLoggedIn();
    setIsLoggedIn(checkLogin);
  }, []);

  const onLogOut = () => {
    authService.logout();
  };
  const ctx = useContext(applicationRequestContext);
  return (
    <UserHeaderStyle>
      <div className="navbar navbar-expand-lg navbar-light navbar-static">
        <div className="container-header navbar-nav ">
          <div>
            <Link to={"/"}>
              <img src={logo} alt="Logo" width={93} height={24} />
            </Link>
          </div>
          <div className="flex-wrap px-3 ml-auto d-flex px-sm-0 mobile-response">
            {isLoggedIn && (
              <div
                className="mt-2 mr-3 dropdown mt-lg-0 d-flex align-items-center"
                onClick={() => {}}
              >
                <div
                  onClick={() => {
                    ctx?.toggleModal();
                  }}
                  className="header-text"
                  style={{ cursor: "pointer" }}
                >
                  Өргөдөл, хүсэлт гаргах
                </div>
              </div>
            )}
            <div className="mt-2 mr-3 dropdown mt-lg-0 d-flex align-items-center">
              <Link to={"/"}>
                <div className="header-text">Нүүр хуудас</div>
              </Link>
            </div>
            <div className="mt-2 mr-3 dropdown mt-lg-0 d-flex align-items-center">
              <Link to={"/info"}>
                <div className="header-text">Мэргэжил, төлбөрийн мэдээлэл</div>
              </Link>
            </div>
            {isLoggedIn ? (
              <>
                <div className="mt-2 ml-2 dropdown mt-lg-0">
                  <button
                    type="button"
                    className="px-2 btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    id="dropdownMenuButton"
                  >
                    <RiUserFill size={12} className="m-0 mr-sm-2" />
                    <div className="d-none d-sm-block">{user?.email}</div>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="dropdown-item d-block d-sm-none">
                      {user?.email}
                    </div>
                    <Link to={"/user/home"} className="dropdown-item">
                      Хувийн мэдээлэл
                    </Link>
                    <Link to={"/"} className="dropdown-item" onClick={onLogOut}>
                      Гарах
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="px-2 mt-2 ml-2 btn btn-primary mt-lg-0"
                  onClick={() => navigate("/login")}
                >
                  <RiUserFill size={12} className="m-0 mr-sm-2" />
                  Нэвтрэх
                </button>
              </>
            )}
            {/*<div className="mt-2 ml-3 dropdown mt-lg-0">*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="px-2 mr-2 btn btn-primary"*/}
            {/*    data-toggle="dropdown"*/}
            {/*    aria-expanded="false"*/}
            {/*    aria-haspopup="true"*/}
            {/*    id="dropdownMenuButton"*/}
            {/*  >*/}
            {/*    <RiMenuFill size={12} className="m-0 mr-sm-2" />*/}
            {/*    <div className="d-none d-sm-block">Цэс</div>*/}
            {/*  </button>*/}
            {/*  <div*/}
            {/*    className="dropdown-menu"*/}
            {/*    aria-labelledby="dropdownMenuButton"*/}
            {/*  >*/}
            {/*    <NavigationMain />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="mobile-response-header">
            <div className="d-flex justify-content-center align-items-center">
              {isLoggedIn && (
                <div
                  className="mt-2 mr-3 dropdown mt-lg-0 d-flex align-items-center"
                  onClick={() => {}}
                >
                  <div
                    onClick={() => {
                      ctx?.toggleModal();
                    }}
                    className="header-text"
                    style={{ cursor: "pointer" }}
                  >
                    Өргөдөл, хүсэлт гаргах
                  </div>
                </div>
              )}
              <div className="mt-2 mr-3 dropdown mt-lg-0 d-flex align-items-center">
                <Link to={"/"}>
                  <div className="header-text">Элсэлт</div>
                </Link>
              </div>
              <div className="mt-2 mr-3 dropdown mt-lg-0 d-flex align-items-center">
                <Link to={"/info"}>
                  <div className="header-text">Сургалтын хэлбэр</div>
                </Link>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              {isLoggedIn ? (
                <>
                  <div className="mt-2 ml-2 dropdown mt-lg-0">
                    <button
                      type="button"
                      className="px-2 btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      aria-haspopup="true"
                      id="dropdownMenuButton"
                    >
                      <RiUserFill size={12} className="m-0 mr-sm-2" />
                      <div className="d-none d-sm-block">{user?.email}</div>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="dropdown-item d-block d-sm-none">
                        {user?.email}
                      </div>
                      <Link to={"/user/home"} className="dropdown-item">
                        Хувийн мэдээлэл
                      </Link>
                      <Link
                        to={"/"}
                        className="dropdown-item"
                        onClick={onLogOut}
                      >
                        Гарах
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="px-2 mt-2 ml-2 btn btn-primary mt-lg-0"
                    onClick={() => navigate("/login")}
                  >
                    <RiUserFill size={12} className="m-0 mr-sm-2" />
                    Нэвтрэх
                  </button>
                </>
              )}
              {/*<div className="mt-2 ml-3 dropdown mt-lg-0">*/}
              {/*  <button*/}
              {/*      type="button"*/}
              {/*      className="px-2 mr-2 btn btn-primary"*/}
              {/*      data-toggle="dropdown"*/}
              {/*      aria-expanded="false"*/}
              {/*      aria-haspopup="true"*/}
              {/*      id="dropdownMenuButton"*/}
              {/*  >*/}
              {/*    <RiMenuFill size={12} className="m-0 mr-sm-2" />*/}
              {/*    <div className="d-none d-sm-block">Цэс</div>*/}
              {/*  </button>*/}
              {/*  <div*/}
              {/*      className="dropdown-menu"*/}
              {/*      aria-labelledby="dropdownMenuButton"*/}
              {/*  >*/}
              {/*    <NavigationMain />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </UserHeaderStyle>
  );
}
