import { Route, Routes } from "react-router-dom";
import Footer from "../../public/components/Footer/Footer";
import React from "react";
import Home from "./screen/Home";
import UserHeader from "../components/PrivateHeader/UserHeader";
import Sat from "./screen/Sat";
import UserInfo from "./screen/Info";
import UserScorePrivate from "./screen/UserScorePrivate";
import UserProfessionSelect from "./screen/UserProfessionSelect";
import Time from "./screen/Time";
import Main from "../../public/home/screen/Main";

export default function HomeRoutes() {
  return (
    <>
      <UserHeader />
      {/*<div className="header-image">*/}
      {/*  <div className="header-shadow">*/}
      {/*    <div className="header-image-text">Элсэлт</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="page-content" style={{minHeight: '60vh'}}>
        <div className="content pb-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sat" element={<Sat />} />
            <Route path="/score" element={<UserScorePrivate />} />
            <Route
              path="/:id/profession/select"
              element={<UserProfessionSelect />}
            />
            <Route path="/info" element={<UserInfo />} />
            <Route path="/time" element={<Time />} />

            <Route path="/main" element={<Main />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
}
