import { constants } from "../constant/constants";
import { Auth } from "../../module/public/auth/entity/auth.model";

function getAuth(): Auth | undefined {
  const userString = localStorage.getItem(constants.USER_KEY);

  if (userString !== null) {
    return JSON.parse(userString);
  }

  return undefined;
}

function setAuth(user: Auth) {
  localStorage.setItem(constants.USER_KEY, JSON.stringify(user));
}

function getAuthRegister(): Auth | undefined {
  const userString = localStorage.getItem('registration');

  if (userString !== null) {
    return JSON.parse(userString);
  }

  return undefined;
}

function setAuthRegister(admissionRegisterList: any) {
  localStorage.setItem('registration', JSON.stringify(admissionRegisterList));
}

function clear() {
  localStorage.clear();
}

const storeService = {
  getAuth,
  setAuth,
  clear,
  getAuthRegister,
  setAuthRegister
};

export default storeService;
