import {Button, Modal, Collapse, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import UserProfessionSelectTable from "../components/UserProfessionSelectTable/UserProfessionSelectTable";
import {homeService} from "../service/home.service";
import {UserProfessionSelectTableUI} from "../entity/home.model";
import {admitService} from "../../admit/service/admit.service";
import {IOModal} from "../../../../utils/Modal/Modal";
import success from "../../../../assets/images/success.png";
import CustomLoader from "../../../public/components/CustomLoader";
import storeService from "../../../../utils/helper/store";
import FloatButton from "../../../public/components/FloatButton";
import {RiArrowRightSLine} from "react-icons/ri";

const {Panel} = Collapse;
const data = [
    {
        profession: "1",
        professionCount: 5,
        professionMax: 10,
        minScore: 630,
        select: "not-selected",
    },
];

export default function UserProfessionSelect() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {state} = useLocation() as any;
    let admitId = state.admitId;
    const [tableData, setTableData] = useState<UserProfessionSelectTableUI[]>([]);
    const [userData, setUserData] = useState<any>();
    const [admission, setAdmission] = useState<any>();
    const [profession, setProfession] = useState<string>("");
    const [professionId, setProfessionId] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [isFirst, setIsFirst] = useState(true);
    let userEmail = storeService.getAuth()?.email;

    const onClickPayment = () => {
        let formLink = "/user/admit/" + admitId + "/info"
        admitService
            .getPaymentCredit(admitId)
            .then((response) => {
                if (response.payment?.state !== "PAID") {
                    navigate("/user/payment/" + response.payment.id + "/qpay", {
                        state: {
                            satCode: 0,
                            admitId: admitId,
                        },
                    });
                }
            });
    };

    useEffect(() => {
        homeService.getAdmissionProfessionList(id).then((res) => {
            console.log(res, 'test');
            setAdmission(res.admission)
            setTableData(res.admissionRegisters);
            setUserData(res.ranking);
            setLoading(false);
        });
    }, []);


    useEffect(()=>{
        if (userData?.state === "accepted" && isFirst){
            setIsFirst(false)
            IOModal.show({
                title: "СЭЗИС-ийн бакалаврын хөтөлбөрт амжилттай элслээ.",
                content:
                    <div className="mt-2 text-center">
                        <div className="font-weight-bold">Танд баяр хүргэе.</div>
                        <img
                            src={success}
                            alt="/src/assets/images/success.png"
                            style={{width: "200px", height: "200px"}}
                            className="mt-3"
                        />
                        <div>
                            Таны {userData.admissionUser.user.email} хаягаар суралцах эрхийн бичиг илгээгдлээ.
                        </div>
                    </div>
                ,
                onCancel: (args) => {
                    Modal.destroyAll();
                },
                okText: "Дуусгах",
                okButtonProps: {
                    style: {
                        display: "flex",
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }
                },
                cancelButtonProps: {style: {display: "none"}},
                onOk: (args) => {
                    Modal.destroyAll();
                    navigate("/user/home");
                }
            });
        }
    }, [userData])


    return (
        <div className="container">
            {loading ? (
                <CustomLoader/>
            ) : (
                <div className="row">
                    <div className="mt-2 mb-2 col-lg-12 d-flex align-items-center">
                        <h1>Мэргэжил, хөтөлбөр сонголтын заавар</h1>
                    </div>

                    <div className="mt-2 mb-4 col-lg-12 align-items-center">
                        <ol>
                            <li className="mb-2">
                                Элсэн суралцах мэргэжил, хөтөлбөрөө анги дүүргэлтээс хамаарч “Мэргэжил сонгох” товчийг
                                дарж сонгон, “Мэргэжил батлах” товчийг дарснаар мэргэжил, хөтөлбөр баталгаажна.
                            </li>
                            <li className="mb-2">
                                Мэргэжил, хөтөлбөрөө баталгаажуулсны дараа сонгосон мэргэжил, хөтөлбөрөө цуцалсан
                                тохиолдолд урьдчилгаа төлбөрийн 6%-ийг суутгана.
                            </li>
                            <li className="mb-2">
                                Элсэлттэй холбоотой өргөдөл, хүсэлтийг admission_bachelor@ufe.edu.mn хаягаар хүлээн авч,
                                журмын дагуу шийдвэрлэнэ. Мэргэжил, хөтөлбөр солих тохиолдол дараах холбоосоор
                                өргөгдлийн маягтыг <a href="/20230609-өөрчлөх-маягт-БЭ001.pdf">ЭНД дарж</a> авч дээрх хаягаар илгээнэ үү.
                            </li>
                        </ol>
                    </div>
                    <div className="col-lg-6">
                        <h3>Сонгосон мэргэжил:</h3>
                        <h5 className="my-3">{profession}</h5>
                        {userData.state === "pending" && (
                            // <Tooltip title="Та төлбөрөө төлсний дараа мэргэжил сонгох боломжтой болно">
                            //     <Button
                            //         type="primary"
                            //         className={`ml-4  w-50 ${
                            //             profession ? "submit-btn" : "back-btn"
                            //         }`}
                            //         onClick={onClick}
                            //         disabled={profession && userData.admissionUser?.state === "C" ? false : true}
                            //     >
                            //         {" "}
                            //         Мэргэжил батлах{" "}
                            //     </Button>
                            // </Tooltip>
                            <></>
                        )}
                        {/*{userData?.state === "accepted" && (*/}
                        {/*    <Button*/}
                        {/*        type="primary"*/}
                        {/*        className={`ml-4  w-50 submit-btn`}*/}
                        {/*        onClick={onClickFormFill}*/}
                        {/*    >*/}
                        {/*        {" "}*/}
                        {/*        Дэлгэрэнгүй анкет{" "}*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </div>
                    <div className="mt-2 col-lg-6">
                        <h5>Таны дундаж ЭЕШ-ын оноо: {userData.scoreSat}</h5>
                        {/* <h5>Ур чадварын оноо: {userData.scoreSkill}</h5> */}
                    </div>
                    {
                        userData.admissionUser.state === "R" && (
                            <div className="mt-2 col-lg-6">
                                <Button
                                    type="primary"
                                    className={`ml-4 w-50 submit-btn`}
                                    style={{width: "fit-content"}}
                                    onClick={onClickPayment}
                                >
                                    Төлбөр төлөх
                                </Button>
                            </div>
                        )
                    }

                    <div className="mt-5 col-lg-12">
                        {/* <Collapse bordered={false} defaultActiveKey={["0"]}>
              {tableData.map((news: any, index: number) => {
                return (
                  <Panel
                    header={
                      <div className="font-weight-bold">
                        {news.name && news.name}
                      </div>
                    }
                    key={index}
                  > */}
                        <UserProfessionSelectTable
                            data={tableData}
                            userData={userData}
                            setProfession={setProfession}
                            profession={profession}
                            setProfessionId={setProfessionId}
                            professionId={professionId}
                            scoreSat={userData.scoreSat}
                            loading={loading}
                            setLoading={setLoading}
                            admission={admission}
                        />
                        {/* </Panel>
                );
              })}
            </Collapse> */}
                    </div>
                </div>
            )}{" "}
            <FloatButton/>
        </div>
    );
}
