import React, { useEffect, useState } from "react";
import {Alert, Button, Form, Select} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import { admitService } from "../service/admit.service";
import { Professions } from "../entity/admit.model";
import CustomLoader from "../../../public/components/CustomLoader";
import {authService} from "../../../public/auth/service/auth.service";
import storeService from "../../../../utils/helper/store";

const { Option } = Select;

export default function UserProfessionSelectFormFinal(props: { admitId: any, admissionState: any }) {
  const { admitId, admissionState } = props;
  const [initialValue, setInitialValue] = useState<any>();
  const [professions, setProfessions] = useState<Professions[]>();
  const [loading, setLoading] = useState(true);
  const [admission, setAdmission] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    admitService.getUserProfessionList(admitId).then((res) => {
      setProfessions(
        res.admissionRegister.map((val: any) => {
          return {
            id: val.id,
            description: val.studyProgram.description,
            isDeleted: val.studyProgram.isDeleted,
          };
        })
      );
      admitService.getUserProfession(admitId).then((res) => {
        console.log(res);
        setInitialValue({ ...res });
      });
      admitService.getAdmissionConfig(admitId).then((res)=>{
        setAdmission(res.admissions);
        setLoading(false);
      })
    });
  }, []);

  console.log(admission, "admission")

  const onFinish = (values: any) => {
    console.log(values);
    admitService
      .updateUserProfessionFinalList(admitId, values.admissionRegister.toString())
      .then((res) => {
        console.log(res);
        authService.admissionUserRegisteredList().then(async (res: any)=>{
          let registrationUserList: any = [];
          res.admissionUserList.map((value: any)=>{
            console.log(value.admission)
            if(value.admission && value.state === "R"){
              registrationUserList.push({
                'admissionId': value.admission?.id,
                'satExam': value.admission?.satExam,
                'state': value.state
              })
            }
            if(value.admission && value.state === "C"){
              registrationUserList.push({
                'admissionId': value.admission?.id,
                'satExam': value.admission?.satExam,
                'state': value.state
              })
            }
          })

          await storeService.setAuthRegister(registrationUserList.filter((value:any) => value !== null))
          window.location.reload();
        })
      });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <h3 className="mb-2">
            {admission?.name}
          </h3>
          <Form
            initialValues={initialValue}
            name="userProfession"
            layout="vertical"
            onFinish={onFinish}
          >
            <div className="register-box">
              <div className="row">
                <div className="col-md-12">
                  <Form.Item name="admissionRegister" label="Мэргэжил сонгох">
                    {
                      // admissionState === "R" ?
                      //     <Select placeholder="Дарж сонгоно уу" allowClear>
                      //       {professions?.map((element) => {
                      //         return (
                      //             <>
                      //               {element.isDeleted == false && (
                      //                   <Option key={element.id} value={element.id}>
                      //                     {element.description}
                      //                   </Option>
                      //               )}
                      //             </>
                      //         );
                      //       })}
                      //     </Select>
                          // :
                          <Select placeholder="Дарж сонгоно уу" allowClear disabled>
                            {professions?.map((element) => {
                              return (
                                  <>
                                    {element.isDeleted == false && (
                                        <Option key={element.id} value={element.id}>
                                          {element.description}
                                        </Option>
                                    )}
                                  </>
                              );
                            })}
                          </Select>
                    }
                  </Form.Item>
                  <Alert
                      message="Санамж"
                      description={<>Мэргэжил сонголт баталгаажуулснаар сургалтын төлөвлөгөөтэй холбогдож, хичээл сонголтын эрх нээгдэнэ.<br/>
                        Мэргэжил сонголтоо солих тохиолдолд ахисан түвшний сургуульд хүсэлт цахим шуудангаар ирүүлэх, дараа нь тушаал гарснаар солигдох боломжтой.</>}
                      type="info"
                      showIcon
                      className="mx-2 mb-3 w-100"
                  />

                </div>
                <div className="col-lg-3" />
                <div className="col-lg-6">
                  <div className="d-flex">
                    <Button
                        type="primary"
                        className="submit-btn mx-2"
                        onClick={() => navigate(-1)}
                    >
                      Буцах
                    </Button>
                    {
                      // admissionState === "R" &&
                      //   (
                      //       <Button
                      //           type="primary"
                      //           className="submit-btn mx-2"
                      //           htmlType="submit"
                      //           form="userProfession"
                      //       >
                      //         Хадгалах
                      //       </Button>
                        // )
                    }
                  </div>
                </div>
                <div className="col-lg-3" />
                <div className="col-lg-12 mb-2" />

              </div>
            </div>
          </Form>
        </>
      )}
    </>
  );
}
