import { ColumnsType } from "antd/lib/table";
import { Table } from "antd";
import React from "react";
import { UserProfessionViewTableStyle } from "./UserProfessionViewTableStyle";
import { UserProfessionSelectTableUI } from "../../entity/home.model";

export default function UserProfessionViewTable(props: {
  data: UserProfessionSelectTableUI[];
}) {
  let columns: ColumnsType<UserProfessionSelectTableUI> = [
    {
      title: "Мэргэжил, чиглэл",
      dataIndex: "degreeProfessionGroupName",
      width: "30%",
      key: "degreeProfessionGroupName",
    },
    {
      title: "Хөтөлбөр",
      dataIndex: "degreeProgramName",
      width: "30%",
      key: "degreeProgramName",
    },
    {
      title: "Анги дүүргэлт",
      dataIndex: "progress",
      key: "progress",
      render: (count, record) => (
        <>
          {count >= record.capacity ? (
            <div className="text-danger">Энэ мэргэжлийн анги дүүрсэн</div>
          ) : (
            count + "/" + record.capacity
          )}
        </>
      ),
    },
    {
      title: "ЭЕШ-ын дундаж оноо",
      dataIndex: "minScoreRanking",
      key: "minScoreRanking",
    }
  ];

  return (
    <UserProfessionViewTableStyle>
      <Table
        columns={columns}
        dataSource={props.data}
        className="table table-responsive"
        pagination={false}
        rowClassName="table-row"
      />
    </UserProfessionViewTableStyle>
  );
}
