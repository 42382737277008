import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { TimesTableStyle } from "./TimesTableStyle";

export default function TimesTable(props: { data: any }) {
  let columns: ColumnsType<any> = [
    {
      title: "Жагсаалтын онооны эрэмбэ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Нээгдэх цаг",
      dataIndex: "time",
      key: "time",
    },
  ];

  return (
    <TimesTableStyle>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={props.data}
        className="table table-responsive mb-3"
        pagination={false}
        rowClassName="table-row"
        scroll={{ x: "max-content", y: 500 }}
      />
    </TimesTableStyle>
  );
}
