import { QuestionCircleOutlined } from "@ant-design/icons";
import { IOModal } from "../../../utils/Modal/Modal";
import Instruction from "./Instruction";

export default function FloatButton() {
  return (
    <a
      style={{
        display: "flex",
        position: "fixed",
        width: "60px",
        height: "60px",
        bottom: "40px",
        right: "40px",
        backgroundColor: "#0c44c9",
        color: "#FFF",
        borderRadius: "50px",
        textAlign: "center",
        boxShadow: "2px 2px 3px rgba(84, 84, 84, 0.2)",
        fontSize: "large",
      }}
      className="d-flex justify-content-center align-items-center"
      onClick={() =>
        IOModal.info({
          title: "Мэргэжил сонгон баталгаажуулах заавар",
          content: <Instruction />,
        })
      }
    >
      ?
    </a>
  );
}
