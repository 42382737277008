import {Button} from "antd";
import {RiSaveFill} from "react-icons/ri";
import {useNavigate} from "react-router-dom";

export default function FormButtons() {
    const navigate = useNavigate()
    return(
        <div className="row mt-5">
            <div className="col-md-12 mt-5">
                {/*<p className="text-center text-muted">Бүтгэлтэй холбоотой асуудлаар <a className="text-info">itsupport@ufe.edu.mn</a> хаяг руу хандана уу.</p>*/}
            </div>
        </div>
    )
}
