import styled from "styled-components";

export const InfoStyle = styled.div`
  @media screen and (max-width: 918px) {
    h1{
      font-size: 24px;
      margin-bottom: 10px !important;
    }
    .mobile-responsive{
      display: block !important;
    }
    .mobile-responsive-select{
      margin-top: 5px;
      margin-bottom: 5px;
    }
    h4{
      font-size: 16px;
    }
    .mobile-responsive-8{
      margin-top: 10px;
    }
  }
  .ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 16px;
    color: rgba(0,0,0,.85);
    line-height: 1.5715;
    cursor: pointer;
    transition: all .3s,visibility 0s;
  }

  .ant-collapse>.ant-collapse-item {
    border-bottom: 0px solid #d9d9d9;
  }
  .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header {
    color: #0132A6;
  }
  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-weight: 600;
    font-size: 14px;
  }
  .ant-collapse-content {
    color: #141719;
    background-color: #fff;
    border-top: 0px solid #d9d9d9;
    font-weight: 400;
    font-size: 12px;
    display: none;
  }

  .ant-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    margin-bottom: 16px;
  }
  
  .ant-radio {
    top: 0;
  }

  .ant-radio-wrapper {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-weight: 600;
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 8px 0 0;
    cursor: pointer;
  }

  .ant-radio-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #0132A6;
    border-radius: 50%;
    visibility: hidden;
    -webkit-animation: antRadioEffect .36s ease-in-out;
    animation: antRadioEffect .36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    content: "";
  }

  .ant-radio-inner {
    border: 2px solid #E4E4EB !important;
  }

  .ant-radio-inner::after {
    background-color: #FFFFFF;
    width: 16px !important;
    height: 16px !important;
    margin-top: -8px !important;
    margin-left: -8px !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border: 4px solid #0132A6 !important;
  }
  
`;
