import { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { validations } from "../../../../utils/validation/validations";
import { RiAddCircleFill, RiDeleteBin5Line } from "react-icons/ri";
import { paymentService } from "../../payment/service/payment.service";
import { UserEmploymentUI } from "../entity/admit.model";
import { admitService } from "../service/admit.service";
import CustomLoader from "../../../public/components/CustomLoader";

const { Option } = Select;

export default function UserEmploymentForm() {
  const [loading, setLoading] = useState(true);
  const [workSection, setWorkSection] = useState<any>();
  const [userEmployment, setUserEmployment] = useState<UserEmploymentUI[]>([{}]);

  useEffect(() => {
    paymentService.getGlobalConstants("work_section").then((res) => {
      console.log(res);
      setWorkSection(res.globalConstants);
      admitService.getUserEmployment().then((res) => {
        if (res.userEmployments.length !== 0){
          setUserEmployment(
              res.userEmployments.map((val: any) => {
                return {
                  ...val,
                  workSection: val.workSection?.id,
                };
              })
          );
        }
        setLoading(false);
      });
    });
  }, []);

  const onFinish = (values: any) => {
    admitService.updateUserEmployment(values).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form name="userEmployment" layout="vertical" onFinish={onFinish}>
          <div className="register-box">
            <div className="row">
              <Form.List name="userEmployment" initialValue={userEmployment}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "workSection"]}
                            label="Ажиллаж байгаа салбар"
                          >
                            <Select placeholder="Дарж сонгоно уу" allowClear>
                              {workSection.map((element: any) => (
                                <Option value={element.id} key={element.id}>
                                  {element.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "organizationName"]}
                            label="Байгууллагын нэр"
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <Form.Item
                            {...fields}
                            name={[field.name, "jobTitle"]}
                            label="Албан тушаал"
                          >
                            <Input />
                          </Form.Item>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <RiDeleteBin5Line
                            size={32}
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      </>
                    ))}
                    <div className="col-md-12 d-flex">
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<RiAddCircleFill size={20} />}
                          className="d-flex"
                        >
                          Нэмэх
                        </Button>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.List>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
