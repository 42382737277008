import { LoadingOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { useEffect, useState } from "react";
import "./UploadImage.css";
import { constants } from "../../constant/constants";
import CustomLoader from "../../../module/public/components/CustomLoader";
import { RiCheckLine } from "react-icons/ri";

function getBase64(img: Blob, callback: any) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function MUploadImage(props: {
  value?: string;
  onChange?: (file: File) => void;
  uploadButton: any;
}) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    if (props.value) {
      setImageUrl(constants.BASE_URL + props.value);
      setFileName("Харах");
    }
  }, [props.value]);

  const handleChange = (info: any) => {
    console.log(info);
    setFileName(info.file.name);
    getBase64(info.file.originFileObj, (_imageUrl: any) => {
      setLoading(false);
      setImageUrl(_imageUrl);
    });
  };

  const onUpload = async (options: any) => {
    const { onSuccess, file } = options;
    props.onChange && props.onChange(file);
    onSuccess(file);
  };

  return (
    <div className="d-flex flex-column">
      <div className="">
        <Upload
          name="avatar"
          showUploadList={false}
          onChange={handleChange}
          customRequest={onUpload}
        >
          {props.uploadButton}
        </Upload>
      </div>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="mt-2 d-inline-flex">
            <a href={imageUrl}>
              <h1 className="text-muted font-size-sm">
                {fileName ? `${fileName}` : "Хоосон"}
              </h1>
            </a>
            {fileName && (
              <RiCheckLine className="ml-2 pb-1" size={20} color="#48ba0f" />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MUploadImage;
