import RegistrationInformationCard from "../components/RegistrationInformationCard";
import { Tabs } from "antd";
import RegistrationText from "../components/RegistrationText";
import { useEffect, useState } from "react";
import { informationService } from "../service/information.service";
import { useParams } from "react-router-dom";
import {
  AdmissionUI,
  Information,
  InformationUI,
  RegistrationInformationUI,
} from "../entity/information.model";
import RegistrationAccordion from "../components/RegistrationAccordion";
import Widget from "../components/widget";
import YouTube, { YouTubeProps } from "react-youtube";
import InstructionAccordion from "../components/instructionAccordion/InstructionAccordion";
import CustomLoader from "../../../public/components/CustomLoader";

const { TabPane } = Tabs;

export default function Registration() {
  const { id } = useParams();
  const [professions, setProfessions] = useState<RegistrationInformationUI>();
  const [admissions, setAdmissions] = useState<AdmissionUI>();
  const [degreeInfo, setDegreeInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [single, setSingle] = useState(true);
  const [multipleProfessions, setMultipleProfessions] = useState<any>();
  const [info, setInfo] = useState<Information[]>();
  const [type, setType] = useState<string>();

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: "100%",
  };

  const groupProfessions = (xs: any, key: any) => {

    return xs.reduce(function (rv: any, x: any) {
      const professionName = x[key]?.name ?? '';
      (rv[professionName] = rv[professionName] || []).push(x);
      return rv;
    }, {});
  };


  useEffect(() => {
    setLoading(true);
    let multiple: any;
    informationService.getDegreeInfo(id).then((response) => {
      let grouped = groupProfessions(response.professionGroups, "groupName");
      if (Object.keys(grouped).length >= 1) {
        setSingle(false);
        multiple = Object.entries(grouped).map(([key, value]) => ({
          key,
          value,
        }));
        setMultipleProfessions(multiple);
        setType(multiple[0].key);
      }
      setProfessions(response);
      informationService
        .getAdmission(parseInt(id ? id : ""))
        .then((response) => {
          setAdmissions(response);
          informationService.getDegreeGuide(id ? id : "").then((res) => {
            setInfo(
              res.map((element: InformationUI) => {
                return {
                  name: element.name,
                  content: (
                    <YouTube
                      videoId={element.description}
                      opts={opts}
                      onReady={onPlayerReady}
                    />
                  ),
                };
              })
            );
            setLoading(false);
          });
        });
    });
  }, []);

  useEffect(() => {
    informationService.getDegreeInfoImages(id ? id : "").then((response) => {
      const { degreeInfos } = response;
      let degreeInformation = Object.entries(degreeInfos).map(
        ([key, value]) => ({ key, value })
      );
      let returnVal: any = [];
      degreeInformation.forEach((info: any, index: number) => {
        let arrayDegree: any = [];
        info.value.forEach((element: any) => {
          if (element.groupName?.name === type) {
            arrayDegree.push(element);
          }
        });

        if (arrayDegree.length > 0) {
          returnVal.push({ key: info.key, value: arrayDegree });
        }
      });
      setDegreeInfo(returnVal);
    });
  }, [type]);

  const onChange = (activeKey: string) => {
    setType(multipleProfessions[activeKey].key);
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Tabs defaultActiveKey="0">
          <TabPane tab="Бүртгэл" key="0">
            <h1 className="text-center">Бүртгэл</h1>
            {admissions?.admissions.map((admission) => {
              return !admission.isDeleted ? (
                <RegistrationText {...admission} key={admission.code} />
              ) : null;
            })}

            <Widget admissions={admissions} id={id} />
            <h1 className="my-4">Элсэгчдэд өгөх зөвлөмж</h1>
            <InstructionAccordion data={info} />
            {/*<Poolcast/>*/}
          </TabPane>
          <TabPane tab="Сургалтын хэлбэр" key={10}>
            {single ? (
              <>
                <Tabs defaultActiveKey="0">
                  <TabPane tab="Үндсэн мэдээлэл">
                    {professions?.professionGroups.map((profession) => {
                      return (
                        <RegistrationInformationCard
                          key={profession.id}
                          profession={profession}
                        />
                      );
                    })}
                  </TabPane>
                  {degreeInfo.map((info: any, index: number) => {
                    return (
                      <TabPane tab={info.key} key={index + 1}>
                        <RegistrationAccordion
                          data={info.value}
                          type={info.key}
                        />
                      </TabPane>
                    );
                  })}
                </Tabs>
              </>
            ) : (
              <Tabs defaultActiveKey="0" onChange={onChange}>
                {multipleProfessions.map((element: any, index: number) => {
                  return (
                    <TabPane tab={element.key} key={index}>
                      <Tabs defaultActiveKey="0">
                        <TabPane tab="Мэргэжил, суралцах хэлбэр">
                          {element.value.map((profession: any) => {
                            return (
                              profession.isDeleted == false && (
                                <RegistrationInformationCard
                                  key={profession.id}
                                  profession={profession}
                                />
                              )
                            );
                          })}
                        </TabPane>
                        {degreeInfo.map((info: any, index: number) => {
                          return (
                            <TabPane tab={info.key} key={index + 1}>
                              <RegistrationAccordion
                                data={info.value}
                                type={info.key}
                              />
                            </TabPane>
                          );
                        })}
                      </Tabs>
                    </TabPane>
                  );
                })}
              </Tabs>
            )}
          </TabPane>
        </Tabs>
      )}
    </>
  );
}
