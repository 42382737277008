import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function CustomLoader() {
  return (
    <div className="mt-5 text-center">
      <Spin size="large" indicator={antIcon} className="mb-2" />
      <div>Уншиж байна.</div>
    </div>
  );
}
