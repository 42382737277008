import styled from "styled-components";

export default function Circle(props: { size?: any; color?: any }) {
  const CircleStyle = styled.div`
    .draw {
      width: ${props.size};
      height: ${props.size};
      background-color: ${props.color};
      border-radius: 50%;
    }
  `;
  return (
    <CircleStyle>
      <div className="mr-1 draw"></div>
    </CircleStyle>
  );
}
