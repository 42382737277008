import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/font.css";
import "./assets/custom.css";
import "./assets/UploadButton.css";
import "antd/dist/antd.min.css";
import "antd-country-phone-input/dist/index.css";
import { createRoot } from "react-dom/client";
const root = createRoot(document.getElementById("root") as HTMLElement); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
