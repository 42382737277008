import { api } from "../../../../utils/helper/api";
import { AdmissionRegisterUI } from "../entity/payment.mode";

const createInvoiceQpay = (id: string | undefined): Promise<any> => {
  return api.get("/payment/" + id + "/invoice/qpay/create");
};

const currentInvoiceQpay = (id: string | undefined): Promise<any> => {
  return api.get("/payment/" + id + "/invoice/qpay/current");
};

const checkInvoiceQpay = (invoiceCode: string | undefined): Promise<any> => {
  return api.get("/payment/invoice/check", {
    params: { invoiceCode: invoiceCode },
  });
};

const registerAdmission = (
  registerAdmission: AdmissionRegisterUI,
  id: string
): Promise<any> => {
  return api.post("/admission/" + id + "/register", registerAdmission);
};

const getGlobalConstants = (groupName: string): Promise<any> => {
  return api.get("/global/constant/" + groupName + "/list");
};

const getGlobalConstantsWithAdmissionId = (groupName: string, admissionId: string): Promise<any> => {
  return api.get("/global/constant/" + groupName + "/list/admission/" + admissionId);
};

export const paymentService = {
  createInvoiceQpay,
  checkInvoiceQpay,
  registerAdmission,
  getGlobalConstants,
  currentInvoiceQpay,
  getGlobalConstantsWithAdmissionId
};
