import { Collapse } from 'antd';
import { InformationAccordionStyle } from "./InformationAccordionStyle";
const { Panel } = Collapse;

export default function InformationAccordion(props: {data: any}) {
    return(
        <InformationAccordionStyle>
            <Collapse
                expandIconPosition={"right"}
            >
                {
                    props.data.map((element:any, index: number)=>(
                        <Panel header={element.name} key={index}>
                            <>
                                {element.content}
                            </>
                        </Panel>
                    ))
                }
            </Collapse>
        </InformationAccordionStyle>
    )
}