import {api} from "../../../../../utils/helper/api";
import {DegreeUI, NavigationUI, DegreeGroupUI} from "../entity/header.model";

const getDegreeGroup = (): Promise<DegreeGroupUI> => {
    return api.get("/_open/degree-group");
};

const getDegreeGroupName = (degreeGroupId:string | undefined): Promise<any> => {
    return api.post(`/_open/degree-group/name`, {degreeGroupId});
};

const getDegree = (degreeGroupId:string | undefined): Promise<DegreeUI> => {
    return api.post(`/_open/degree/` , {degreeGroupId});
};

const getDegreeTraining = (): Promise<any> => {
    return api.get("/_open/degree/training");
};

export const headerService = {
    getDegree,
    getDegreeTraining,
    getDegreeGroup,
    getDegreeGroupName
};


