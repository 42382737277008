import {Link, useLocation} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { homeService } from "../service/home.service";
import {Collapse, Radio, RadioChangeEvent, Select} from "antd";
import {RiArrowDownSLine, RiSubtractLine} from "react-icons/ri";
import {HomeStyle} from "./HomeStyle";
import UserInfo from "./Info";
import Sat from "./Sat";
import UserScorePrivate from "./UserScorePrivate";
import Time from "./Time";
import UserProfessionViewTable from "../components/UserProfessionViewTable/UserProfessionViewTable";
import {UserProfessionSelectTableUI} from "../entity/home.model";
import storeService from "../../../../utils/helper/store";
import {informationService} from "../../../public/information/service/information.service";
import {RankingKindUI} from "../../../public/information/entity/information.model";
import YouTube from "react-youtube";
import {onPlayerReady, opts} from "../../../public/home/screen/Main";
import UserProfessionSelectForm from "../../admit/components/UserProfessionSelectForm";
import UserProfessionSelectFormFinal from "../../admit/components/UserProfessionSelectFormFinal";
const { Panel } = Collapse;

export default function Home() {
  const [check, setCheck] = useState<boolean>();
  const [checkSat, setCheckSat] = useState<boolean>();
  const location: any = useLocation();
  const [tableData, setTableData] = useState<UserProfessionSelectTableUI[]>([]);
  const [rankingKind, setRankingKind] = useState<RankingKindUI[]>();
  const [rankingId, setRankingId] = useState<any>();
  const [admissionRegisterNonSat, setAdmissionRegisterNonSat] = useState<any>([])
  const [admissionRegisterNonSatCheck, setAdmissionRegisterNonSatCheck] = useState<any>(false)

  useEffect(() => {
    let admissionRegister: any = storeService.getAuthRegister();
    let admissionRegisterNonSatArray: any = []
    admissionRegister?.map((val: any)=>{
      if (val.satExam === false && (val.state === "R" || val.state === "C") && (val.admissionId === 109 || val.admissionId === 67 || val.admissionId === 66)){
        setAdmissionRegisterNonSatCheck(true)
        admissionRegisterNonSatArray.push(val)
      }
    })
    setAdmissionRegisterNonSat(admissionRegisterNonSatArray)
    homeService.admissionSatCheck().then((res) => {
      setCheck(res.check);
      setCheckSat(res.sat);
    });
  }, []);

  useEffect(() => {
    informationService.getRankingKind().then((res) => {
      setRankingKind(res.ranking);
      if(res.ranking){
        setRankingId(res.ranking[0].id)
      }
    })
  }, []);


  useEffect(() => {
    homeService.getAdmissionProfessionList(rankingId).then((res) => {
      setTableData(res.admissionRegisters);
    });
  }, [rankingId]);
  console.log("location.state?.clickButtonType", location.state?.clickButtonType)
  const [activeKey, setActiveKey] = useState<any>()
  const [radioValue, setRadioValue] = useState(1);
  const [defaultActiveKey, setDefaultActiveKey] = useState(location.state?.clickButtonType === "register" ? 2 : 1);

  const onChange = (key: string | string[]) => {
    setActiveKey(key)
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setRadioValue(e.target.value);
  };

  useEffect(()=>{
    if (location.state?.clickButtonType === "register"){
      setActiveKey(2)
      setRadioValue(3)
    }
  },[])

  console.log(rankingKind)

  const handleChange = (value: any) => {
    setRankingId(value)
  }

  return (
    <div className="container">
      {/*{check && (*/}
      {/*  <>*/}
      {/*    <h3 className="mb-4">Мэргэжил, хөтөлбөр баталгаажуулах</h3>*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-md-6">*/}
      {/*        <div className="card">*/}
      {/*          <div className="card-body">*/}
      {/*            <h5 className="card-title">Онооны жагсаалт</h5>*/}
      {/*            <h6 className="mb-2 card-subtitle text-muted"></h6>*/}
      {/*            <Link to="/user/home/score" className="card-link">*/}
      {/*              Харах*/}
      {/*            </Link>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-6">*/}
      {/*        <div className="card">*/}
      {/*          <div className="card-body">*/}
      {/*            <h5 className="card-title">*/}
      {/*              Мэргэжил, хөтөлбөр сонгох цагийн хуваарь*/}
      {/*            </h5>*/}
      {/*            <h6 className="mb-2 card-subtitle text-muted"></h6>*/}
      {/*            <Link to="/user/home/time" className="card-link">*/}
      {/*              Харах*/}
      {/*            </Link>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}

      {/*<h3 className="mb-4">Хувийн мэдээлэл</h3>*/}
      {/*<div className="mt-4 row">*/}
      {/*  <div className="col-md-6">*/}
      {/*    <div className="card">*/}
      {/*      <div className="card-body">*/}
      {/*        <h5 className="card-title">Үндсэн мэдээлэл</h5>*/}
      {/*        <h6 className="mb-2 card-subtitle text-muted"></h6>*/}
      {/*        <Link to="/user/home/info" className="card-link">*/}
      {/*          Харах*/}
      {/*        </Link>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  {check && (*/}
      {/*    <div className="col-md-6">*/}
      {/*      <div className="card">*/}
      {/*        <div className="card-body">*/}
      {/*          <h5 className="card-title">БҮТ мэдээлэл</h5>*/}
      {/*          <h6 className="mb-2 card-subtitle text-muted"></h6>*/}
      {/*          <Link to="/user/home/sat" className="card-link">*/}
      {/*            Харах*/}
      {/*          </Link>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <HomeStyle>
        <div className={"row"}>
          <div className="col-md-4">
            <Collapse
                onChange={onChange}
                accordion
                defaultActiveKey={defaultActiveKey}
            >
              {
                  check && (
                      <Panel header={'Мэргэжил, хөтөлбөр баталгаажуулах'} key={2} showArrow={false} extra={parseInt(activeKey)===2 ? <RiSubtractLine size={24}/> : <RiArrowDownSLine size={24}/>}>
                        <Radio.Group onChange={onChangeRadio} value={radioValue}>
                          <Radio value={3}>Онооны жагсаалт</Radio>
                        </Radio.Group>
                        <Radio.Group onChange={onChangeRadio} value={radioValue}>
                          <Radio value={4}>Мэргэжил, хөтөлбөр сонгох цагийн хуваарь</Radio>
                        </Radio.Group>
                        <Radio.Group onChange={onChangeRadio} value={radioValue}>
                          <Radio value={5}>Анги дүүргэлтийн мэдээлэл харах</Radio>
                        </Radio.Group>
                        <Radio.Group onChange={onChangeRadio} value={radioValue}>
                          <Radio value={6}>Заавар</Radio>
                        </Radio.Group>
                      </Panel>
                  )
              }
              <Panel header={'Хувийн мэдээлэл'} key={1} showArrow={false} extra={parseInt(activeKey)===1 ? <RiSubtractLine size={24}/> : <RiArrowDownSLine size={24}/>}>
                <Radio.Group onChange={onChangeRadio} value={radioValue}>
                  <Radio value={1}>Үндсэн мэдээлэл</Radio>
                </Radio.Group>
                {
                  checkSat && (
                        <Radio.Group onChange={onChangeRadio} value={radioValue}>
                          <Radio value={2}>БҮТ мэдээлэл</Radio>
                        </Radio.Group>
                    )
                }
                {
                    admissionRegisterNonSatCheck && (
                        <Radio.Group onChange={onChangeRadio} value={radioValue}>
                          <Radio value={7}>Мэргэжил сонгох</Radio>
                        </Radio.Group>
                    )
                }
              </Panel>

            </Collapse>
          </div>
          <div className="col-md-8 mt-2">
            {
                radioValue === 1 && (
                  <>
                    <UserInfo />
                  </>
                )
            }
            {
                radioValue === 2 && (
                    <>
                      <Sat />
                    </>
                )
            }
            {
                radioValue === 3 && (
                    <>
                      <UserScorePrivate />
                    </>
                )
            }
            {
                radioValue === 4 && (
                    <>
                      <Time />
                    </>
                )
            }
            {
                radioValue === 5 && (
                    <>
                      <div className="d-flex align-items-center my-2">
                        <h1 className={"mr-5"}>Бүлэг сонгох: </h1>
                        {
                            rankingKind && (
                                <Select
                                  defaultValue={rankingKind ? rankingKind : ""}
                                  style={{width: 350}}
                                  onChange={handleChange}
                                  options={rankingKind && rankingKind?.map((data: any) => ({label: `${data.name} / ${data.description}`, value: data.id}))}
                                />
                          )
                        }
                      </div>
                      <UserProfessionViewTable data={tableData} />
                    </>
                )
            }
            {
                radioValue === 6 && (
                    <>
                      <YouTube
                          videoId={"lQkLFPCUZ0Q"}
                          opts={opts}
                          onReady={onPlayerReady}
                      />
                      <iframe
                          className="w-100"
                          style={{ height: "600px" }}
                          src={'/admission-guide.pdf'}
                      />
                    </>
                )
            }
            {
                radioValue === 7 && (
                    <>
                      {
                        admissionRegisterNonSat.map((value:any)=>{
                          return(
                              <UserProfessionSelectFormFinal admitId={value.admissionId} admissionState={value.state}/>
                          )
                        })
                      }
                    </>
                )
            }
          </div>



        </div>
      </HomeStyle>
    </div>
  );
}
