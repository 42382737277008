import { api } from "../../../../utils/helper/api";
import {
  AddressingUI,
  AddressList,
  CountriesUI,
  PaymentUI,
  UserAddress,
  UserDisability,
  UserDisabilityUI,
  UserEducationUI,
  UserEmploymentUI,
  UserFamilyUI,
  UserFileUI,
  UserInfoUI,
  UserInfoUpdate,
  UserInfoUpdateBasic,
  UserLanguageUI,
  UserSat,
} from "../entity/admit.model";
import { constants } from "../../../../utils/constant/constants";

const userInfoUpdateBasic = (
  userInfoForm: UserInfoUpdateBasic
): Promise<any> => {
  return api.post("/user/info/update/basic", userInfoForm);
};

const userInfoUpdateInfo = (userInfoForm: UserInfoUpdate): Promise<any> => {
  return api.post("/user/info/update/basic", userInfoForm);
};

const getUserInfo = (id: string | undefined): Promise<any> => {
  return api.get("/admission/" + id + "/my/detail");
};

const getAddress = (): Promise<AddressList> => {
  return api.get("/addressing/0/index");
};

const getPayment = (id: string | undefined): Promise<PaymentUI> => {
  return api.get(
    "/admission/" +
      id +
      "/payment/" +
      constants.TYPE_ADMISSION_REGISTER +
      "/get"
  );
};

const getLanguage = (): Promise<any> => {
  return api.get("/_open/language");
};

const getPaymentCredit = (id: string | undefined): Promise<PaymentUI> => {
  return api.get(
    "/admission/" + id + "/payment/" + constants.TYPE_ADMISSION_CREDIT + "/get"
  );
};

const createInvoiceQpay = (id: string): Promise<any> => {
  return api.get("/payment/" + id + "/invoice/qpay/create");
};

const getFormInfo = (id: string): Promise<any> => {
  return api.get("");
};

const getCountries = (): Promise<CountriesUI> => {
  return api.get("/_open/country");
};

const getUserInfoDetail = (): Promise<UserInfoUpdate> => {
  return api.get("/user/info");
};

const updateUserInfoDetail = (data: UserInfoUI): Promise<any> => {
  return api.post("/user/info/update/all", { user_info: data });
};

const checkUserInfoStudentCode = (code: string): Promise<any> => {
  return api.post("/user/info/check/student", { student_code: code });
};

const checkAdmissionDiscountCode = (admissionId: string, code: string): Promise<any> => {
  return api.post(`/admission/${admissionId}/discount/code/check`, { code: code });
};

const getUserAddress = (): Promise<any> => {
  return api.get("/user/addressing");
};

const updateUserAddress = (data: any, id: string | undefined): Promise<any> => {
  return api.post("/user/addressing/" + id + "/edit", {
    user_addressing: data,
  });
};

const createUserAddress = (data: any): Promise<any> => {
  return api.post("/user/addressing/new", { user_addressing: data });
};

const getUserDisability = (): Promise<UserDisabilityUI> => {
  return api.get("/user/disability/detail");
};

const updateUserDisability = (data: UserDisability): Promise<any> => {
  let userData: UserDisabilityUI = {
    user_disability: {
      ...data,
    },
  };
  return api.post("/user/disability/update", userData);
};

const getAdmissionConfig = (id: string | undefined): Promise<any> => {
  return api.get("/admission/" + id + "/detail");
};

const getAddressing = (id: string | undefined): Promise<AddressingUI> => {
  return api.get("/addressing/" + id + "/index");
};

const getUserEducation = (): Promise<any> => {
  return api.get("/user/education");
};

const createUserEducation = (data: UserEducationUI): Promise<any> => {
  return api.post("/user/education/new", { data });
};

const updateUserEducation = (user_education: UserEducationUI): Promise<any> => {
  return api.post("/user/education/edit", { user_education });
};

const getUserFamilyInfo = (): Promise<any> => {
  return api.get("/user/family/info");
};

const updateUserFamilyInfo = (
  user_family_info: UserFamilyUI[]
): Promise<any> => {
  return api.post("/user/family/info/edit", { user_family_info });
};

const getUserFile = (): Promise<any> => {
  return api.get("/user/file");
};

const updateUserFile = async (user_file: UserFileUI[]): Promise<any> => {
  return await api.post("/user/file/edit", { user_file: user_file });
};

const getUserSat = (): Promise<any> => {
  return api.get("/user/sat");
};

const createUserSat = async (userSat: UserSat): Promise<any> => {
  return await api.post("/user/sat/new", { ...userSat });
};

const updateUserSat = async (
  userSat: UserSat,
  id: string | undefined
): Promise<any> => {
  return await api.post(`/user/sat/${id}/edit`, { ...userSat });
};

const deleteUserSat = async (id: string | undefined): Promise<any> => {
  return await api.get(`/user/sat/${id}/delete`);
};

const getUserBranchSat = (): Promise<any> => {
  return api.get("/user/sat-branch");
};

const createUserBranchSat = async (userSat: UserSat): Promise<any> => {
  return await api.post("/user/sat-branch/new", { ...userSat });
};

const updateUserBranchSat = async (
    userSat: UserSat,
    id: string | undefined
): Promise<any> => {
  return await api.post(`/user/sat-branch/${id}/edit`, { ...userSat });
};

const deleteUserBranchSat = async (id: string | undefined): Promise<any> => {
  return await api.get(`/user/sat-branch/${id}/delete`);
};

const createAdmissionUser = async (
  admissionId: string | undefined
): Promise<any> => {
  return await api.get(`/admission/${admissionId}/user/create`);
};

const getAdmissionUser = (admissionId: string | undefined): Promise<any> => {
  return api.get(`/admission/${admissionId}/user/info`);
};

const confirmAdmissionUser = (
  rankingId: string | undefined,
  admissionRegisterId: string | undefined
): Promise<any> => {
  return api.get(
    `/ranking/${rankingId}/confirm/admission/register/${admissionRegisterId}`
  );
};

const updateAdmissionUserInfoChannel = (
  id: string | undefined,
  infoId: string | undefined
): Promise<any> => {
  return api.get(`/admission/${id}/info-channel/${infoId}}`);
};

const getUserLanguage = (): Promise<any> => {
  return api.get(`/user/language`);
};

const updateUserLanguage = (languages: UserLanguageUI[]): Promise<any> => {
  return api.post(`/user/language/edit/all`, { ...languages });
};

const getUserEmployment = (): Promise<any> => {
  return api.get(`/user/employment`);
};

const updateUserEmployment = (
  userEmployment: UserEmploymentUI[]
): Promise<any> => {
  return api.post(`/user/employment/edit/all`, { ...userEmployment });
};

const getUserProfession = (id: string | undefined): Promise<any> => {
  return api.get(`/admission/${id}/user/profession`);
};

const getUserProfessionList = (id: string | undefined): Promise<any> => {
  return api.get(`/admission/${id}/profession/list`);
};

const updateUserProfessionList = (
  id: string | undefined,
  admissionRegisterId: string | undefined
): Promise<any> => {
  return api.get(`/admission/${id}/profession/${admissionRegisterId}/select`);
};

const updateUserProfessionFinalList = (
    id: string | undefined,
    admissionRegisterId: string | undefined
): Promise<any> => {
  return api.get(`/admission/${id}/profession/${admissionRegisterId}/select/final`);
};

const getAdmissionGuide = (admitId: string): Promise<any> => {
  return api.get("/admission-guide/" + admitId);
};

const getAdmissionCreditGuide = (admitId: string): Promise<any> => {
  return api.get("/admission-credit-guide/" + admitId);
};

const changeAdmissionUserStep = (admissionUserId: string, admissionStep: string, admissionType: string): Promise<any> => {
  return api.get(`admission/${admissionUserId}/user/${admissionType}/step/${admissionStep}`);
};

const sendAdmissionUserCertificate = (id: string | undefined): Promise<any> => {
  return api.get(`admission/${id}/user/send/mail/certificate`);
};

export const admitService = {
  changeAdmissionUserStep,
  getUserProfession,
  getUserProfessionList,
  updateUserProfessionList,
  getUserLanguage,
  updateUserLanguage,
  getUserEmployment,
  updateUserEmployment,
  getLanguage,
  createAdmissionUser,
  updateAdmissionUserInfoChannel,
  getAdmissionUser,
  confirmAdmissionUser,
  getUserSat,
  createUserSat,
  updateUserSat,
  deleteUserSat,
  getUserBranchSat,
  createUserBranchSat,
  updateUserBranchSat,
  deleteUserBranchSat,
  getUserFile,
  updateUserFile,
  getUserFamilyInfo,
  updateUserFamilyInfo,
  getUserEducation,
  createUserEducation,
  updateUserEducation,
  getUserAddress,
  updateUserAddress,
  createUserAddress,
  userInfoUpdateBasic,
  getAddress,
  getPayment,
  createInvoiceQpay,
  getUserInfo,
  getCountries,
  getFormInfo,
  getUserInfoDetail,
  getUserDisability,
  userInfoUpdateInfo,
  updateUserDisability,
  getAdmissionConfig,
  getAddressing,
  getPaymentCredit,
  updateUserInfoDetail,
  getAdmissionGuide,
  getAdmissionCreditGuide,
  checkUserInfoStudentCode,
  checkAdmissionDiscountCode,
  sendAdmissionUserCertificate,
  updateUserProfessionFinalList
};
