import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Row, Col } from "antd";

import { homeService } from "../service/home.service";
import TimesTable from "../components/TimesTable/TimesTable";
import CustomLoader from "../../../public/components/CustomLoader";
import FloatButton from "../../../public/components/FloatButton";

export default function UserScore() {
  const [timingDetailsList, setTimingDetailsList] = useState<any>([]);

  const [loading, setLoading] = useState(true);

  let dataJson = new Array();

  useEffect(() => {
    homeService.getRankingKindTiming(1).then((res) => {
      res.rankingKinds.map((data: any) => {
        let dataTemp = new Array();

        if (data.activeTiming) {
          let currentTime = new Date(data.activeTiming.timeStart.date);
          console.log(currentTime);
          let index = 0;
          let currentCount = data.activeTiming.intervalCount;
          while (currentTime < new Date(data.activeTiming.timeEnd.date)) {
            dataTemp.push({
              name:
                currentCount -
                data.activeTiming.intervalCount +
                1 +
                "-" +
                currentCount,
              time: currentTime.toLocaleString(),
            });

            currentTime.setMinutes(currentTime.getMinutes() + data.activeTiming.intervalDuration);
            currentCount = currentCount + data.activeTiming.intervalCount;
            index++;
          }
          dataJson.push([data.name, dataTemp]);
        }
      });

      setTimingDetailsList(dataJson);

      setLoading(false);
    });
  }, []);
  return (
    <div className="container">
      <>
        {/*<h1 className="my-4">Мэргэжил, хөтөлбөр сонгох цагийн хуваарь</h1>*/}
        {loading ? (
          <CustomLoader />
        ) : (
          timingDetailsList && (
            <div>
              <Row gutter={30}>
                {timingDetailsList.map((data: any) => (
                  <Col md={24} lg={12}>
                    <h5 className="mb-1 card-title"> {data[0] && data[0]}</h5>
                    {data[1] && <TimesTable data={data[1]} />}
                  </Col>
                ))}
              </Row>
            </div>
          )
        )}
      </>
      <FloatButton />
    </div>
  );
}
