import { BsFillArrowRightCircleFill } from "react-icons/bs";

import { CardButtonStyle } from "./style";

const CustomButton = () => (
  <CardButtonStyle>
    <a className="fw-bold">
      <BsFillArrowRightCircleFill
        style={{
          color: "#8F90A6",
          fontSize: "25px",
          marginLeft: "-2px",
          marginBottom: "-23px",
        }}
      />
      <p style={{ marginLeft: "25px", color: '#555770' }}>Дэлгэрэнгүй</p>
    </a>
  </CardButtonStyle>
);

export default CustomButton;
