import styled from "styled-components";

export const FooterStyle = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    color:#FFFFFF;
  }
  .m{
    margin-top:30px;
  }

  .footer-content{
    display: flex;
    flex-direction: column;
  }

  li{
    margin-bottom: 12px;
  }

  a:hover{
    color: #0aa7ef;
  }
  .footer-content h3{
    font-size: 2.1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 3rem;
  }
  .footer-content p{
    max-width: 500px;
    line-height: 28px;
    font-size: 14px;
    color: #cacdd2;
  }
  .footer-p{
    text-align:left;
  }

  .icon-body{
    color: #FFFFFF;
    margin-right : 10px;
    width: 36px;
    height: 36px;
    background-color: rgba(228, 228, 235, 0.5);
    border-radius: 6px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon{
    opacity: 1;
    background-color: #FFFFFF;
  }

  .copyright{
    border-top: 1px solid #C7C9D9;
    margin-top: 20px;
    padding-top: 10px;
  }
`;