import { Button, Steps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import success from "../../../assets/images/success.png";
import { RiMenuFill, RiUserFill } from "react-icons/ri";
import { StarFilled } from "@ant-design/icons";
import Circle from "../information/components/circle/Circle";

const steps = [
  {
    key: 1,
    title: " ",
    content: (
      <div>
        <div className="w-full pt-2 pb-2 text-center">
          {/* <img
            src={success}
            alt="/src/assets/images/success.png"
            style={{ width: "200px", height: "200px" }}
            className="mb-3 "
          /> */}
        </div>
        <div className="text-center">Алхам 1</div>
        <div className="mb-3 ">
          Та өөрийн мэйл хаяг дээр дарж хувийн мэдээлэл цэс рүү{" "}
          <RiUserFill className="inline-img" /> орсноор та мэргэжил, хөтөлбөр
          баталгаажуулах боломжтой болно.
        </div>
      </div>
    ),
  },
  {
    key: 2,

    title: " ",
    content: (
      <div>
        <div className="w-full pt-2 pb-2 text-center">
          {/* <img
            src={success}
            alt="/src/assets/images/success.png"
            style={{ width: "200px", height: "200px" }}
            className="mb-3 "
          /> */}
        </div>
        <div className="text-center">Алхам 2</div>
        <div className="mb-1">
          Онооны жагсаалт харахыг сонгон өөрийн Онооны жагсаалт, мэргэжил сонгох
          цагийн хуваарийг Бүлэг 1 (Математик, Англи хэл), Бүлэг 2 (Математик,
          Нийгмийн тухай мэдлэг), Бүлэг 3 (Математик, Англи хэл+ярилцлага) гэсэн
          гурван хэлбэрээр бүртгүүлэгч эрэмбэ, мэргэжил, хөтөлбөр баталгаажуулах
          цагийн хуваарь, төлбөр, элсэгчийн төлөв зэрэг мэдээлэлтэй танилцана.
        </div>
        <div className="mb-3">
          ЖИЧ: Төлөв болон Мэргэжил баталгаажуулах хугацаан дээр цагийн мэдээлэл
          байхгүй тохиолдолд таны мэдээлэл од{" "}
          <StarFilled className="inline-img" style={{ color: "#ffcc01" }} />{" "}
          тэмдэглэгдсэн байна. Энэ тохиолдолд та төлөвийн тайлбар мэдээллийг
          анхааралтай уншина уу
        </div>
      </div>
    ),
  },
  {
    key: 3,

    title: " ",
    content: (
      <div>
        <div className="w-full pt-2 pb-2 text-center">
          {/* <img
            src={success}
            alt="/src/assets/images/success.png"
            style={{ width: "200px", height: "200px" }}
            className="mb-3 "
          /> */}
        </div>
        <div className="text-center">Алхам 3</div>
        <div className="mb-3">
          Та тэнцсэн төлөвт{" "}
          <span
            style={{ display: "inline-block" }}
            className="pl-1 pr-1 inline-img"
          >
            <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: "#0132a6",
                borderRadius: "50%",
              }}
            ></div>
          </span>{" "}
          шилжиж, мэргэжил баталгаажуулах хугацаа нээгдсэнээр төлбөр төлөх товч
          идэвхжин төлбөрөө төлөх боломжтой болно. Төлбөр төлөхдөө зааврыг
          анхааралтай уншиж танилцана уу.
        </div>
      </div>
    ),
  },
  {
    key: 4,

    title: " ",
    content: (
      <div>
        <div className="w-full pt-2 pb-2 text-center">
          {/* <img
            src={success}
            alt="/src/assets/images/success.png"
            style={{ width: "200px", height: "200px" }}
            className="mb-3 "
          /> */}
        </div>
        <div className="text-center">Алхам 4</div>

        <div className="mb-3">
          Төлбөр төлөх зааврын дагуу:
          <ul>
            <li>
              Qpay-ээр төлсөн тохиолдолд төлбөр шалгах гэсэн товчийг дарж онооны
              жагсаалт руу орж мэргэжил сонгох боломжтой болно.
            </li>
            <li>
              Дансаар төлсөн тохиолдолд буцах товч дарж онооны жагсаалт руу орж
              мэргэжил сонгох боломжтой болно.
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    key: 5,

    title: " ",
    content: (
      <div>
        <div className="w-full pt-2 pb-2 text-center">
          {/* <img
            src={success}
            alt="/src/assets/images/success.png"
            style={{ width: "200px", height: "200px" }}
            className="mb-3 "
          /> */}
        </div>
        <div className="text-center">Алхам 5</div>

        <div className="mb-3">
          Төлбөрийн мэдээлэл <span className="text-success">ТӨЛСӨН</span> гэсэн
          төлөвтэй болсны дараагаар мэргэжил сонгох хэсэгт мэргэжил болон
          хөтөлбөрүүдийн жагсаалт гарч ирсэн байх ба та мэргэжилээ сонгох гэсэн
          товч дээр дарснаар мэргэжил баталгаажих товч идэвхжинэ. Мэргэжлээ
          баталгаажуулахдаа мэргэжил, хөтөлбөр сонголтын зааварыг анхааралтай
          уншиж танилцан баталгаажуулна уу, мэргэжил батлах товчыг дарсан
          тохиолдолд солих боломжгүйг анхаарна уу.
        </div>
      </div>
    ),
  },
  {
    key: 6,

    title: " ",
    content: (
      <div>
        <div className="w-full pt-2 pb-2 text-center">
          {/* <img
            src={success}
            alt="/src/assets/images/success.png"
            style={{ width: "200px", height: "200px" }}
            className="mb-3 "
          /> */}
        </div>
        <div className="text-center">Алхам 6</div>

        <div className="mb-3">
          Мэргэжилээ сонгосон бол мэргэжил баталгаажуулах товчыг дарснаар та
          СЭЗИС-д элссэн талаар мэдээлэл гарч ирнэ.
        </div>
      </div>
    ),
  },
];

export default function Instruction() {
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <div>
      <div className="text-center">Сайн байна уу?</div>
      <Steps size="small" current={current}>
        {steps.map((item) => (
          <Step key={item.key} title={item.title} />
        ))}
      </Steps>
      <div className="mt-2 ">{steps[current].content}</div>
      <div className="steps-action d-flex justify-content-between">
        <div>
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Өмнөх
            </Button>
          )}
        </div>
        <div>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Дараах
            </Button>
          )}
        </div>
        {/* {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => message.success("Processing complete!")}
                  >
                    Done
                  </Button>
                )} */}
      </div>
    </div>
  );
}
