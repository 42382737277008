import styled from "styled-components";

export const UserConfirmStyle = styled.div`
  .step-title{
    font-weight: 600;
    font-size: 14px;
    color: #0132A6;
  }

  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #FFFFFF;
  }

  .ant-steps-item-finish .ant-steps-item-content>.ant-steps-item-title {
    color: #05A660;
  }
  
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #05A660;
    color: #FFFFFF;
  }

  .ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
    min-height: 0;
  }
  .ant-steps-item-container{
    display: flex;
    align-items: center;
    justify-content: start;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  
  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #0132A6;
    color: #0132A6;
  }
  
  .ant-steps-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    border: 0px solid rgba(0,0,0,.25);
    border-radius: 32px;
    transition: background-color .3s,border-color .3s;
    background-color: #CCD6ED;
    color: #8F90A6;
  }
  
  .ant-steps-item-title{
    font-weight: 600;
    font-size: 12px;
  }

  .ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
    display: none;
  }
  
  .steps-background{
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 16px;
    background: #FAFAFC;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    height: fit-content;
  }
`;
