import React, {useEffect, useState} from "react";
import {RankingKindUI} from "../../../public/information/entity/information.model";
import {UserScoreTableUI} from "../../../public/information/components/UserScoreTable/entity/userScoreTable.model";
import {informationService} from "../../../public/information/service/information.service";
import UserScoreTable from "../../../public/information/components/UserScoreTable/UserScoreTable";
import {Button, Tooltip} from "antd";
import UserScoreRankingKind from "../components/UserScoreRankingKind";
import {useNavigate} from "react-router-dom";
import Circle from "../../../public/information/components/circle/Circle";
import storeService from "../../../../utils/helper/store";
import moment from "moment";
import {admitService} from "../../admit/service/admit.service";
import {Table} from "antd";
import CustomLoader from "../../../public/components/CustomLoader";
import {StarFilled} from "@ant-design/icons";
import FloatButton from "../../../public/components/FloatButton";

export default function UserScorePrivate() {
    const [rankingKind, setRankingKind] = useState<RankingKindUI[]>();
    const [tableData, setTableData] = useState<UserScoreTableUI[]>([]);
    const [selected, setSelected] = useState<string>("");
    const [selectedName, setSelectedName] = useState<string>("");

    const [count, setCount] = useState<number>(0);
    const [capacity, setCapacity] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [subLoading, setSubLoading] = useState(true);
    const navigate = useNavigate();
    const [ranking, setRanking] = useState<any>();
    const [rankingId, setRankingId] = useState<any>();
    const [admissionUser, setAdmissionUser] = useState<any>();
    const [userRanking, setUserRanking] = useState<any>();
    const [userInfoTable, setUserInfoTable] = useState<any>();
    const [selectedRankingKindId, setSelectedRankingKindId] = useState<any>();
    const [selectedProfession, setSelectedProfession] = useState<any>({
        profession: '',
        program: ''
    })
    const [selectedDegreeProfession, setSelectedDegreeProfession] = useState<any>({
        profession: '',
        program: ''
    })

    useEffect(() => {
        console.log(userInfoTable, 'rankingId')
        if (userInfoTable?.status === 'accepted') {
            setSelectedProfession(selectedDegreeProfession)
        } else {
            setSelectedProfession({
                profession: '',
                program: ''
            })
        }
    }, [userInfoTable])

    let userEmail = storeService.getAuth()?.email;
    const dataSource = [
        {
            key: "1",
            color: "#00a65f",
            status: "Элссэн",
            text: "Мэргэжил сонгосон.",
        },
        {
            key: "2",
            color: "#0132a6",
            status: "Тэнцсэн",
            text: "Хяналтын тоонд багтсан, мэргэжил баталгаажуулаагүй. Тэнцсэн төлөвтэй бүртгүүлэгч мэргэжил хэлбэрээ сонгож элссэн төлөвт шилжинэ.",
        },
        {
            key: "3",
            color: "#ffcc01",
            status: "Хүлээгдэж байгаа ",
            text: "СЭЗИС-ийн ЭЕШ-ын босго оноо хангасан ч хяналтын тоонд багтаагүй бүртгүүлэгч. Бүртгүүлэгч нь цуцалсан бүртгүүлэгчийн тоогоор жагсаалт ахиж тэнцсэн төлөв рүү шилжих боломжтой.",
        },
        {
            key: "4",
            color: "#8a8a8b",
            status: "Тэнцээгүй",

            text: "СЭЗИС-ийн ЭЕШ-ын босго оноо хангаагүй.",
        },
        {
            key: "5",
            color: "#ff0000",
            status: "Цуцалсан",
            text: "Өөрийн хүсэлтээр мэргэжил сонголтоо цуцалсан болон өөр бүлгийн мэргэжил, хөтөлбөр сонгосон бүртгүүлэгч.",
        },
        {
            key: "star",
            color: "#ffcc01",
            status: "Мэргэжил баталгаажуулах хугацаа",
            text: "Тухайн өдрийн цагийн интервалд багтаагүй.",
        },
    ];

    const columns = [
        {
            color: "",
            dataIndex: "color",
            key: "color",
            render: (color: any, record: any) => {
                return (
                    <>
                        {record.key == "star" ? (
                            <StarFilled style={{color: color}}/>
                        ) : (
                            <Circle size={"12px"} color={color}/>
                        )}
                    </>
                );
            },
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Тайлбар",
            dataIndex: "text",
            key: "text",
        },
    ];

    useEffect(() => {
        informationService.getRankingKind().then((res) => {
            setRankingKind(res.ranking);
            let value = res.ranking[0];
            let capacityCount = 1;
            let indexCount = 0;

            informationService.getUserRankingInfo().then((res) => {
                if (res.degreeName && res.professionName) {
                    setSelectedDegreeProfession({
                        profession: res.degreeName,
                        program: res.professionName
                    })
                }
                setAdmissionUser(res.admissionUser);
                setUserRanking(res.rankingGroup);
                setUserInfoTable([]);
                setRankingId(value.id);
                informationService.getRankingList(value.id).then((res) => {
                    let tableValue = res.ranking.map((element: any, index: number) => {
                        if (capacityCount <= value.capacity) {
                            indexCount++;
                            if (element.state != "canceled" && element.state != "declined") {
                                capacityCount++;
                            }
                        }

                        let timeConfirmState = false;
                        if (element.timeConfirm) {
                            if (new Date() > new Date(element.timeConfirm?.date)) {
                                timeConfirmState = true;
                            }
                        }

                        if (userEmail === element.admissionUser.user.email) {
                            setRanking(element);
                            setUserInfoTable({
                                index: index + 1,
                                id: element.id,
                                averageScore: element.scoreSat,
                                skillScore: element.scoreSkill,
                                status: element.state,
                                satCode: element.admissionUser.satCode,
                                email: element.admissionUser.user.email,
                                admissionId: element.rankingKind.admission.id,
                                rankingId: value,
                                timeConfirm: element.timeConfirm?.date,
                                timeConfirmState: timeConfirmState,
                            });
                        }
                        return {
                            id: element.id,
                            index: index + 1,
                            averageScore: element.scoreSat,
                            skillScore: element.scoreSkill,
                            status: element.state,
                            satCode: element.admissionUser.satCode,
                            email: element.admissionUser.user.email,
                            admissionId: element.rankingKind.admission.id,
                            timeConfirm: element.timeConfirm?.date,
                            timeConfirmState: timeConfirmState,
                        };
                    });
                    // let count = 0;
                    // res.admissionRegister.forEach((val: any) => {
                    //   count += val.capacity;
                    // });
                    tableValue.splice(indexCount, 0, {index: -1});
                    setCount(indexCount);
                    setCapacity(value.capacity);
                    setTableData(tableValue);
                    setSelected(value.description);
                    setSelectedName(value.name);
                    setLoading(false);
                    setSubLoading(false);
                });
            });
        });
    }, []);

    const onClickPayment = () => {
        let formLink = "/user/admit/" + admissionUser.admission.id.toString() + "/info"
        admitService
            .getPaymentCredit(admissionUser.admission.id.toString())
            .then((response) => {
                if (response.payment?.state !== "PAID") {
                    navigate("/user/payment/" + response.payment.id + "/qpay", {
                        state: {
                            satCode: 0,
                            admitId: admissionUser.admission.id,
                        },
                    });
                    // navigate(formLink, { state: {
                    //     title: admissionUser.admission.degree?.description,
                    //     clickButtonType: "confirm",
                    //   }})
                } else {
                    navigate("/user/home");
                }
            });
    };


    return (
        <div className="container">
            {loading ? (
                <CustomLoader/>
            ) : (
                <div className="row">
                    <div className="mb-2 col-lg-12">
                        <h3>Элсэгчийн мэдээлэл</h3>
                    </div>
                    <div className="mt-3 col-lg-5">
                        <h5>Овог: {admissionUser.user.userInfo.lastnameMn}</h5>
                        <h5>Нэр: {admissionUser.user.userInfo.firstnameMn}</h5>
                        <h5>БҮТ дугаар: {admissionUser.satCode}</h5>
                    </div>
                    <div className="mt-3 mb-5 col-lg-3">
                        <div className="d-flex">
                            <h5>Төлбөр: </h5>
                            <div className="ml-3 d-flex align-items-center">
                                {admissionUser.state === "C" ? (
                                    <div style={{color: "#05a660", fontWeight: "bold"}}>
                                        Төлсөн
                                    </div>
                                ) : (
                                    <div>Төлөөгүй</div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <h5>Төлөв: </h5>
                            <>
                                {userInfoTable?.index > count ? (
                                    <>
                                        {userInfoTable?.status === "pending" ? (
                                            ranking &&
                                            userInfoTable?.averageScore >=
                                            ranking.rankingKind.minScore ? (
                                                <div className="ml-3 d-flex align-items-center">
                                                    <Circle size={"12px"} color={"#ffcc01"}/>
                                                    Хүлээгдэж байгаа
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <Circle size={"12px"} color={"#8a8a8b"}/>
                                                    Тэнцээгүй
                                                </div>
                                            )
                                        ) : (
                                            <div className="ml-3 d-flex align-items-center">
                                                <Circle size={"12px"} color={"#8a8a8b"}/>
                                                Тэнцээгүй
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {userInfoTable?.status === "pending" && (
                                            <div className="ml-3 d-flex align-items-center">
                                                <Circle size={"12px"} color={"#0132a6"}/>
                                                Тэнцсэн
                                            </div>
                                        )}
                                        {userInfoTable?.status === "accepted" && (
                                            <div className="ml-3 d-flex align-items-center">
                                                <Circle size={"12px"} color={"#00a65f"}/>
                                                Элссэн
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        </div>
                        <div className="d-flex align-items-center">
                            <h5>Мэргэжил: </h5>
                        </div>
                        <div className="d-flex align-items-center">
                            {selectedProfession.profession}
                        </div>
                        <div className="d-flex align-items-center">
                            <h5>Хөтөлбөр: </h5>
                        </div>
                        <div className="d-flex align-items-center">
                            {selectedProfession.program}
                        </div>
                        {userRanking?.map((element: any) =>
                            rankingId === element.rankingKind.id && count >= userInfoTable?.index ? (
                                admissionUser.state === "C" ?
                                    <>
                                        {selectedName === element.rankingKind.name && userInfoTable?.status !== "accepted" ? (
                                            <>
                                                {ranking.timeConfirm &&
                                                    new Date(ranking.timeConfirm.date) <=
                                                    new Date() && (
                                                        <>
                                                            <Button
                                                                type="primary"
                                                                className="mb-4 submit-btn w-80"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/user/home/${rankingId}/profession/select`,
                                                                        {
                                                                            state: {
                                                                                admitId: userInfoTable.admissionId,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                Мэргэжил сонгох
                                                            </Button>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ) : <></>}
                                    </>
                                    : (
                                        <Button
                                            type="primary"
                                            className={`  my-3 
                         submit-btn
                      `}
                                            onClick={onClickPayment}
                                            style={{width: "fit-content"}}
                                            disabled={false}
                                        >
                                            Төлбөр төлөх
                                        </Button>
                                    )
                            ) : null
                        )}
                    </div>
                    <div className="mt-3 mb-5 col-lg-4">
                        <h5>Элсэгчийн эрэмбэ/ Хяналтын тоо </h5>
                        {userRanking?.map((element: any) => (
                            <h5>
                                {element.rankingKind.name} - {element.count} /{" "}
                                {element.rankingKind.capacity}
                            </h5>
                        ))}
                    </div>
                    {subLoading ? (
                        <CustomLoader/>
                    ) : (
                        <>
                            <div className="col-lg-4">
                                <h3 className="font-weight-bold">Жагсаалт</h3>
                                <div className="mt-2">
                                    {rankingKind?.map((element) => {
                                        return (
                                            <>
                                                <UserScoreRankingKind
                                                    data={element}
                                                    setTableData={setTableData}
                                                    setCount={setCount}
                                                    setSelected={setSelected}
                                                    setUserInfoTable={setUserInfoTable}
                                                    setLoading={setSubLoading}
                                                    setRanking={setRanking}
                                                    setRankingId={setRankingId}
                                                    setCapacity={setCapacity}
                                                    selectedRankingKindId={selectedRankingKindId}
                                                    setSelectedRankingKindId={selectedRankingKindId}
                                                    setSelectedName={setSelectedName}
                                                    selected={selected}
                                                    selectedName={selectedName}
                                                    userRanking={userRanking}
                                                />
                                                {/*{selectedName === element.name ? (*/}
                                                {/*    <>*/}
                                                {/*        {count >= userInfoTable?.index &&*/}
                                                {/*        admissionUser.state !== "P" ? (*/}
                                                {/*            <>*/}
                                                {/*                {ranking.timeConfirm &&*/}
                                                {/*                new Date(ranking.timeConfirm.date) <=*/}
                                                {/*                new Date() ? (*/}
                                                {/*                    <>*/}
                                                {/*                        <Button*/}
                                                {/*                            type="primary"*/}
                                                {/*                            className="mb-4 submit-btn w-80"*/}
                                                {/*                            onClick={() =>*/}
                                                {/*                                navigate(*/}
                                                {/*                                    `/user/home/${rankingId}/profession/select`,*/}
                                                {/*                                    {*/}
                                                {/*                                        state: {*/}
                                                {/*                                            admitId: userInfoTable.admissionId,*/}
                                                {/*                                        },*/}
                                                {/*                                    }*/}
                                                {/*                                )*/}
                                                {/*                            }*/}
                                                {/*                        >*/}
                                                {/*                            Мэргэжил сонгох*/}
                                                {/*                        </Button>*/}
                                                {/*                    </>*/}
                                                {/*                ) : (*/}
                                                {/*                    <>*/}
                                                {/*                        <Tooltip*/}
                                                {/*                            title="Та төлбөрөө төлсний дараа мэргэжил сонгох боломжтой болно">*/}
                                                {/*                            <Button*/}
                                                {/*                                type="primary"*/}
                                                {/*                                disabled={true}*/}
                                                {/*                                className="mb-4 back-btn"*/}
                                                {/*                                style={{width: "fit-content"}}*/}
                                                {/*                                onClick={() =>*/}
                                                {/*                                    navigate(*/}
                                                {/*                                        `/user/home/${userInfoTable.rankingId.id}/profession/select`*/}
                                                {/*                                    )*/}
                                                {/*                                }*/}
                                                {/*                            >*/}
                                                {/*                                Мэргэжил сонгох*/}
                                                {/*                            </Button>*/}
                                                {/*                        </Tooltip>*/}
                                                {/*                    </>*/}
                                                {/*                )}*/}
                                                {/*            </>*/}
                                                {/*        ) : (*/}
                                                {/*            <Tooltip*/}
                                                {/*                title="Та төлбөрөө төлсний дараа мэргэжил сонгох боломжтой болно">*/}
                                                {/*                <Button*/}
                                                {/*                    type="primary"*/}
                                                {/*                    disabled={true}*/}
                                                {/*                    className="mb-4 back-btn"*/}
                                                {/*                    style={{width: "fit-content"}}*/}
                                                {/*                    onClick={() =>*/}
                                                {/*                        navigate(*/}
                                                {/*                            `/user/home/${userInfoTable.rankingId.id}/profession/select`*/}
                                                {/*                        )*/}
                                                {/*                    }*/}
                                                {/*                >*/}
                                                {/*                    Мэргэжил сонгох*/}
                                                {/*                </Button>*/}
                                                {/*            </Tooltip>*/}
                                                {/*        )}*/}
                                                {/*    </>*/}
                                                {/*) : (*/}
                                                {/*    <></>*/}
                                                {/*)}*/}
                                            </>
                                        )
                                    })}
                                </div>
                                <div className="mt-5">
                                    <Table
                                        tableLayout={'auto'}
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <>
                                    <h3>{selectedName}</h3>
                                    <h5>{selected}</h5>
                                    {ranking ? (
                                        <UserScoreTable
                                            data={tableData}
                                            count={count}
                                            name={selected}
                                            setTableData={setTableData}
                                            userInfoTable={userInfoTable}
                                            setUserInfoTable={setUserInfoTable}
                                            capacity={capacity}
                                            minScore={ranking.rankingKind.minScore}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            </div>
                        </>
                    )}
                </div>
            )}
            <FloatButton/>
        </div>
    );
}
