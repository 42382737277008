import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { headerService } from "../../../private/components/PrivateHeader/service/header.service";
import { DegreeGroupUI } from "../../../private/components/PrivateHeader/entity/header.model";
import Widget from "../../information/components/widget";
import { Pagination } from "antd";
import { TrainingCard } from "../../components/TrainingCard";
import { Collapse, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import {
  RiArrowDownSLine,
  RiArrowRightLine,
  RiSubtractLine,
} from "react-icons/ri";
import { MainStyle } from "./MainStyle";
import { informationService } from "../../information/service/information.service";
import {
  AdmissionUI,
  Information,
  InformationUI,
} from "../../information/entity/information.model";
import YouTube, { YouTubeProps } from "react-youtube";
import RegistrationText from "../../information/components/RegistrationText";
import InstructionAccordion from "../../information/components/instructionAccordion/InstructionAccordion";
import QAAccordion from "../../information/components/QAAccordion";
import { Modal, Form, Input, Select } from "antd";
import { MoreButtonStyle } from "../../information/components/more-button/style";
import { MdTouchApp } from "react-icons/md";

const { Panel } = Collapse;
const { Option } = Select;
export const onPlayerReady: YouTubeProps["onReady"] = (event) => {
  event.target.pauseVideo();
};

export const opts: YouTubeProps["opts"] = {
  height: "390",
  width: "100%",
};

export default function Main() {
  const [trainings, setTrainings] = useState<any>();
  const [trainingsPagination, setTrainingsPagination] = useState<any>();
  const [activeKey, setActiveKey] = useState<any>("1");
  const [degreeGroup, setDegreeGroup] = useState<DegreeGroupUI>();
  const [radioValue, setRadioValue] = useState<any>(1);
  const [info, setInfo] = useState<Information[]>();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const panelId = params.get("panelId");
    if (panelId) {
      setActiveKey(panelId);
    } else {
      setActiveKey("1");
      navigate("?panelId=1", { replace: true });
    }
  }, [location, navigate]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        informationService.sendBrochureData(values).then((res) => {
          const link = document.createElement("a");
          link.href = "/E - Элсэгчдийн гарын авлага ЦАХИМ small size.pdf";
          link.download = "E - Элсэгчдийн гарын авлага ЦАХИМ small size.pdf";
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
        form.resetFields();
        setVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    headerService.getDegreeGroup().then((response) => {
      setDegreeGroup(response);
      headerService.getDegreeTraining().then((response) => {
        setTrainings(response.admissions);
        const result = response.admissions.reduce(
          (resultArray: any, item: any, index: any) => {
            const chunkIndex = Math.floor(index / 3);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
          },
          []
        );
        setTrainingsPagination(result[0]);
      });
    });
  }, []);

  const handlePagination = (page: any, pageSize: any) => {
    const result = trainings.reduce(
      (resultArray: any, item: any, index: any) => {
        const chunkIndex = Math.floor(index / pageSize);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      },
      []
    );
    setTrainingsPagination(result[page - 1]);
  };

  const onChange = (key: string | string[]) => {
    setActiveKey(key);
    navigate(`?panelId=${key}`);
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const [admissions, setAdmissions] = useState<AdmissionUI>();
  const [qa, setQa] = useState<any>();

  useEffect(() => {
    if (radioValue) {
      informationService.getAdmission(radioValue).then((response) => {
        setAdmissions(response);
        informationService.getDegreeGuide(radioValue.toString()).then((res) => {
          setInfo(
            res.map((element: InformationUI) => {
              return {
                name: element.name,
                content: (
                  <YouTube
                    videoId={element.description}
                    opts={opts}
                    onReady={onPlayerReady}
                  />
                ),
              };
            })
          );
          informationService
            .getDegreeInfoQA(radioValue.toString())
            .then((res) => {
              setQa(res);
            });
        });
      });
    }
  }, [radioValue]);

  return (
    <div className="container mb-5">
      <MainStyle>
        <h1 className="mt-3 mb-4 font-weight-bold">Элсэлт</h1>
        <div className={"row"}>
          <div className="col-lg-4 col-xs-12">
            <Collapse onChange={onChange} accordion activeKey={activeKey}>
              {degreeGroup?.degreeGroups.map((element) => {
                return (
                  <Panel
                    header={element.name}
                    key={element.id}
                    showArrow={false}
                    extra={
                      parseInt(activeKey) === element.id ? (
                        <RiSubtractLine size={24} />
                      ) : (
                        <RiArrowDownSLine size={24} />
                      )
                    }
                  >
                    {element.id !== 3 &&
                      element?.degrees.map((degree) => {
                        return (
                          <Radio.Group
                            onChange={onChangeRadio}
                            value={radioValue}
                            key={degree.id}
                          >
                            <Radio value={degree.id}>{degree.name}</Radio>
                          </Radio.Group>
                        );
                      })}
                  </Panel>
                );
              })}
            </Collapse>
          </div>
          <div className="col-lg-8 col-xs-12">
            <>
              {parseInt(activeKey) === 3 ? (
                <>
                  <div className="row">
                    {trainingsPagination &&
                      trainingsPagination.map((val: any) => (
                        <div className="col-md-4 mt-3" key={val.id}>
                          <TrainingCard training={val} />
                        </div>
                      ))}
                  </div>
                  {trainings ? (
                    <div className="mt-5 d-flex align-items-center justify-content-center">
                      <Pagination
                        defaultCurrent={1}
                        total={trainings.length}
                        onChange={handlePagination}
                        pageSize={3}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {admissions?.admissions.map((admission) => {
                    return !admission.isDeleted ? (
                      <RegistrationText {...admission} key={admission.code} />
                    ) : null;
                  })}
                </>
              )}
            </>
          </div>
          {parseInt(activeKey) !== 3 && info && info?.length > 0 && (
            <div className="col-12 mt-3">
              <h1 className="mt-3 mb-4 font-weight-bold">
                Элсэгчдэд өгөх зөвлөгөө
              </h1>
              <InstructionAccordion data={info} />
            </div>
          )}

          {parseInt(activeKey) !== 3 && radioValue && (
            <div className="col-12 mt-3">
              <Widget id={radioValue} />
            </div>
          )}
          {parseInt(activeKey) !== 3 && qa && qa.qa.length > 0 && (
            <div className="col-12 mt-3">
              <div className="container header-container">
                <div className="row d-flex align-items-center">
                  <div className="col-lg">
                    <h1 className="mt-3 mb-4 font-weight-bold">
                      Түгээмэл асуулт, хариулт
                    </h1>
                  </div>
                  <div className="col-lg">
                    <div className="more-button">
                      <MoreButtonStyle>
                        <>
                          <a
                            style={{
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: "regular",
                            }}
                            className="d-flex align-items-center justify-content-end"
                            href={"/information/qa/" + radioValue}
                          >
                            Бүгдийг харах
                            <RiArrowRightLine
                              style={{ color: "#345BB8", marginLeft: "8px" }}
                            />
                          </a>
                        </>
                      </MoreButtonStyle>
                    </div>
                  </div>
                </div>
              </div>
              <QAAccordion data={qa.qa.slice(0, 4)} />
            </div>
          )}
        </div>
        <button className="fixed-button" onClick={showModal}>
          <MdTouchApp size={24} />
          Элсэгчийн гарын авлага татах
        </button>
        <Modal
          title="Элсэгчийн гарын авлага татах"
          visible={visible}
          onOk={handleOk}
          okText="Татах"
          cancelText="Хаах"
          onCancel={handleCancel}
        >
          <Form form={form} layout="vertical" name="userForm">
            <Form.Item
              name="firstName"
              label="Нэр"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Овог"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="И-мэйл"
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Утас"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </MainStyle>
    </div>
  );
}
