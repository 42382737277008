import { useForm } from "antd/lib/form/Form";
import { Button, Form, message, Steps } from "antd";
import FormButtons from "../components/FormButtons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { admitService } from "../service/admit.service";
import { StepsUI, UserInfoUpdateBasic } from "../entity/admit.model";
import PreRegistrationInformation from "../components/PreRegistrationInformation";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { RiSaveFill } from "react-icons/ri";
import PersonalInformation from "../components/PersonalInformation";
import BachelorSatInformation from "../components/bachelor/BachelorSatInformation";
import CustomLoader from "../../../public/components/CustomLoader";
import InformationReceived from "../components/InformationReceived";
import Regulation from "../components/Regulation";

const { Step } = Steps;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #345bb8;
`;

const personalInformation = {
  title: "Бүртгүүлэгчийн товч мэдээлэл",
  content: <PersonalInformation />,
  form: "personalInformation",
};

const userSat = {
  title: "ЭЕШ-ын мэдээлэл",
  content: <BachelorSatInformation />,
  form: "userSat",
};




export default function PreRegistrationBachelor() {
  const [form] = useForm();
  const navigate = useNavigate();
  const { admitId } = useParams();
  let { state } = useLocation() as any;
  let title = state?.title as string;
  const [payed, setPayed] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState<StepsUI[]>([]);
  const [check, setCheck] = useState<boolean>();
  const [checkBox, setCheckBox] = useState<boolean>(true);
  const [name, setName] = useState("Бүртгүүлэх");


  const regulation = {
    title: "Журам",
    content: <Regulation setCheckBox={setCheckBox} satExam={true}/>,
    form: "regulation",
  };

  const informationReceived = {
    title: "Төлбөр-бүртгэлийн хураамж",
    content: <InformationReceived check={check}/>,
    form: "informationReceived",
  };

  if (title === "Цахим зөвлөх үйлчилгээ") {
    title = "Цахим зөвлөх үйлчилгээний";
  }

  const next = () => {
    setStep(step + 1);
  };

  const prev = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    admitService.getUserInfo(admitId).then((response) => {
      setSteps([]);
      setSteps((array) => [...array, regulation]);
      setSteps((array) => [...array, personalInformation]);
      setSteps((array) => [...array, userSat]);
      setSteps((array) => [...array, informationReceived]);


      if (response.admission?.name) {
        setName(response.admission?.name)
      }

      if (response.payment?.state === "PAID") {
        setPayed(true);
      }
      setLoading(false);
    });
  }, []);

  const onFinish = () => {
    admitService.getPayment(admitId).then((response) => {
      if (response.payment.state === "NOT_PAID") {
        navigate("/user/payment/" + response.payment.id + "/qpay", {
          state: {
            admitId: admitId,
          },
        });
      } else {
        navigate("/user/home");
      }
    });
  };

  return (
      <>
        {
          loading ? (
              <>
                <CustomLoader />
                {/* <ClipLoader color="#ffffff" loading={loading} size={30} css={override} speedMultiplier={1}/> */}
              </>
          ) : (
              <Form.Provider
                  onFormFinish={(name) => {
                    if (name === "personalInformation") {
                      message.success("Success");
                      next();
                    } else {
                      next();
                    }
                  }}
              >
                <>
                  <h3>{name}</h3>
                  <div className="mt-2 row">
                    <div className="col-md-3">
                      <Steps direction="vertical" current={step}>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                      </Steps>
                    </div>
                    <div className="col-md-9">
                      <>{steps[step].content}</>
                    </div>
                  </div>
                  <div className="steps-action">
                    <div className="row">
                      <div className="col-md-4" />
                      <div className="col-md-4 d-flex">
                        {step < steps.length - 1 && (
                            <Button
                                type="primary"
                                className="submit-btn"
                                htmlType="submit"
                                disabled={checkBox}
                                form={steps[step].form}
                            >
                              Дараах
                            </Button>
                        )}
                        {step === steps.length - 1 && (
                            <>
                              {payed ? (
                                  <>
                                    <Button
                                        type="primary"
                                        className="submit-btn"
                                        onClick={() => {
                                          onFinish();
                                        }}
                                    >
                                      <RiSaveFill size={24} className="mr-2" />
                                      Хадгалах
                                    </Button>
                                  </>
                              ) : (
                                  <div>
                                    <Button
                                        className="submit-btn"
                                        type="primary"
                                        onClick={() => {
                                          message.success("Processing complete!");
                                          onFinish();
                                        }}
                                    >
                                      <RiSaveFill size={24} className="mr-2" />
                                      Хураамж төлөх
                                    </Button>
                                  </div>
                              )}
                            </>
                        )}
                        {step > 0 && (
                            <Button
                                className="back-btn"
                                style={{ margin: "0 8px" }}
                                onClick={() => prev()}
                            >
                              Буцах
                            </Button>
                        )}
                      </div>

                      <div className="col-md-4" />
                    </div>
                  </div>
                  <FormButtons />
                </>
              </Form.Provider>
          )
          // <Form layout="vertical" name="pre-register-bachelor-form" onFinish={onFinish} form={form}>
          //     <h3>{title} бүртгэл</h3>
          //     <PreRegistrationInformation userInfo={userInfo}/>
          //     {payed ?
          //         <>
          //         </>
          //         :
          //         <div className="mt-5 row">
          //             <div className="col-md-3"/>
          //             <div className="col-md-3">
          //                 <Button className="submit-btn" htmlType="submit"><RiSaveFill size={24} className="mr-2"/>Хураамж төлөх</Button>
          //             </div>
          //             <div className="col-md-3">
          //                 <Button className="submit-btn" onClick={()=>{navigate("/user/home")}}>Буцах</Button>
          //             </div>
          //             <div className="col-md-3"/>
          //             <FormButtons/>
          //         </div>
          //     }
          // </Form>
        }
      </>
  );
}


