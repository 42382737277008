import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { informationService } from "../service/information.service";
import {
  RiMapPin2Line,
  RiArrowRightLine,
  RiCalendarTodoLine,
  RiCalendarCheckLine,
  RiTimerLine,
  RiGlobalLine,
  RiCoinLine,
  RiCloseFill,
} from "react-icons/ri";
import moment from "moment";
import YouTube, { YouTubeProps } from "react-youtube";
import { TrainingCard } from "../../components/TrainingCard";
import storeService from "../../../../utils/helper/store";
import { admitService } from "../../../private/admit/service/admit.service";
import { Helmet } from "react-helmet";

const opts: YouTubeProps["opts"] = {
  height: "255",
  width: "100%",
};

const imgBase = process.env.REACT_APP_FILE_HOST;

const onPlayerReady: YouTubeProps["onReady"] = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

function NewlineText(props: any) {
  const text = props.text;
  return text.split(".").map((str: any) => <div className="body1" style={{ textAlign: "justify" }}>{str}.</div>);
}

export default function Training() {
    const { id } = useParams();
    const [admissionInfo, setAdmissionInfo] = useState<any>()
    const [paymentPaid, setPaymentPaid] = useState(false)
    const token = storeService.getAuth();

    useEffect(()=>{
        informationService.getAdmissionInfo(id ? id : "").then((res)=>{
            setAdmissionInfo(res)
            if (token !== undefined){
                admitService.createAdmissionUser(id).then((res) => {
                    admitService.getPayment(id).then((response) => {
                        if (response.payment === null){
                            setPaymentPaid(true)
                        }
                        if (response.payment.state === "PAID") {
                            setPaymentPaid(true)
                        }
                    });
                })
            }
        })
    }, [])
  return (
    <div className="mt-5">
        <Helmet>
            <title>{admissionInfo?.admission.name}</title>
            <meta property={"og:title"} content={admissionInfo?.admission.name}/>
            <meta property={"og:description"} content={admissionInfo?.admission_info[0]?.body}/>
            <meta property={"og:image"} content={imgBase+admissionInfo?.admission.imagePath}/>
        </Helmet>
      {admissionInfo && (
        <>
          <div className="row">
            <div className="col-md-7 mr-5">
              <h3 className="font-weight-bold">
                {admissionInfo?.admission.name}
              </h3>
              {admissionInfo.admission_info.map((val: any) => (
                <>
                  <h5 className="font-weight-bold mt-4 mb-3">{val.title}</h5>
                  <NewlineText
                    className="body1 pb-4"
                    style={{ borderBottom: "1px solid #C7C9D9" }}
                    text={val.body}
                  >
                  </NewlineText>
                </>
              ))}
            </div>
            <div className="col-md-4">
              <div
                className="p-5"
                style={{ borderRadius: "8px", border: "1px solid #C7C9D9" }}
              >
                <div
                  className="d-flex justify-content-between align-items-center pb-3 mb-5"
                  style={{ borderBottom: "1px solid #C7C9D9" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="training-icon">
                      <RiMapPin2Line size={24} />
                    </div>
                    <div className="body1 font-weight-bold ml-2">
                      СУРАЛЦАХ ХЭЛБЭР
                    </div>
                  </div>
                  <div className="body1 m-0">
                    {admissionInfo.admission.location}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center pb-3 mb-5"
                  style={{ borderBottom: "1px solid #C7C9D9" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="training-icon">
                      <RiCalendarTodoLine size={24} />
                    </div>
                    <div className="body1 font-weight-bold ml-2">
                      ЭХЛЭХ ХУГАЦАА
                    </div>
                  </div>
                  <div className="body1 m-0">
                    (
                    {moment(admissionInfo.admission.confirmStart.date).format(
                      "YYYY/MM/DD"
                    )}
                    )
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center pb-3 mb-5"
                  style={{ borderBottom: "1px solid #C7C9D9" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="training-icon">
                      <RiCalendarCheckLine size={24} />
                    </div>
                    <div className="body1 font-weight-bold ml-2">
                      СУРГАЛТЫН ХУГАЦАА
                    </div>
                  </div>
                  <div className="body1 m-0">
                    {admissionInfo.admission.studyYear}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center pb-3 mb-5"
                  style={{ borderBottom: "1px solid #C7C9D9" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="training-icon">
                      <RiTimerLine size={24} />
                    </div>
                    <div className="body1 font-weight-bold ml-2">
                      СУРАЛЦАХ ЦАГ
                    </div>
                  </div>
                  <div className="body1 m-0">
                    {admissionInfo.admission.studySession}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center pb-3 mb-5"
                  style={{ borderBottom: "1px solid #C7C9D9" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="training-icon">
                      <RiGlobalLine size={24} />
                    </div>
                    <div className="body1 font-weight-bold ml-2">
                      СУРАЛЦАХ ХЭЛ
                    </div>
                  </div>
                  <div className="body1 m-0">
                    {admissionInfo.admission.studyLanguage}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center pb-3 mb-5"
                  style={{ borderBottom: "1px solid #C7C9D9" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="training-icon">
                      <RiCoinLine size={24} />
                    </div>
                    <div className="body1 font-weight-bold ml-2">ТӨЛБӨР</div>
                  </div>
                  <div className="body1 m-0">
                    {admissionInfo.admission.creditFeeAmount.toLocaleString(
                      "en-US"
                    )}
                    ₮
                  </div>
                </div>
                  {
                      admissionInfo.admission.state === 'finished' || admissionInfo.admission.state === 'closed' ?
                          <>
                              <div
                                  className="btn btn-primary disabled"
                                  style={{ backgroundColor: "#FFCC00" }}
                              >
                                  <RiCloseFill size={24} style={{ marginRight: "10px" }} />{" "}
                                  Бүртгэл дууссан
                              </div>
                          </>
                          :
                          <>
                              {admissionInfo.admission_register?.capacity -
                              admissionInfo.admission_registered_users >
                              0 ? (
                                  <a href={`/user/payment/training/${id}`}>
                                      <div
                                          className="btn btn-primary"
                                          style={{ backgroundColor: "#FFCC00" }}
                                      >
                                          <RiArrowRightLine
                                              size={24}
                                              style={{ marginRight: "10px" }}
                                          />{" "}
                                          {paymentPaid ? "Сургалтын төлбөр төлөх" : "Бүртгүүлэх"}
                                      </div>
                                  </a>
                              ) : (
                                  <div
                                      className="btn btn-primary disabled"
                                      style={{ backgroundColor: "#FFCC00" }}
                                  >
                                      <RiCloseFill size={24} style={{ marginRight: "10px" }} />{" "}
                                      Бүртгэл дүүрсэн
                                  </div>
                              )}
                          </>
                  }

              </div>
              <div
                className="p-3 my-3"
                style={{ borderRadius: "8px", border: "1px solid #C7C9D9" }}
              >
                <div className="body1 font-weight-bold mb-2">
                  БАЙГУУЛЛАГЫН СУРГАЛТ
                </div>
                <div className="body1">
                  {admissionInfo.admission_contact.company}
                </div>
              </div>
              <div
                className="p-3 my-3"
                style={{ borderRadius: "8px", border: "1px solid #C7C9D9" }}
              >
                <div className="body1 font-weight-bold mb-2">ХОЛБОО БАРИХ</div>
                <NewlineText text={admissionInfo.admission_contact.contact} />
              </div>
              <YouTube
                videoId={admissionInfo.admission_contact.video}
                opts={opts}
                onReady={onPlayerReady}
              />
            </div>
          </div>
          <div className="row my-2">
            <h5 className="font-weight-bold">Анги дүүргэлт</h5>
          </div>
          <div
            className="row p-5 w-100"
            style={{ borderRadius: "8px", border: "1px solid #C7C9D9" }}
          >
            <div
              className="col-md-4 d-flex align-items-center justify-content-center"
              style={{ borderRight: "1px solid #C7C9D9" }}
            >
              <div className="d-flex flex-column align-items-center">
                <h4 className="font-weight-bold">
                  {admissionInfo.admission_register?.capacity}
                </h4>
                <div className="body1">Нийт бүртгүүлэх тоо</div>
              </div>
            </div>
            <div
              className="col-md-4 d-flex align-items-center justify-content-center"
              style={{ borderRight: "1px solid #C7C9D9" }}
            >
              <div className="d-flex flex-column align-items-center">
                <h4 className="font-weight-bold">
                  {admissionInfo.admission_registered_users
                    ? admissionInfo.admission_registered_users
                    : 0}
                </h4>
                <div className="body1">Одоогоор бүртгүүлсэн тоо</div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <h4 className="text-blue-400 font-weight-bold">
                  {admissionInfo.admission_register?.capacity -
                    admissionInfo.admission_registered_users}
                </h4>
                <div className="body1">Үлдсэн бүртгэлийн тоо</div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <h5 className="font-weight-bold">Багш/Багийн танилцуулга</h5>
          </div>
          <div className="row">
            {admissionInfo.admission_teachers.map((teacher: any) => (
              <>
                <div className="col-lg-3 my-2">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={`${imgBase}${teacher.imagePath}`}
                      style={{
                        borderRadius: "8px",
                        objectFit: "contain",
                        width: "100%",
                        height: "100%"
                      }}
                    />
                    <h4 className="font-weight-bold mt-2">{teacher.name}</h4>
                    <h6>{teacher.description}</h6>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="row my-2">
            <h5 className="font-weight-bold">Ижил төстэй сургалт</h5>
          </div>
          <div className="row">
            {admissionInfo.admission_recommend &&
              admissionInfo.admission_recommend.map((val: any) => (
                <div className="col-md-3 my-3">
                  <TrainingCard training={val.admission} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
