import { RankingKindUI } from "../../../public/information/entity/information.model";
import { informationService } from "../../../public/information/service/information.service";
import storeService from "../../../../utils/helper/store";
import { useEffect } from "react";

export default function UserScoreRankingKind(props: {
  data: RankingKindUI;
  setTableData: any;
  setCount: any;
  setSelected: any;
  setUserInfoTable: any;
  setLoading: any;
  setRankingId: any;
  setRanking: any;
  setCapacity: any;
  selectedRankingKindId: any;
  setSelectedRankingKindId: any;
  setSelectedName: any;
  selected: any;
  userRanking: any;
  selectedName: any;
}) {
  let userEmail = storeService.getAuth()?.email;
  const onClick = (
    value: any,
    name: string,
    capacity: number,
    selectedName: string
  ) => {
    props.setUserInfoTable();
    // props.setLoading(true);
    props.setRankingId(value);
    props.setRanking();
    informationService.getRankingList(value).then((res) => {
      let capacityCount = 1;
      let indexCount = 0;

      let tableValue = res.ranking.map((element: any, index: number) => {
        if (capacityCount <= capacity) {
          indexCount++;
          if (element.state != "canceled" && element.state != "declined") {
            capacityCount++;
          }
        }

        let timeConfirmState = false;
        if (new Date() > new Date(element.timeConfirm?.date)) {
          timeConfirmState = true;
        }
        if (userEmail === element.admissionUser.user.email) {
          props.setRanking(element);
          props.setUserInfoTable({
            index: index + 1,
            id: element.id,
            averageScore: element.scoreSat,
            skillScore: element.scoreSkill,
            status: element.state,
            satCode: element.admissionUser.satCode,
            email: element.admissionUser.user.email,
            admissionId: element.rankingKind.admission.id,
            rankingId: value,
            timeConfirm: element.timeConfirm?.date,
            timeConfirmState: timeConfirmState,
          });
        }
        return {
          id: element.id,
          index: index + 1,
          averageScore: element.scoreSat,
          skillScore: element.scoreSkill,
          status: element.state,
          satCode: element.admissionUser.satCode,
          email: element.admissionUser.user.email,
          admissionId: element.rankingKind.admission.id,
          timeConfirm: element.timeConfirm?.date,
          timeConfirmState: timeConfirmState,
        };
      });
      // let count = 0;
      // res.admissionRegister.forEach((val: any) => {
      //   count += val.capacity;
      // });

      tableValue.splice(indexCount, 0, { index: -1 });
      props.setCount(indexCount);
      props.setCapacity(capacity);
      props.setTableData(tableValue);
      props.setSelected(name);
      props.setSelectedName(selectedName);
      // props.setLoading(false);
    });
  };

  return (
    <a
      className="py-2"
      onClick={() =>
        onClick(
          props.data.id,
          props.data.description,
          props.data.capacity,
          props.data.name
        )
      }
    >
      <div
        className={`d-flex flex-column ${
          props.selectedName == props.data.name && "font-weight-bold blue-text"
        }`}
      >
        <h5>{props.data.name}</h5>
        <p>{props.data.description}</p>
      </div>
    </a>
  );
}
