import {
  RiGitRepositoryLine,
  RiBankLine,
  RiBookMarkLine,
  RiCoinsLine,
  RiFileTextLine,
  RiTimeLine,
  RiTranslate2,
  RiArrowRightCircleLine,
  RiArrowLeftCircleLine,
} from "react-icons/ri";
import { useEffect, useState } from "react";
import { Profession } from "../entity/information.model";
import { constants } from "../../../../utils/constant/constants";
import { Select } from "antd";
import { ProfessionInformationStyle } from "./ProfessionInformationStyle";

const { Option } = Select;

export default function ProfessionInformationCard(props: {
  profession: Profession;
}) {
  let { profession } = props;
  const [current, setCurrent] = useState(0);
  const [imageUrl, setImageUrl] = useState<string>();
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  let degreeType = "";
  let degreeTypeCount = 0;

  profession.degreeStudyPrograms.forEach((element) => {
    if (!degreeType.includes(element.degreeProgram.name)) {
      degreeType += element.degreeProgram.name + "/";
    }
    degreeTypeCount += 1;
  });

  useEffect(() => {
    if (profession.images.length !== 0) {
      setImageUrl(constants.IMAGE_URL + profession.images[0].filePath);
    }
  }, []);

  const handleChange = (value: string) => {
    setCurrent(parseInt(value));
  };

  return (
    <ProfessionInformationStyle>
      <div className="border-bottom-1">
        {profession.degreeStudyPrograms.length !== 0 ? (
          <>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between flex-column pt-3 pb-4 px-4">
                <div className="d-flex align-items-center justify-content-between mobile-responsive">
                  <h2 className="font-weight-bold">{profession.name}</h2>
                  <div className="selector d-flex align-items-center">
                    <Select
                      defaultValue={
                        profession.degreeStudyPrograms[current].degreeProgram
                          .name
                      }
                      style={{ width: "100%" }}
                      onChange={handleChange}
                    >
                      {profession.degreeStudyPrograms.map((element, index) => (
                        <Option value={index} key={index}>
                          {element.degreeProgram.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <div className="d-flex align-items-center mt-3">
                      <div className="register-icon">
                        <RiGitRepositoryLine size={24} />
                      </div>
                      <div>
                        {/*<div className="body1 ml-2">*/}
                        {/*    Боловсролын зэрэг:*/}
                        {/*</div>*/}
                        <div className="body1 font-weight-bold ml-2">
                          {profession.degree.name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="d-flex align-items-center mt-3">
                      <div className="register-icon">
                        <RiBookMarkLine size={24} />
                      </div>
                      <div className="body1 font-weight-bold ml-2">
                        {
                          profession.degreeStudyPrograms[current].briefInfos
                            .description
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="d-flex align-items-center mt-3">
                      <div className="register-icon">
                        <RiCoinsLine size={24} />
                      </div>
                      <div className="body1 font-weight-bold ml-2">
                        {
                          profession.degreeStudyPrograms[current].briefInfos
                            .payment
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="d-flex align-items-center mt-3">
                      <div className="register-icon">
                        <RiTimeLine size={24} />
                      </div>
                      <div className="body1 font-weight-bold ml-2">
                        {
                          profession.degreeStudyPrograms[current].briefInfos
                            .program
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <div className="d-flex align-items-center mt-3">
                      <div className="register-icon">
                        <RiFileTextLine size={24} />
                      </div>
                      <div className="body1 font-weight-bold ml-2">
                        {
                          profession.degreeStudyPrograms[current].briefInfos
                            .requirement
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <div className="d-flex align-items-center mt-3">
                      <div className="register-icon">
                        <RiTranslate2 size={32} />
                      </div>
                      <div className="body1 font-weight-bold ml-2 ">
                        {
                          profession.degreeStudyPrograms[current].briefInfos
                            .language
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </ProfessionInformationStyle>
  );
}
