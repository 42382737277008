import InformationAccordion from "../components/InformationAccordion";
import { useEffect, useState } from "react";
import { Information, InformationUI } from "../entity/information.model";
import { informationService } from "../service/information.service";
import YouTube, { YouTubeProps } from "react-youtube";
import InstructionAccordion from "../components/instructionAccordion/InstructionAccordion";
import CustomLoader from "../../../public/components/CustomLoader";

export default function Instruction() {
  const [info, setInfo] = useState<Information[]>();
  const [loading, setLoading] = useState(true);

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: "100%",
  };

  useEffect(() => {
    informationService.getInformation("guide").then((res) => {
      console.log(res);
      setInfo(
        res.map((element: InformationUI) => {
          return {
            name: element.name,
            content: (
              <YouTube
                videoId={element.description}
                opts={opts}
                onReady={onPlayerReady}
              />
            ),
          };
        })
      );
      setLoading(false);
    });
  }, []);
  return (
    <div className="mt-5">
      <h1 className="mb-4">Элсэгчдэд өгөх зөвлөгөө</h1>
      {loading ? <CustomLoader /> : <InstructionAccordion data={info} />}
    </div>
  );
}
