import styled from "styled-components";

export const RegistrationAccordionStyleV1 = styled.div`
  @media screen and (max-width: 918px) {
    .ant-collapse-content>.ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse>.ant-collapse-item {
      border-bottom: none !important;
      font-weight: normal;
      border-left: 3px solid #0132A6 !important;
      margin-bottom: 10px !important;
      padding-left: 10px !important;
    }
  }
  .ant-collapse{
    font-size: 16px !important;
    font-weight: bold;
    background-color: transparent !important;
    border-left: none !important;
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
  }
  .ant-collapse>.ant-collapse-item{
    border-bottom: none !important;
    font-weight: normal;
    border-left: 3px solid #0132A6 !important;
    margin-bottom: 40px;
    padding-left: 40px;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header{
    border-bottom: none;
    font-weight: bold;
    font-size: 16px;
  }

  .ant-collapse-content{
    border-top: none !important;
  }
  .ant-collapse-content-active{
    display: block;
  }
`;
