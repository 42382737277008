import MoreButton2 from "../more-button";

import { useEffect, useState } from "react";
import { widgetService } from "../../service/widget.service";
import { NewsStyle } from "./style";
import { NewsCard } from "../news/news-card";
import { useParams } from "react-router-dom";
import { Select, Spin } from "antd";

const Widget = (props: { admissions?: any; id: any }) => {
  const [news, setNews]: any = useState([]);
  const { admissions, id } = props;
  const [admissionId, setAdmissionId] = useState<any>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    widgetService.getNews(id).then((emps) => {
      setNews(emps.posts);
    });
  }, [id]);

  useEffect(() => {
    if (admissionId >= 0) {
      try {
        setLoading(true);

        widgetService.getNews3(id, admissionId).then((emps) => {
          setNews(emps.posts);
        });
      } finally {
        setLoading(false);
      }
    }
  }, [admissionId]);

  return (
    <>
      {news && news.length > 0 && (
        <NewsStyle>
          <section id="news_list" className="mt-5 news_list section">
            <div className="container header-container">
              <div className="row d-flex align-items-center">
                <div className="col-lg">
                  <h1 className="mt-3 mb-4 font-weight-bold">
                    Элсэлтийн мэдээ, мэдээлэл
                  </h1>
                </div>
                <div className="col-lg">
                  <div className="more-button">
                    <MoreButton2 id={id} />

                    {/*<Select*/}
                    {/*  className="mt-2"*/}
                    {/*  showSearch*/}
                    {/*  allowClear*/}
                    {/*  style={{ width: "250px" }}*/}
                    {/*  placeholder="Сонгоно уу"*/}
                    {/*  onChange={(value) => setAdmissionId(value)}*/}
                    {/*  filterOption={(input: any, option: any) =>*/}
                    {/*    option?.children*/}
                    {/*      ?.toString()*/}
                    {/*      .toLowerCase()*/}
                    {/*      .indexOf(input?.toString().toLowerCase()) >= 0*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  <option value={0} key={0}>*/}
                    {/*    Бүгд*/}
                    {/*  </option>*/}
                    {/*  {admissions?.admissions.map((admission: any) => (*/}
                    {/*    <option value={admission.id} key={admission.id}>*/}
                    {/*      {admission.name}*/}
                    {/*    </option>*/}
                    {/*  ))}*/}
                    {/*</Select>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <Spin spinning={loading} style={{ width: "100%" }}>
                <div className="row ">
                  {news?.map((news: any, index: number) => {
                    return (
                      <div key={news.id} className="mb-3 col-md-4">
                        <NewsCard newsData={news} />
                      </div>
                    );
                  })}
                </div>
              </Spin>
            </div>
          </section>
        </NewsStyle>
      )}
    </>
  );
};
export default Widget;
