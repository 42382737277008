import { useEffect, useState } from "react";
import { NewsStyle } from "../../../public/information/components/widget/style";
import { NewsSmall } from "../components/news/news-small";
import { widgetService } from "../service/widget.service";
import { useParams } from "react-router-dom";

export default function News() {
  const [news, setNews]: any = useState([]);
  const { id } = useParams();

  useEffect(() => {
    widgetService.getNewsAll(id).then((emps) => {
      console.log(emps);
      setNews(emps.posts);
    });
  }, []);

  return (
    <>
      <NewsStyle>
        <section id="news_list" className="news_list section">
          <div className="container mt-5">
            <div className="row gx-5">
              <div className={"col-lg-8"}>
                <h1>Мэдээ</h1>
                {news.map((news: any, index: number) => {
                  return (
                    <div key={news.id}>
                      <NewsSmall newsData={news} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </NewsStyle>
    </>
  );
}
