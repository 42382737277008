import styled from "styled-components";

export const InstructionAccordionStyle = styled.div`
  .ant-collapse {
    font-size: 18px !important;
    font-weight: bold;
    background-color: transparent !important;
    border-left: none !important;
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
  }
  .ant-collapse > .ant-collapse-item {
    display: flex;
    justify-content: space-between;
    border-bottom: none !important;
    font-weight: normal;
    margin-bottom: 20px;
    padding-left: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border-bottom: none;
    font-weight: normal;
    font-size: 24px;
    width: 100%;
  }

  .ant-collapse-content {
    border-top: none !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .text-enabled {
    color: #0132a6;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 12px;
    background: #ccd6ed;
    border-radius: 4px;
  }
  .text:hover {
    color: #0132a6;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 12px;
    background: #ccd6ed;
    border-radius: 4px;
    transition: all 0.3s;
    cursor: pointer;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    padding: 4px 12px;
  }

  iframe {
    border-radius: 16px;
  }
`;
