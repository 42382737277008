import {Button, Form, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {SuccessComponentStyle} from "./SuccessComponentStyle";
import {RiSendPlaneFill} from "react-icons/ri";
import {admitService} from "../service/admit.service";
import {IOModal} from "../../../../utils/Modal/Modal";
import success from "../../../../assets/images/success.png";
import {useNavigate} from "react-router-dom";
import CustomLoader from "../../../public/components/CustomLoader";

export default function SuccessComponent(props: {setCheckbox: any; text: any, clickButtonType?: any, satExam?: any, admissionUser?: any}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        props.setCheckbox(false)
    },[])
    console.log(props.admissionUser)
    const handleClick = () => {
        setLoading(true)
        admitService.sendAdmissionUserCertificate(props.admissionUser ? props.admissionUser.id : "").then((res)=>{
            IOModal.show({
                title: "СЭЗИС-ийн бакалаврын хөтөлбөрт амжилттай элслээ.",
                content:
                    <div className="mt-2 text-center">
                        <div className="font-weight-bold">Танд баяр хүргэе.</div>
                        <img
                            src={success}
                            alt="/src/assets/images/success.png"
                            style={{width: "200px", height: "200px"}}
                            className="mt-3"
                        />
                        <div>
                          Таны {props.admissionUser.user.email} хаягаар суралцах эрхийн бичиг илгээгдлээ.
                        </div>
                    </div>
                ,
                onCancel: (args) => {
                    Modal.destroyAll();
                },
                okText: "Дуусгах",
                okButtonProps: { style: { display: "flex", justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' } },
                cancelButtonProps: { style: { display: "none" } },
                onOk: (args) => {
                    Modal.destroyAll();
                    navigate("/user/home");
                }
            });
        }).finally(()=>{
            setLoading(false)
        })
    }

    if (loading) return <CustomLoader/>

    return(
        <Form name="success" layout="vertical">
            <SuccessComponentStyle>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <h4 className="font-weight-bold">{props.text}</h4>
                    <img src="/admission-success.png" className="success-image"/>
                    {
                        props.satExam && props.clickButtonType && (props.clickButtonType === "confirm" || props.clickButtonType === "confirm-registered") && (
                            <Button
                                type="primary"
                                className="submit-btn w-xs-100 w-md-75"
                                onClick={handleClick}
                            >
                                <RiSendPlaneFill size={24} className="mr-2"/>
                                "СУРАЛЦАХ ЭРХИЙН БИЧИГ" цахимаар өөрийн э-мэйл хаягаар авах
                            </Button>
                        )
                    }
                </div>
            </SuccessComponentStyle>

        </Form>
    )
}
