import CustomButton from "../custom-button";
import DateCard from "../date-card";

export const NewsCard = (props: any) => {
  const { newsData } = props;

  const imgBase = process.env.REACT_APP_FILE_HOST;
  const translate: any =
    newsData.translates.length > 0 ? newsData.translates[0] : null;

  const imgSrc =
    newsData.images[0] && newsData.images[0].filePath
      ? imgBase + newsData.images[0].filePath
      : null;

  return (
    <>
      <a href={`/information/newsDetail/${newsData?.id}`}>
        <div
          className="card m-2"
          style={{ width: "100%", borderRadius: "16px", height: "100%" }}
        >
          <div>
            <div className="img">
              {newsData?.youtube ? (
                <iframe
                  width="100%"
                  height="200px"
                  src={`https://www.youtube.com/embed/${newsData?.youtube}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <img
                  className="card-img-top"
                  src={`${imgSrc}`}
                  alt="image-alt-text"
                  style={{
                    borderRadius: "16px 16px 0 0",
                    objectFit: "cover",
                    width: "100%",
                    height: "200px",
                  }}
                />
              )}
            </div>
          </div>
          <div className="card-body">
            <p className="text fw-bold">{translate?.title}</p>
            <p className="card-text">{translate?.description}</p>
            <DateCard newsDate={newsData?.timePublished} />
            <CustomButton />
          </div>
        </div>
      </a>
    </>
  );
};
