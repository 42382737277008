import {Form, notification, Radio, Select} from "antd";
import { validations } from "../../../../utils/validation/validations";
import { admitService } from "../service/admit.service";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { paymentService } from "../../payment/service/payment.service";
import CustomLoader from "../../../public/components/CustomLoader";
import {constants} from "../../../../utils/constant/constants";

const { Option } = Select;

export default function InformationReceived(props: { check: any}) {
  const { admitId } = useParams();
  const [infoChannel, setInfoChannel] = useState<any>();
  const [state, setState] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string|null>("");
  const [loading, setLoading] = useState<boolean>(true);
  const onFinish = (values: any) => {
    admitService
        .updateAdmissionUserInfoChannel(admitId, values.informationReceivedFrom)
        .then((res) => {
          notification.success({
            message: "Success",
          });
        });
  };

  useEffect(() => {
    paymentService.getGlobalConstants("info_channel").then((res) => {
      setInfoChannel(res.globalConstants);
      setLoading(false);

    });
  }, []);

  return (
      <>
        {loading ? (
            <CustomLoader />
        ) : (
            <Form name="informationReceived" layout="vertical" onFinish={onFinish}>
              <div className="register-box">
                <div className="row">
                  <div className="col-md-10">
                    <Form.Item
                        name="informationReceivedFrom"
                        rules={[...validations.REQUIRED_FIELD]}
                    >
                      <Select placeholder="Элсэлтийн мэдээллийн талаар мэдээлэл хүлээн авсан суваг" allowClear>
                        {infoChannel.map((element: any) => (
                            <Option value={element.id}>{element.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
        )}
      </>
  );
}
