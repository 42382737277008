import styled from "styled-components";

export const RegistrationTextStyle = styled.div`

  @media screen and (max-width: 918px) {
    .mobile-responsive-section{
      display: block !important;
    }
    
    .mobile-responsive-status{
      margin-left: auto !important;
      width: 85%;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: auto !important;
    }
  }
  button{
    border: #FFFFFF;
    color: #FFFFFF;
    background: #0132A6;
    padding: 6px 8px 6px 16px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 13px;
  }
  
  a {
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .registration-open{
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #E3FFF1;
    border-radius: 4px;
    padding: 4px 6px;
    gap: 8px;
    color: #05A660;
  }
  .registration-closed{
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #FFE5E5;
    border-radius: 4px;
    padding: 4px 6px;
    gap: 8px;
    color: #E53535;
  }
`;
