import { Form, Input, Select } from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { useEffect, useState } from "react";
import { validations } from "../../../../utils/validation/validations";
import { paymentService } from "../../payment/service/payment.service";
import { admitService } from "../service/admit.service";
import {
  Addressing,
  AddressingUI,
  GlobalUI,
  UserAddress,
  UserAddressUI,
} from "../entity/admit.model";
import CustomLoader from "../../../public/components/CustomLoader";

const { Option } = Select;

export default function Address() {
  const [state, setState] = useState(false);
  const [address, setAddress] = useState<Addressing[]>();
  const [subAddress, setSubAddress] = useState<Addressing[]>();
  const [addressState, setAddressState] = useState<GlobalUI[]>();
  const [addressStateType, setAddressStateType] = useState<GlobalUI[]>();
  const [accommodation, setAccommodation] = useState();
  const [userAddress, setUserAddress] = useState<UserAddressUI>();
  const [loading, setLoading] = useState(true);
  const [newAddress, setNewAddress] = useState(false);

  useEffect(() => {
    admitService.getAddressing("0").then((response) => {
      setAddress(response.addressings);
      paymentService
        .getGlobalConstants("user_addressing_state")
        .then((response) => {
          setAddressState(response.globalConstants);
          response.globalConstants.forEach((element: any) => {
            if (element.name === "Дотуур байр") {
              setAccommodation(element.id);
            }
          });
          paymentService
            .getGlobalConstants("user_addressing_state_type")
            .then((response) => {
              setAddressStateType(response.globalConstants);
              admitService.getUserAddress().then((response) => {
                if (response.userAddressings.length === 0) {
                  setNewAddress(true);
                } else {
                  setUserAddress({
                    ...response.userAddressings[0],
                    region: response.userAddressings[0].addressing.parentId,
                    district: response.userAddressings[0].addressing.id,
                    state: response.userAddressings[0].state.id,
                    stateType: response.userAddressings[0].stateType?.id,
                  });
                }

                setLoading(false);
              });
            });
        });
    });
  }, []);

  function onChange(value: string) {
    if (value === accommodation) {
      setState(true);
    } else {
      setState(false);
    }
  }

  const onFinish = (values: any) => {
    console.log(values);
    let userData = {
      addressing: values.district,
      state: values.state,
      detail: values.detail,
      microDistrict: values.microDistrict,
    };
    if (newAddress) {
      admitService.createUserAddress(userData).then((res) => {
        console.log(res);
      });
    } else {
      admitService
        .updateUserAddress(userData, userAddress?.id.toString())
        .then((res) => {
          console.log(res);
        });
    }
  };

  const handleAddressChange = (value: any) => {
    setSubAddress([]);
    admitService.getAddressing(value).then((response) => {
      setSubAddress(response.addressings);
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form
          name="userAddress"
          initialValues={userAddress}
          layout="vertical"
          onFinish={onFinish}
        >
          <div className="register-box">
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  name="region"
                  label="Үндсэн захиргаа"
                  rules={[...validations.REQUIRED_FIELD]}
                >
                  <Select
                    placeholder="Дарж сонгоно уу"
                    allowClear
                    onSelect={handleAddressChange}
                  >
                    {address?.map((element, index) => (
                      <Option key={index} value={element.id}>
                        {element.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="district"
                  label="Сум, дүүрэг"
                  rules={[...validations.REQUIRED_FIELD]}
                >
                  <Select placeholder="Дарж сонгоно уу" allowClear>
                    {subAddress?.map((element) => (
                      <Option key={element.id} value={element.id}>
                        {element.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="microDistrict"
                  label="Баг, хороо"
                  rules={[...validations.REQUIRED_FIELD]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="detail"
                  label="хороолол, байр, тоот"
                  rules={[...validations.REQUIRED_FIELD]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <>
                  <Form.Item
                    name="state"
                    label="Амьдарч буй газар"
                    rules={[...validations.REQUIRED_FIELD]}
                  >
                    <Select
                      placeholder="Дарж сонгоно уу"
                      allowClear
                      onSelect={onChange}
                    >
                      {addressState?.map((element) => (
                        <Option value={element.id} key={element.id}>
                          {element.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              </div>
              {state ? (
                <>
                  <div className="col-md-6">
                    <Form.Item
                      name="stateType"
                      label="Дотуур байр төрөл"
                      rules={[...validations.REQUIRED_FIELD]}
                    >
                      <Select placeholder="Дарж сонгоно уу" allowClear>
                        {addressStateType?.map((element) => (
                          <Option value={element.id} key={element.id}>
                            {element.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
