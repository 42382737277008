import PersonalInformation from "../../admit/components/PersonalInformation";
import { Button, message, Form } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function UserInfo() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <Form.Provider
        onFormFinish={(name) => {
          if (name === "personalInformation") {
            message.success("Success");
          }
        }}
      >
        {/*<h1 className="mb-3">Үндсэн мэдээлэл</h1>*/}
        <PersonalInformation />
        <div className="row mt-3">
          <div className="col-lg-4" />
          <div className="col-lg-4">
            <div className="d-flex">
              <Button
                type="primary"
                className="submit-btn mx-2"
                onClick={() => navigate(-1)}
              >
                Буцах
              </Button>
              <Button
                type="primary"
                className="submit-btn mx-2"
                htmlType="submit"
                form="personalInformation"
              >
                Хадгалах
              </Button>
            </div>
          </div>
          <div className="col-lg-4" />
        </div>
      </Form.Provider>
    </div>
  );
}
