import Widget from "../../information/components/widget";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DegreeUI} from "../../../private/components/PrivateHeader/entity/header.model";
import {headerService} from "../../../private/components/PrivateHeader/service/header.service";
import {BiArrowBack} from "react-icons/bi";
import {Pagination} from "antd";
import {TrainingCard} from "../../components/TrainingCard";

export default function Degree() {

    const {degreeGroupId} = useParams();
    const navigate = useNavigate();
    const [degree, setDegree] = useState<DegreeUI>();
    const [currDegreeGroupName, setCurrDegreeGroup] = useState<any>();
    const [trainings, setTrainings] = useState<any>();
    const [trainingsPagination, setTrainingsPagination] = useState<any>();

    useEffect(() => {
        window.scrollTo({
            top: 250,
            behavior: "smooth",
        });
        headerService.getDegree(degreeGroupId).then((response) => {
            setDegree(response);
            headerService.getDegreeTraining().then((response) => {
                setTrainings(response.admissions)
                // setTrainingDegree(response.degree)
                const result = response.admissions.reduce((resultArray: any, item: any, index: any) => {
                    const chunkIndex = Math.floor(index / 8)

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                }, [])
                setTrainingsPagination(result[0])
            })
            headerService.getDegreeGroupName(degreeGroupId).then((response) => {
                setCurrDegreeGroup(response);
            })
        });
    }, []);
    const handlePagination = (page: any, pageSize: any) => {

        const result = trainings.reduce((resultArray: any, item: any, index: any) => {
            const chunkIndex = Math.floor(index / pageSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
        setTrainingsPagination(result[page - 1])
        console.log(page, pageSize)
        console.log(result)
    }
    return (
        <div className="container">
            <h3 className="mt-3">Academics / Сургалтууд</h3>
            <a className="mb-4" onClick={() => {
                navigate("/");
            }}>
                <BiArrowBack className={"d-inline"}/> {currDegreeGroupName?.degreeGroupName}
            </a>
            {degreeGroupId !== '3' ? <>
                <div className="container mt-5">
                    <div className="row mt-4">
                        {degree?.degrees.map((element) => {
                            return (
                                <a
                                    className="col-md-6"
                                    onClick={() => {
                                        navigate("/information/registration/" + element.id.toString());
                                    }}
                                    key={element.id}
                                >
                                    <div className="card">
                                        <div className="card-body">
                                            <h6 className="card-title">{element.name}</h6>
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </> : <>
                <div className="row">
                    {trainingsPagination &&
                        trainingsPagination.map((val: any) => (
                            <div className="col-md-3 mt-3">
                                <TrainingCard training={val}/>
                            </div>

                        ))
                    }
                </div>
                {
                    trainings ?
                        <div className="mt-5 d-flex align-items-center justify-content-center">
                            <Pagination defaultCurrent={1} total={trainings.length} onChange={handlePagination}
                                        pageSize={8}/>
                        </div> : <></>
                }
            </>}
        </div>

    );
}


