import { api } from "../helper/api";
import { FileResponse } from "../entity/utility.model";

async function uploadFile(file: File): Promise<FileResponse> {
  const formData = new FormData();
  formData.append("file", file);
  return await api.post(`/file/service/user_file/upload`, formData);
}

export const utilityService = {
  uploadFile,
};
