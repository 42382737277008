import { useEffect, useState } from "react";
import { UserDisability, UserDisabilityType } from "../entity/admit.model";
import { admitService } from "../service/admit.service";
import { paymentService } from "../../payment/service/payment.service";
import { Form, InputNumber, Select } from "antd";
import { validations } from "../../../../utils/validation/validations";
import CustomLoader from "../../../public/components/CustomLoader";

const { Option } = Select;

export default function UserDisabilityForm() {
  const [state, setState] = useState(false);
  const [disability, setDisability] = useState<UserDisability>();
  const [disabilityType, setDisabilityType] = useState<UserDisabilityType[]>();
  const [disabilityReason, setDisabilityReason] =
    useState<UserDisabilityType[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    admitService.getUserDisability().then((disability) => {
      paymentService.getGlobalConstants("disability_type").then((global) => {
        paymentService
          .getGlobalConstants("disability_reason")
          .then((reason) => {
            setDisability(disability.user_disability);
            setState(
              disability.user_disability?.hasIssue
                ? disability.user_disability.hasIssue
                : false
            );
            setDisabilityType(
              global.globalConstants.map((element: any) => {
                return {
                  id: element.id,
                  name: element.name,
                };
              })
            );
            setDisabilityReason(
              reason.globalConstants.map((element: any) => {
                return {
                  id: element.id,
                  name: element.name,
                };
              })
            );
            setLoading(false);
          });
      });
    });
  }, []);

  function onChange(value: boolean) {
    setState(value);
  }

  const onFinish = (values: any) => {
    let userDisability: UserDisability;
    userDisability = {
      hasIssue: values.hasIssue,
      type: values?.disabledType ? values.disabledType : "",
      lossRate: values?.lossRate ? values?.lossRate : 0,
      reason: values?.disabledReason ? values?.disabledReason : "",
    };
    admitService.updateUserDisability(userDisability).then((response) => {});
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form
          name="userDisabilityForm"
          initialValues={disability}
          layout="vertical"
          onFinish={onFinish}
        >
          <div className="register-box">
            <div className="row">
              <>
                <div className="col-md-6">
                  <Form.Item
                    name="hasIssue"
                    label="Хөгжлийн бэрхшээлтэй эсэх"
                    rules={[...validations.REQUIRED_FIELD]}
                  >
                    <Select
                      placeholder="Дарж сонгоно уу"
                      allowClear
                      onSelect={onChange}
                    >
                      <Option value={true}>Тийм</Option>
                      <Option value={false}>Үгүй</Option>
                    </Select>
                  </Form.Item>
                </div>
                {state ? (
                  <>
                    <div className="col-md-6">
                      <Form.Item
                        name="disabledType"
                        label="Хөгжлийн бэрхшээл"
                        rules={[...validations.REQUIRED_FIELD]}
                        initialValue={disability?.type?.id}
                      >
                        <Select placeholder="Дарж сонгоно уу" allowClear>
                          {disabilityType && (
                            <>
                              {disabilityType.map((type) => (
                                <Option key={type.id} value={type.id}>
                                  {type.name}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="disabledReason"
                        label="Хөгжлийн бэрхшээлийн үндсэн шалтгаан"
                        rules={[...validations.REQUIRED_FIELD]}
                        initialValue={disability?.reason?.id}
                      >
                        <Select placeholder="Дарж сонгоно уу" allowClear>
                          {disabilityReason && (
                            <>
                              {disabilityReason.map((reason, index) => (
                                <Option key={index} value={reason.id}>
                                  {reason.name}
                                </Option>
                              ))}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="lossRate"
                        label="Чадвар алдалтын хувь"
                        rules={[...validations.REQUIRED_FIELD]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          formatter={(value) => `${value}%`}
                          parser={(value: any) => value.replace("%", "")}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
