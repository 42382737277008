import { Modal } from "antd";
import { createContext, useState } from "react";

const applicationRequestContext = createContext<{
  toggleModal: () => void;
} | null>(null);

export const ApplicationRequestProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <applicationRequestContext.Provider value={{ toggleModal }}>
      {showModal && (
        <Modal
          title="Өргөдөл, санал хүсэлт гаргах"
          centered
          visible={showModal}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
        >
          <div className="d-flex flex-column">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfcbJe0V5POk-7QJlmud9PK50HyE6lMYYh2fMG8oEXJVgaPpA/viewform?embedded=true"
            >
              Мэргэжил хөтөлбөр, санал хүсэлт
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe87WwVc3K-vl1poguZzAIQ6e0cOu3C9nRvygFS4aItlGCG6Q/viewform?embedded=true"
            >
              Бусад өргөдөл хүсэлт
            </a>
            {/* <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSe87WwVc3K-vl1poguZzAIQ6e0cOu3C9nRvygFS4aItlGCG6Q/viewform?embedded=true"
              width="100%"
              height="1393"
              frameBorder="0"
              // marginHeight="0"
              // marginwidth="0"
              title="мэдээлэл хөтөлбөр"
            >
              Loading…
            </iframe> */}
          </div>
        </Modal>
      )}

      {children}
    </applicationRequestContext.Provider>
  );
};

export default applicationRequestContext;
