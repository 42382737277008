import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Alert } from "antd";
import { validations } from "../../../../../utils/validation/validations";
import { RiAddCircleFill, RiDeleteBin5Line } from "react-icons/ri";
import { UserSat, UserSatUI } from "../../entity/admit.model";
import { admitService } from "../../service/admit.service";
import { IOModal } from "../../../../../utils/Modal/Modal";
import CustomLoader from "../../../../public/components/CustomLoader";

export default function BachelorSatInformation(props: {setCheckBox?: any}) {
  const [loading, setLoading] = useState(true);
  const [initialInput, setInitialInput] = useState<UserSatUI[]>([{}]);
  const [input, setInput] = useState<UserSat[]>([]);

  useEffect(() => {
    props.setCheckBox !== undefined ? props.setCheckBox(true) : console.log("No check box")
    admitService.getUserSat().then((res) => {
      if (res.userSat.length !== 0) {
        setInitialInput(res.userSat);
        setInput(res.userSat);
      }
      setLoading(false);
    });
  }, []);

  const onFinish = (values: any) => {
    notification.success({
      message: "Success",
    });
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <Form name="userSat" layout="vertical" onFinish={onFinish}>
          <div className="register-box">
            <div className="row">
              <Form.List name="userSat" initialValue={initialInput}>
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => {
                        const onChangePassword = (e: any) => {
                          let dataArray = input;
                          if (dataArray[field.name] === undefined) {
                            dataArray.splice(field.name, 0, {
                              satPassword: e.target.value,
                            });
                          } else {
                            dataArray[field.name].satPassword = e.target.value;
                          }
                          setInput(dataArray);
                        };

                        const onChangeCode = (e: any) => {
                          let dataArray = input || [];
                          if (dataArray[field.name] === undefined) {
                            dataArray.splice(field.name, 0, {
                              satCode: e.target.value,
                            });
                          } else {
                            dataArray[field.name].satCode = e.target.value;
                          }
                          setInput(dataArray);
                        };
                        return (
                          <div className="col-md-12" key={index + "userSat"}>
                            <div className="row">
                              <div className="col-md-4">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "satCode"]}
                                  label="ЭЕШ-ын дугаар"
                                  rules={[
                                    { required: true },
                                    ...validations.SAT_NUMBER,
                                  ]}
                                >
                                  <Input
                                    onChange={onChangeCode}
                                    disabled={
                                      initialInput[field.name]?.isConfirm
                                        ? true
                                        : false
                                    }
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-md-4">
                                <Form.Item
                                  {...fields}
                                  name={[field.name, "satPassword"]}
                                  label="Нууц үг"
                                  rules={[
                                    { required: true },
                                    {
                                      min: 6,
                                      message:
                                        "Нууц үг хамгийн багадаа 6 орон байна.",
                                    },
                                  ]}
                                >
                                  <Input.Password
                                    onChange={onChangePassword}
                                    disabled={
                                      initialInput[field.name]?.isConfirm
                                        ? true
                                        : false
                                    }
                                  />
                                </Form.Item>
                              </div>
                              {initialInput[field.name]?.isConfirm ? (
                                <div className="my-2 col-md-4 d-flex align-items-center"></div>
                              ) : (
                                <>
                                  <div className="p-1 my-2 col-md-2 d-flex align-items-center">
                                    {
                                      initialInput[field.name]?.id !== undefined ?
                                          (
                                            <>
                                              <Button
                                                  type="primary"
                                                  onClick={(event) => {
                                                    event.preventDefault()
                                                    let regex = /^([0-9]{9})+$/
                                                    console.log("TEST", input[field.name]?.satCode)
                                                    // @ts-ignore
                                                    if (regex.test(input[field.name]?.satCode)){
                                                      event.currentTarget.classList.replace("submit-btn-small", "submit-btn-clicked")
                                                      if (
                                                          initialInput[field.name]?.id !==
                                                          undefined
                                                      ) {
                                                        admitService
                                                            .updateUserSat(
                                                                input[field.name],
                                                                initialInput[
                                                                    field.name
                                                                    ]?.id?.toString()
                                                            )
                                                            .then((res) => {
                                                              props.setCheckBox(false)

                                                              notification.success({
                                                                message:
                                                                    "БҮТ-ийн дугаар баталгаажлаа",
                                                              });
                                                            });
                                                        console.log(input[field.name]);
                                                      } else {
                                                        admitService
                                                            .createUserSat(input[field.name])
                                                            .then((res) => {
                                                              props.setCheckBox(false)

                                                              notification.success({
                                                                message:
                                                                    "БҮТ-ийн дугаар нэмэгдлээ",
                                                              });
                                                            });
                                                      }
                                                    } else {
                                                      notification.error({
                                                        message:
                                                            "БҮТ-ийн дугаар буруу байна.",
                                                      });
                                                    }

                                                  }}
                                                  block
                                                  // icon={
                                                  //   <RiAddCircleFill
                                                  //     size={20}
                                                  //     className="mr-2"
                                                  //   />
                                                  // }
                                                  className="submit-btn-small"
                                              >
                                                {
                                                  initialInput[field.name]?.id !== undefined ?
                                                      <>Батлах</>
                                                      :
                                                      <>Хадгалах</>
                                                }
                                              </Button>
                                            </>
                                          )
                                          :
                                          (
                                            <Button
                                              type="primary"
                                              onClick={(event) => {
                                                event.preventDefault()
                                                let regex = /^([0-9]{9})+$/
                                                // @ts-ignore
                                                console.log("TEST", regex.test(input[field.name]?.satCode))
                                                // @ts-ignore
                                                if (regex.test(input[field.name]?.satCode)){
                                                  event.currentTarget.classList.replace("submit-btn-small", "submit-btn-clicked")
                                                  if (
                                                      initialInput[field.name]?.id !==
                                                      undefined
                                                  ) {
                                                    admitService
                                                        .updateUserSat(
                                                            input[field.name],
                                                            initialInput[
                                                                field.name
                                                                ]?.id?.toString()
                                                        )
                                                        .then((res) => {
                                                          props.setCheckBox(false)

                                                          notification.success({
                                                            message:
                                                                "БҮТ-ийн дугаар баталгаажлаа",
                                                          });
                                                        });
                                                    console.log(input[field.name]);
                                                  } else {
                                                    admitService
                                                        .createUserSat(input[field.name])
                                                        .then((res) => {
                                                          props.setCheckBox(false)

                                                          notification.success({
                                                            message:
                                                                "БҮТ-ийн дугаар нэмэгдлээ",
                                                          });
                                                        });
                                                  }
                                                } else {
                                                  notification.error({
                                                    message:
                                                        "БҮТ-ийн дугаар буруу байна.",
                                                  });
                                                }
                                              }}
                                              block
                                              // icon={
                                              //   <RiAddCircleFill
                                              //     size={20}
                                              //     className="mr-2"
                                              //   />
                                              // }
                                              className="submit-btn-small"
                                            >
                                              {
                                                initialInput[field.name]?.id !== undefined ?
                                                      <>Батлах</>
                                                    :
                                                      <>Хадгалах</>
                                              }
                                            </Button>
                                          )
                                    }
                                  </div>
                                  <div className="my-2 col-md-2 d-flex align-items-center">
                                    <RiDeleteBin5Line
                                      size={32}
                                      onClick={() => {
                                        if (fields.length !== 1) {
                                          if (
                                            initialInput[field.name] !==
                                            undefined
                                          ) {
                                            IOModal.confirm({
                                              title: "Та итгэлтэй байна уу",
                                              content:
                                                "Та устгахыг хүсэж байвал тийм дарна уу.",
                                              okText: "Тийм",
                                              onOk: () => {
                                                console.log("ok");
                                                admitService
                                                  .deleteUserSat(
                                                    initialInput[
                                                      field.name
                                                    ].id?.toString()
                                                  )
                                                  .then((res) => {
                                                    remove(field.name);
                                                    notification.success({
                                                      message: "Амжилттай",
                                                    });
                                                    Modal.destroyAll();
                                                  });
                                              },
                                            });
                                          } else {
                                            remove(field.name);
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <Alert
                        // message="Санамж"
                        description={
                          <div className="text-danger">
                            Та ЭЕШ-ын БҮТ-ийн дугаараа бичсэн бол заавал хадгалах
                            товчийг дарна уу ЖИЧ: Дараагүй тохиолдолд таны
                            ЭЕШ-ын оноо татагдахгүй байхыг анхаарна уу
                          </div>
                        }
                        type="info"
                        showIcon
                        className="mx-2 mb-3 w-100"
                      />
                      <Alert
                        message="Санамж"
                        description="Та ЭЕШ-ыг нэгээс дээш удаа өгсөн бол “БҮТ нэмэх” товч дарж нэмэлт БҮТ-ийн дугаарыг бүртгүүлнэ. Энэ тохиолдолд элсэлтийн шалгалтын оноог тооцохдоо өндөр оноог авна. "
                        type="info"
                        showIcon
                        className="mx-2 mb-3 w-100"
                      />
                      <Alert
                          message="Санамж"
                          description={<>ЭЕШ-ийн дугаар нь <a href='https://eyesh.eec.mn'>https://eyesh.eec.mn</a> вэб хуудсанд нэвтрэх дугаар юм.</>}
                          type="info"
                          showIcon
                          className="mx-2 mb-3 w-100"
                      />
                      <div className="col-md-12 d-flex">
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              if (fields.length < 3) {
                                add();
                                setInput([
                                  ...input,
                                  { satCode: "", satPassword: "" },
                                ]);
                              }
                            }}
                            block
                            icon={<RiAddCircleFill size={20} />}
                            className="d-flex"
                          >
                            ЭЕШ-ын дугаар Нэмэх
                          </Button>
                        </Form.Item>
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}
