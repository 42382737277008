import InformationAccordion from "../components/InformationAccordion";
import ContactContent from "../components/ContactContent";
import { useEffect, useState } from "react";
import { informationService } from "../service/information.service";
import { Information, InformationUI } from "../entity/information.model";
import CustomLoader from "../../../public/components/CustomLoader";

const content = [
  {
    type: "mail",
    text: "ufe_connect@ufe.edu.mn",
  },
  {
    type: "number",
    text: "Үндсэн хөтөлбөр 70008085 (дотуур дугаар 1-1-1-1) Мобайл 90335363, 89764940",
  },
  {
    type: "number",
    text: "Олон улсын нягтлан бодох бүртгэл, санхүүгийн мэргэшсэн сургууль 70008085  (дотуур дугаар 1-1-2) Мобайл 99158505, 99875354",
  },
  {
    type: "number",
    text: "Олон улсын хөтөлбөр (дотуур дугаар 1-1-1-2) Мобайл 98474832",
  },
  {
    type: "number",
    text: "IO институт 70008085 (дотуур дугаар 1-1-1-1) Мобайл 86662222, 99137714",
  },
];

const data = [
  {
    name: "Бакалавр /Үндсэн/",
    content: <ContactContent data={content} />,
  },
  {
    name: "Бакалавр /Эчнээ/",
    content: <ContactContent data={content} />,
  },
  {
    name: "Магистр",
    content: <ContactContent data={content} />,
  },
  {
    name: "Доктор",
    content: <ContactContent data={content} />,
  },
  {
    name: "Гурван-Эрдэнэ БДС",
    content: <ContactContent data={content} />,
  },
];

export default function Contact() {
  const [info, setInfo] = useState<Information[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    informationService.getInformation("contact").then((res) => {
      setInfo(
        res.map((element: InformationUI) => {
          return {
            name: element.name,
            content: <ContactContent data={element.info} />,
          };
        })
      );
      setLoading(false);
    });
  }, []);
  return (
    <div className="mt-5">
      <h1 className="mb-4 font-weight-bold">Холбоо барих</h1>
      {loading ? <CustomLoader /> : <InformationAccordion data={info} />}
    </div>
  );
}
