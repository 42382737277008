import { Collapse } from "antd";
import { RegistrationAccordionStyle } from "./RegistrationAccordionStyle";
import ImageCarousel from "./ImageCarousel";
import ImageList from "./ImageList";
import {useEffect, useState} from "react";
import { ReactComponent as DefaultImage } from "../../../../assets/default-image.svg";
import { constants } from "../../../../utils/constant/constants";

const { Panel } = Collapse;

const baseUrl = constants.BASE_URL.slice(0, -1);

export default function RegistrationAccordion(props: { data: any; type: any }) {
  const [key, setKey] = useState<number>(99);

  useEffect(()=>{
      setKey(0)
  },[])

  const onChange = (key: string | string[]) => {
    setKey(parseInt(key[key.length - 1]));
  };

  return (
    <div className="row">
      <div
        className={
          props.type === "Асуулт,Хариулт" ||
          props.type === "Мэргэжлийн танилцуулга" ||
          props.type === "Түгээмэл асуулт, хариулт"
            ? "col-12"
            : "col-md-6"
        }
      >
        <RegistrationAccordionStyle>
          <Collapse expandIconPosition={"right"} onChange={onChange}>
            {props.data.map((element: any, index: number) => {
                console.log("TEST")
              return (
                <Panel header={element.name} key={index}>
                  <ImageList data={element.images} />
                  <div
                    dangerouslySetInnerHTML={{ __html: element.htmlContent }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </RegistrationAccordionStyle>
      </div>
      {props.type !== "Асуулт,Хариулт" &&
      props.type !== "Мэргэжлийн танилцуулга" &&
      props.type !== "Түгээмэл асуулт, хариулт" ? (
        <>
          {props.data[key] === undefined ? (
            <div className="col-md-6">
              <DefaultImage style={{ width: "100%" }} />
            </div>
          ) : (
            <div className="col-md-6">
              {props.data[key].images &&
              props.data[key].images[0] &&
              props.data[key].images[0].filePath.substr(
                props.data[key].images[0].filePath.length - 3
              ) == "pdf" ? (
                <iframe
                  className="w-100"
                  style={{ height: "600px" }}
                  src={baseUrl + props.data[key].images[0].filePath}
                />
              ) : (
                <>
                  {props.data[key].images ? (
                    <ImageCarousel data={props.data[key].images} />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
