import {useNavigate, useParams} from "react-router-dom";
import React, {Component, useEffect, useState} from "react";
import {informationService} from "../../../public/information/service/information.service";
import {admitService} from "../../admit/service/admit.service";
import {
    Alert,
    Button,
    Divider,
    Form,
    message, Modal,
    notification,
    Steps,
    Table,
    Tooltip
} from 'antd';
import {TrainingStepsStyle} from "../../components/TrainingStepsStyle";
import moment from "moment";
import { Input } from 'antd';
import styled from "styled-components";
import {
    RiErrorWarningLine, RiFileCopy2Fill, RiRefreshFill, RiSaveFill
} from "react-icons/ri";
import PersonalInformation from "../../admit/components/PersonalInformation";
import {paymentService} from "../service/payment.service";
import {IOModal} from "../../../../utils/Modal/Modal";
import RegulationTraining from "../components/RegulationTraining";

const { Step } = Steps;

const InputStyle = styled.div`
  .ant-input{
    background-color: #E5F2FF;
    border: 1px solid #007AFF;
    color: #000000;
  }
`;

const renderButton = (onClick: any, disabled: any, title: any, text: any) => {
    const button = (
        <div className="btn btn-primary" onClick={onClick}>
            {text}
        </div>
    );
    return disabled ? (
        <Tooltip placement="topLeft" title={title}>
            <div className="btn btn-primary disabled">{text}</div>
        </Tooltip>
    ) : (
        button
    );
};

const tableData = [
    {
        key: "1",
        bank: "ХХБанк",
        account: "2612007984",
        name: "СЭЗИСургууль",
    },
    {
        key: "2",
        bank: "Хаан банк",
        account: "5129032930",
        name: "Санхүү Эдийн Засгийн Их Сургууль",
    },
    {
        key: "3",
        bank: "Хас банк",
        account: "5000010393",
        name: "Санхүү Эдийн Засгийн Их Сургууль",
    },
];

const columns = [
    {
        title: "Банк",
        dataIndex: "bank",
        key: "bank",
    },
    {
        title: "Дансны дугаар",
        dataIndex: "account",
        key: "account",
    },
    {
        title: "Дансны нэр",
        dataIndex: "name",
        key: "name",
    },
];

class QrImage extends Component<{ data: any }> {
    render() {
        let { data } = this.props;
        return (
            <img
                src={`data:image/jpeg;base64,${data}`}
                alt="Qr"
                style={{ width: "100%", maxWidth: "150px" }}
            />
        );
    }
}

const copyToClipboard = (content: any) => {
    const el = document.createElement("textarea");
    el.value = content;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(el);
    message.success("Амжилттай хуулагдлаа");
};

const PaymentScreen = (props: { setInvoiceCode: any; setEmail: any;  paymentState: any; setStudentDiscount: any; setCodeDiscount: any}) => {
    const { id } = useParams();
    const [qr, setQr] = useState();
    const [shortLink, setShortLink] = useState();
    const [paymentId, setPaymentId] = useState<any>();
    const [sat, setSat] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [mobile, setMobile] = useState();
    const [code, setCode] = useState("")

    const onClick = () => {
        admitService.checkAdmissionDiscountCode(id ? id : "", code).then((response)=>{
            if (response.amount){
                props.setCodeDiscount(response.amount)
                props.setStudentDiscount(true)
                paymentService.createInvoiceQpay(paymentId).then((response) => {
                    setQr(response.invoiceData.qr_image);
                    setShortLink(response.invoiceData.qPay_shortUrl);
                    props.setInvoiceCode(response.paymentInvoice.invoiceCode);
                });

                notification.info({
                    message: "Амжилттай",
                    description: "Таны Qpay амжилттай шинэчлэгдлээ",
                });
            } else {
                props.setStudentDiscount(false)
            }
        })
    }

    const onChange = (e: any) => {
        setCode(e.target.value)
    }


    const handleCopy = () => {
        let exampleData =
            "" + sat + " " + lastName + " " + firstName + " " + mobile;

        copyToClipboard(exampleData);
    };

    const OnRefreshInvoice = () => {
        paymentService.createInvoiceQpay(paymentId).then((response) => {
            setQr(response.invoiceData.qr_image);
            setShortLink(response.invoiceData.qPay_shortUrl);
            props.setInvoiceCode(response.paymentInvoice.invoiceCode);
        });

        notification.info({
            message: "Амжилттай",
            description: "Таны Qpay амжилттай шинэчлэгдлээ",
        });
    };

    useEffect(()=>{
        if (props.paymentState){
            admitService.getPaymentCredit(id).then((creditResponse) => {
                setPaymentId(creditResponse.payment.id.toString())
                paymentService.currentInvoiceQpay(creditResponse.payment.id.toString()).then((response) => {
                    if (response.invoiceData) {
                        setQr(response.invoiceData.qr_image);
                        setShortLink(response.invoiceData.qPay_shortUrl);
                        props.setInvoiceCode(response.paymentInvoice.invoiceCode);
                    } else {
                        paymentService.createInvoiceQpay(creditResponse.payment.id.toString()).then((response) => {
                            setQr(response.invoiceData.qr_image);
                            setShortLink(response.invoiceData.qPay_shortUrl);
                            props.setInvoiceCode(response.paymentInvoice.invoiceCode);
                        });
                    }
                    admitService.getUserInfo(id).then((response) => {
                        setSat(response.admissionUser.satCode);
                        setFirstName(response.userInfo.firstnameMn);
                        setLastName(response.userInfo.lastnameMn);
                        setMobile(response.userInfo.mobile);
                        props.setEmail(response.admissionUser.user.email);
                    });
                });
            })
        } else {
            admitService.getPayment(id).then((creditResponse) => {
                setPaymentId(creditResponse.payment.id.toString())
                paymentService.currentInvoiceQpay(creditResponse.payment.id.toString()).then((response) => {
                    if (response.invoiceData) {
                        setQr(response.invoiceData.qr_image);
                        setShortLink(response.invoiceData.qPay_shortUrl);
                        props.setInvoiceCode(response.paymentInvoice.invoiceCode);
                    } else {
                        paymentService.createInvoiceQpay(creditResponse.payment.id.toString()).then((response) => {
                            setQr(response.invoiceData.qr_image);
                            setShortLink(response.invoiceData.qPay_shortUrl);
                            props.setInvoiceCode(response.paymentInvoice.invoiceCode);
                        });
                    }
                    admitService.getUserInfo(id).then((response) => {
                        setSat(response.admissionUser.satCode);
                        setFirstName(response.userInfo.firstnameMn);
                        setLastName(response.userInfo.lastnameMn);
                        setMobile(response.userInfo.mobile);
                        props.setEmail(response.admissionUser.user.email);
                    });
                });
            })
        }

    }, [])

  return(
      <>
          <h3 className="mb-4">Төлбөр төлөх</h3>
          <div className="row">
              <div className="mt-2 col-md-6">
                  <div className="payment-card">
                      <div className="payment-card-body">
                          <h5>Төлбөр төлөх алхам:</h5>
                          <ul>
                              <li>Банкны аппликейшн нээх</li>
                              <li>Qpay хэсэг сонгох</li>
                              <li>QR кодыг уншуулах</li>
                              <li>Төлбөр шалгах товчийг дарсны дараа Дуусгах товчийг дарна уу.</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="mt-2 col-md-6">
                  <div className="payment-card">
                      <div className="payment-card-body">
                          <h5>Бүртгэлийн хураамж төлөх Qpay:</h5>
                          <QrImage data={qr} />
                          <p>
                              Qpay уншуулах боломжтой {" "}
                              <a href={shortLink}> банкууд</a>
                          </p>
                      </div>
                  </div>
              </div>
          </div>
          <div className="mt-3 row">
              <div className="mt-2 col-md-12">
                  <div className="payment-card">
                      <div className="payment-card-body">
                          <h5>Дансаар төлбөр төлөх:</h5>
                              <p className="mt-3">
                                  • Нэхэмжлэх авч төлбөр төлөх тохиолдолд "Дуусгах" товчийг дарж утсаар холбогдоно уу.</p>
                              <p className="mt-3">
                                  • СЭЗИС-ийн дараах дансаар төлбөр төлөх
                                  боломжтой. Гүйлгээний утга дээр "овог, нэр, утасны
                                  дугаар" мэдээллийг бичнэ.
                                  <div className="p-2 my-3 bg-white">
                                      <Divider className="mt-0 mb-3">
                                          Гүйлгээний утга
                                      </Divider>

                                      <div className="mt-2 d-flex justify-content-between">
                                          {sat && sat} {lastName && lastName}{" "}
                                          {firstName && firstName} {mobile && mobile}
                                          <a onClick={handleCopy}>
                                              <RiFileCopy2Fill size={24} />
                                          </a>
                                      </div>
                                  </div>
                              </p>
                          <Table
                              columns={columns}
                              dataSource={tableData}
                              pagination={false}
                              size="small"
                              scroll={{ x: "max-content", y: 500 }}
                          />
                      </div>
                  </div>
              </div>
              <div className="col-md-3">
                  <Button
                      className="mt-3 secondary-btn"
                      onClick={() => OnRefreshInvoice()}
                  >
                      <RiRefreshFill size={24} className="mr-2" /> Qpay шинэчлэх
                  </Button>
              </div>
              <div className="col-md-3" />
              <div className="mt-3 col-12">
                  <Alert
                      message="Санамж"
                      description="Хэрэв 15 минутын дотор төлбөр төлөөгүй тохиолдолд Qpay шинэчлэх товчийг дарна уу."
                      type="info"
                      showIcon
                      className="mb-3 w-100"
                  />
              </div>
              <div className="my-2 col-md-12">
                  <div className="px-5 py-3" style={{borderRadius: '8px', border: '1px solid #C7C9D9'}}>
                      <h5 className="font-weight-bold mb-3">Хөнгөлөлтийн код</h5>
                      <p>Код</p>
                      <Input onChange={onChange} size="large"  />
                      <div style={{backgroundColor: '#E5F2FF', borderRadius: '8px'}} className="d-flex mt-2 px-2 py-1 align-items-center">
                          <RiErrorWarningLine />
                          <div style={{fontSize: '12px', marginTop: '2px', marginLeft: '5px', color: '#007AFF'}}>Зөвхөн СЭЗИС-ээс хүлээж авсан код үйлчилнэ</div>
                      </div>
                      <div className="btn btn-primary mt-3" onClick={onClick}>Шалгах</div>
                  </div>
              </div>
          </div>
      </>
  )
}

const UserInfo = (props: {setClicked: any, setStudentDiscount:any}) => {
    const [code, setCode] = useState("")

    useEffect(()=>{
        props.setClicked(false)
    }, [])

    const onClick = () => {
      admitService.checkUserInfoStudentCode(code).then((response)=>{
          if (response.status === true){
              props.setStudentDiscount(true)
          } else {
              props.setStudentDiscount(false)
          }
      })
    }

    const onChange = (e: any) => {
      setCode(e.target.value)
    }
    return (
        <InputStyle>
            <div className="px-5 py-3 mb-5" style={{borderRadius: '8px', border: '1px solid #C7C9D9'}}>
                <h5 className="font-weight-bold mb-3">Бүртгүүлэгчийн товч мэдээлэл</h5>
                <Form.Provider
                    onFormFinish={(name) => {
                        notification.success({
                            message: "Амжилттай",
                        });
                        props.setClicked(true)
                    }}
                >
                    <PersonalInformation />
                </Form.Provider>

                <div className="row">
                    <div className="col-md-4" />
                    <div className="col-md-4 mt-3">
                        <Button
                            type="primary"
                            className="submit-btn"
                            htmlType="submit"
                            form="personalInformation"
                        >
                            <RiSaveFill size={24} className="mr-2" />
                            Хадгалах
                        </Button>
                    </div>
                    <div className="col-md-4" />
                </div>
            </div>
            {/*<div className="px-5 py-3" style={{borderRadius: '8px', border: '1px solid #C7C9D9'}}>*/}
            {/*    <h5 className="font-weight-bold mb-3">Бүртгэлийн нэмэлт мэдээлэл</h5>*/}
            {/*    <p>Оюутны код</p>*/}
            {/*    <Input onChange={onChange} size="large"  />*/}
            {/*    <div style={{backgroundColor: '#E5F2FF', borderRadius: '8px'}} className="d-flex mt-2 px-2 py-1 align-items-center">*/}
            {/*        <RiErrorWarningLine />*/}
            {/*        <div style={{fontSize: '12px', marginTop: '2px', marginLeft: '5px', color: '#007AFF'}}>Зөвхөн СЭЗИС-ийн оюутан, төгсөгч нарт хамаатай</div>*/}
            {/*    </div>*/}
            {/*    <div className="btn btn-primary mt-3" onClick={onClick}>Шалгах</div>*/}
            {/*</div>*/}
        </InputStyle>

    )
}



export default function PaymentTraining() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [admissionInfo, setAdmissionInfo] = useState<any>()
    const [paymentState, setPaymentState] = useState<boolean>(false);
    const [current, setCurrent] = useState(0);
    const [studentDiscount, setStudentDiscount] = useState<boolean>(false)
    const [codeDiscount, setCodeDiscount] = useState(0)
    const [studentClicked, setStudentClicked] = useState(false)
    const [email, setEmail] = useState();
    const [invoiceCode, setInvoiceCode] = useState();
    const [steps, setSteps] = useState([
        {
            description: 'Бүртгэлийн мэдээлэл',
            content: <UserInfo setClicked={setStudentClicked} setStudentDiscount={setStudentDiscount}/>
        },
        {
            description: 'Төлбөр төлөх',
            content: <PaymentScreen setEmail={setEmail} setInvoiceCode={setInvoiceCode} paymentState={paymentState} setStudentDiscount={setStudentDiscount} setCodeDiscount={setCodeDiscount}/>
        },
    ])


    const OnClick = () => {
        paymentService.checkInvoiceQpay(invoiceCode).then((response) => {
            if (response.checkResult.payment_status === "PAID") {
                IOModal.show({
                    title: "Амжилттай бүртгэгдлээ, баяр хүргэе.",
                    content: (
                        <div className="mt-2 text-center">
                            Та <div className="text-info">{email}</div> и-мэйл хаягаа шалгаж
                            мэдээллээ авна уу. Баярлалаа.
                        </div>
                    ),
                    onCancel: (args) => {
                        Modal.destroyAll();
                        navigate("/user/home");
                    },
                    okButtonProps: {style: {display: "none"}},
                });
            } else {
                notification.error({
                    message: "Та дансаар төлбөр төлсөн бол дуусгах товчыг дарна уу.",
                    description: response.message,
                });
            }
        });
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(()=>{
        informationService.getAdmissionInfo(id ? id : "").then((res)=>{
            setAdmissionInfo(res)
            admitService.createAdmissionUser(id).then((res) => {
                admitService.getPayment(id).then((response) => {
                    if (response.payment === null){
                        setPaymentState(true)
                    }
                    if (response.payment.state === "PAID") {
                        setPaymentState(true);
                    }
                });
            })
        })
    }, [])

    useEffect(()=>{
        if (paymentState){
            if(!admissionInfo.admission.registrationFee){
                setSteps([
                    {
                        description: 'Бүртгэлийн мэдээлэл',
                        content: <UserInfo setClicked={setStudentClicked} setStudentDiscount={setStudentDiscount}/>
                    },
                    {
                        description: 'Журам',
                        content: <RegulationTraining setCheckBox={setStudentClicked}/>
                    },
                    {
                        description: 'Төлбөр төлөх',
                        content: <PaymentScreen setEmail={setEmail} setInvoiceCode={setInvoiceCode} paymentState={paymentState} setStudentDiscount={setStudentDiscount} setCodeDiscount={setCodeDiscount}/>
                    },
                ])
            } else {
                setSteps([
                    {
                        description: 'Журам',
                        content: <RegulationTraining setCheckBox={setStudentClicked}/>
                    },
                    {
                        description: 'Төлбөр төлөх',
                        content: <PaymentScreen setEmail={setEmail} setInvoiceCode={setInvoiceCode} paymentState={paymentState} setStudentDiscount={setStudentDiscount} setCodeDiscount={setCodeDiscount}/>
                    },
                ])
            }

        } else {
            setSteps([
                {
                    description: 'Бүртгэлийн мэдээлэл',
                    content: <UserInfo setClicked={setStudentClicked} setStudentDiscount={setStudentDiscount}/>
                },
                {
                    description: 'Төлбөр төлөх',
                    content: <PaymentScreen setEmail={setEmail} setInvoiceCode={setInvoiceCode} paymentState={paymentState} setStudentDiscount={setStudentDiscount} setCodeDiscount={setCodeDiscount}/>
                },
            ])
        }
    }, [paymentState])
    return(
        <div className="container">
            {
                admissionInfo &&
                <>
                    <h4 className="font-weight-bold text-center">Сургалтын бүртгэл</h4>
                    <div className="row my-3">
                        <div className="col-md-3"/>
                        <div className="col-md-6">
                            <TrainingStepsStyle>
                                <Steps labelPlacement={"vertical"} current={current}>
                                    {
                                        steps.map((val:any)=>(
                                            <Step key={val.description} description={val.description}/>
                                        ))
                                    }
                                </Steps>
                            </TrainingStepsStyle>
                        </div>
                        <div className="col-md-3"/>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            {steps[current].content}
                        </div>
                        <div className="col-md-4">
                            <div className="px-5 py-3" style={{borderRadius: '8px', border: '1px solid #C7C9D9'}}>
                                <h5 className="font-weight-bold mb-3">Apply course</h5>
                                <p className="font-weight-bold">{admissionInfo.admission.name}</p>
                                <div className="d-flex justify-content-between">
                                    <p>Суралцах хэлбэр</p>
                                    <p className="font-weight-bold">{admissionInfo.admission.location}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>Эхлэх хугацаа</p>
                                    <p className="font-weight-bold">{moment(admissionInfo.admission.confirmStart.date).format("YYYY-MM-DD")}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>Сургалтын хугацаа</p>
                                    <p className="font-weight-bold">{admissionInfo.admission.studyYear}</p>
                                </div>
                                <div className="d-flex justify-content-between pb-2" style={{borderBottom: '1px solid #C7C9D9'}}>
                                    <p>Суралцах хэл</p>
                                    <p className="font-weight-bold">{admissionInfo.admission.studyLanguage}</p>
                                </div>
                                {
                                    paymentState ?
                                        <>
                                            <div className="pb-2" style={{borderBottom: '1px solid #C7C9D9'}}>
                                                <div className="d-flex justify-content-between pt-3">
                                                    <p>Сургалтын төлбөр</p>
                                                    <p className="font-weight-bold">{admissionInfo.admission.creditFeeAmount.toLocaleString('en-US')}₮</p>
                                                </div>
                                                {
                                                    studentDiscount &&
                                                    <div className="d-flex justify-content-between">
                                                        <p>Хөнгөлөлт</p>
                                                        <p className="font-weight-bold text-danger-400">-{codeDiscount.toLocaleString('en-US')}₮</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between pt-3 mb-2">
                                                <h6>Нийт</h6>
                                                <p className="font-weight-bold">{studentDiscount ? (admissionInfo.admission.creditFeeAmount - codeDiscount).toLocaleString('en-US') : admissionInfo.admission.creditFeeAmount.toLocaleString('en-US')}₮</p>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="pb-2" style={{borderBottom: '1px solid #C7C9D9'}}>
                                                <div className="d-flex justify-content-between pt-3">
                                                    <p>Бүртгэлийн хураамж</p>
                                                    <p className="font-weight-bold">{admissionInfo.admission.registerFeeAmount.toLocaleString('en-US')}₮</p>
                                                </div>
                                                {
                                                    studentDiscount &&
                                                    <div className="d-flex justify-content-between">
                                                        <p>Оюутны хөнгөлөлт</p>
                                                        <p className="font-weight-bold text-danger-400">-{admissionInfo.admission.registerStudentDiscount.toLocaleString('en-US')}₮</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="d-flex justify-content-between pt-3 mb-2">
                                                <h6>Нийт</h6>
                                                <p className="font-weight-bold">{studentDiscount ? (admissionInfo.admission.registerFeeAmount - admissionInfo.admission.registerStudentDiscount).toLocaleString('en-US') : admissionInfo.admission.registerFeeAmount.toLocaleString('en-US')}₮</p>
                                            </div>
                                        </>
                                }

                                {current < steps.length - 1 && (
                                    <>
                                        <>
                                            {admissionInfo.admission.registrationFee && current === 0 && !paymentState && (
                                                renderButton(() => next(), !studentClicked, "Хадгалах товч дарна уу.", "Үргэлжлүүлэх")
                                            )}
                                            {admissionInfo.admission.registrationFee && current === 0 && paymentState && (
                                                renderButton(() => next(), !studentClicked, "Гэрээтэй танилцаж зөвшөөрнө үү ?", "Үргэлжлүүлэх")
                                            )}
                                            {admissionInfo.admission.registrationFee && current !== 0 && (
                                                renderButton(() => next(), !studentClicked, "Гэрээтэй танилцаж зөвшөөрнө үү ?", "Үргэлжлүүлэх")
                                            )}
                                            {!admissionInfo.admission.registrationFee && current === 0 && (
                                                renderButton(() => next(), !studentClicked, "Хадгалах товч дарна уу.", "Үргэлжлүүлэх")
                                            )}
                                            {!admissionInfo.admission.registrationFee && current === 1 && (
                                                renderButton(() => next(), !studentClicked, "Гэрээтэй танилцаж зөвшөөрнө үү ?", "Үргэлжлүүлэх")
                                            )}
                                        </>
                                    </>

                                )}
                                {current === steps.length - 1 && (
                                    <>
                                        {
                                            paymentState ?
                                                <>
                                                    <div className="btn btn-primary" onClick={OnClick}>
                                                        Төлбөр шалгах
                                                    </div>
                                                    <div className="btn btn-primary mt-3" onClick={() => navigate('/user/home')}>
                                                        Дуусгах
                                                    </div>
                                                </>
                                                :
                                                <div className="btn btn-primary disabled">
                                                    Төлбөр төлөгдсөн
                                                </div>
                                        }
                                    </>

                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 d-flex">
                            {current > 0 && (
                                <div className="btn btn-primary" onClick={() => prev()}>
                                    Буцах
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
