import styled from "styled-components";

export const PaymentComponentStyle = styled.div`
  
  @media screen and (max-width: 918px) {
    ul{
      padding: 0;
    }
  }
  
`;
