import { useForm } from "antd/lib/form/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Modal, Steps } from "antd";
import React from "react";
import { validations } from "../../../../utils/validation/validations";
import { authService } from "../service/auth.service";
import { LoginCredentials } from "../entity/auth.model";
import { IOModal } from "../../../../utils/Modal/Modal";
import { RiUserFill } from "react-icons/ri";
import { useState } from "react";
import success from "../../../../assets/images/success.png";
import Instruction from "../../components/Instruction";
import { LoginStyle } from "./LoginStyle";
import storeService from "../../../../utils/helper/store";
export const loginRoute = "/login";

const steps = [
  {
    key: 1,
    title: "Алхам",
    content: (
      <div>
        <img
          src={success}
          alt="/src/assets/images/success.png"
          style={{ width: "200px", height: "200px" }}
          className="mt-3"
        />
        <div>
          1. Та өөрийн имэйл profile/icon тавих/ даран хувийн мэдээлэл цэс рүү
          үсэрнэ.
        </div>
      </div>
    ),
  },
  {
    key: 2,

    title: "Алхам",
    content: (
      <div>
        <img
          src={success}
          alt="/src/assets/images/success.png"
          style={{ width: "200px", height: "200px" }}
          className="mt-3"
        />
        <div>
          2. Онооны жагсаалт харахыг сонгон өөрийн Онооны жагсаалт, мэргэжил
          сонгох цагийн хуваарийг Бүлэг 1 (Математик/Англи хэл), Бүлэг 2
          (Математик/Нийгэм) гэсэн хоёр хэлбэрээр элсэгч эрэмбэ, мэргэжил,
          хөтөлбөр, суралцах хэлбэр баталгаажуулах цагийн хуваарь, төлбөр,
          элсэгчийн төлөв зэрэг мэдээлэлтэй танилцана.
        </div>
      </div>
    ),
  },
  {
    key: 3,

    title: "Алхам",
    content: (
      <div>
        <img
          src={success}
          alt="/src/assets/images/success.png"
          style={{ width: "200px", height: "200px" }}
          className="mt-3"
        />
        <div>
          3. Төлөв болон Мэргэжил баталгаажуулах хугацаан дээр цаг гүйхгүй од
          /icon / байвал төлөвийн тайлбар хүснэгтийг харна уу.
        </div>
      </div>
    ),
  },
  {
    key: 4,

    title: "Алхам",
    content: (
      <div>
        <img
          src={success}
          alt="/src/assets/images/success.png"
          style={{ width: "200px", height: "200px" }}
          className="mt-3"
        />
        <div>
          4. Хэрэв таны төлөв /цэнхэр өнгө/ тэнцсэн мөн Мэргэжил баталгаажуулах
          хугацаа нээгдсэн байвал Төлбөр төлөх товч идэвхжин төлбөрөө төлөх
          боломжтой болно.
        </div>
      </div>
    ),
  },
  {
    key: 5,

    title: "Алхам",
    content: (
      <div>
        <img
          src={success}
          alt="/src/assets/images/success.png"
          style={{ width: "200px", height: "200px" }}
          className="mt-3"
        />
        <div>
          5. Төлбөрөө төлөхдөө зааврыг маш сайн уншиж няхуур хандана уу. Хэрэв
          төлбөрөө төлсөн бол буцаж онооны жагсаалт руу орон Мэргэжил сонгох
          товч дээр дарна.
        </div>
      </div>
    ),
  },
  {
    key: 6,

    title: "Алхам",
    content: (
      <div>
        <img
          src={success}
          alt="/src/assets/images/success.png"
          style={{ width: "200px", height: "200px" }}
          className="mt-3"
        />
        <div>
          6.Мэргэжилээ баталгаажуулахдаа Мэргэжил, хөтөлбөр сонголтын зааварыг
          заавал уншиж зааврын дагуу .
        </div>
      </div>
    ),
  },
];

export default function Login() {
  const [form] = useForm();
  const [form1] = useForm();
  const navigate = useNavigate();
  const location = useLocation() as any;
  let fromLogin = location.state?.fromLogin;
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const RecoverForm = () => {
    const onFinishRecoverForm = (values: any) => {
      let passwordRecovery = {
        otp: {
          email: values.email,
          code: "-1",
        },
      };
      authService.recoverPassword(passwordRecovery).then((response) => {
        Modal.destroyAll();
        navigate("/recover/password", { state: { email: values.email } });
      });
    };
    return (
        <div>
          <Form
              name="recover-password-form"
              onFinish={onFinishRecoverForm}
              form={form1}
          >
            <div className="mt-3 row">
              <div className="col-md-4">
                <p>И-мэйл:</p>
              </div>
              <div className="col-md-8">
                <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "И-мэйл хаяг оруулна уу.",
                      },
                    ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>

    );
  };

  const onFinish = (values: any) => {
    let credentials: LoginCredentials = {
      email: values.email,
      password: values.password,
    };
    authService.login(credentials).then((response) => {
      authService.admissionUserRegisteredList().then(async (res: any)=>{
        console.log("test", res.admissionUserList)
        let registrationUserList: any = []
        res.admissionUserList.map((value: any)=>{
          console.log(value.admission)
          if(value.admission && value.state === "R"){
            registrationUserList.push({
              'admissionId': value.admission?.id,
              'satExam': value.admission?.satExam,
              'state': value.state
            })
          }
          if(value.admission && value.state === "C"){
            registrationUserList.push({
              'admissionId': value.admission?.id,
              'satExam': value.admission?.satExam,
              'state': value.state
            })
          }
        })

        await storeService.setAuthRegister(registrationUserList.filter((value:any) => value !== null))
        if (fromLogin === undefined) {
          navigate("/user/home/main");
          // IOModal.info({
          //   title: "Мэргэжил сонгон баталгаажуулах заавар",
          //   // okText: "Илгээх",
          //   content: <Instruction />,
          //   // okButtonProps: { form: "recover-password-form", htmlType: "submit" },
          // });
        } else {
          navigate(-2);
        }
      })

    });
  };

  const recoverPasswordClick = () => {
    IOModal.confirmForm({
      title: "Нууц үг сэргээх",
      okText: "Илгээх",
      content: <RecoverForm />,
      okButtonProps: { form: "recover-password-form", htmlType: "submit" },
    });
  };
  return (
      <LoginStyle>
        <Form
            className="d-flex justify-content-center"
            layout="vertical"
            name="pre-register-bachelor-form"
            onFinish={onFinish}
            form={form}
        >
          <div className="register-card">
            <div className="register-card-body">
              <h5 className="font-weight-bold">Нэвтрэх</h5>
              <div className="mt-3 panel panel-body login-form w-100">
                <div className="form-group has-feedback has-feedback-left">
                  <Form.Item
                      name="email"
                      label="Цахим шуудан"
                      rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="form-group has-feedback has-feedback-left">
                  <Form.Item
                      label="Нууц үг"
                      name="password"
                      rules={[...validations.ENTER_PASSWORD, ...validations.PASSWORD]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <div className="text-center">
                    <a className="text-info" onClick={recoverPasswordClick}>
                      Нууц үгээ мартсан
                    </a>
                  </div>
                </div>
                <Button className="mt-3 submit-btn" htmlType="submit">
                  Нэвтрэх
                </Button>
                <Button
                    className="mt-3 register-btn"
                    onClick={() => navigate("/register")}
                >
                  Бүртгүүлэх
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </LoginStyle>

  );
}
