import { useEffect, useState } from "react";
import { headerService } from "../../../private/components/PrivateHeader/service/header.service";
import { DegreeGroupUI } from "../../../private/components/PrivateHeader/entity/header.model";
import { Select } from "antd";
import { Collapse } from "antd";
import { RiArrowDownSLine, RiSubtractLine } from "react-icons/ri";
import { informationService } from "../../information/service/information.service";
import { InfoStyle } from "./InfoStyle";
import ProfessionInformationCard from "../../information/components/ProfessionInformationCard";
import RegistrationAccordion from "../../information/components/RegistrationAccordion";
import RegistrationAccordionV1 from "../../information/components/RegistrationAccordionV1";
import CustomLoader from "../../components/CustomLoader";

const { Panel } = Collapse;

const groupProfessions = (originalArray: any) => {
  let newArray: any = [];

  originalArray.forEach((item: any) => {
    const groupName = item.groupName !== null ? item.groupName.name : "None";
    const existingGroup = newArray.find(
      (group: any) => group.name === groupName
    );

    if (existingGroup) {
      existingGroup.data.push(item);
    } else {
      newArray.push({
        name: groupName,
        data: [item],
      });
    }
  });

  if (newArray.length > 1) {
    newArray = newArray.filter((group: any) => group.name !== "None");
  }

  return newArray;
};

export default function Main() {
  const [activeKey, setActiveKey] = useState<any>(0);
  const [degreeGroup, setDegreeGroup] = useState<DegreeGroupUI>();
  const [subDegree, setSubDegree] = useState<any>();
  const [selectedDegree, setSelectedDegree] = useState<any>();

  useEffect(() => {
    headerService.getDegreeGroup().then((response) => {
      setDegreeGroup(response);
      setSubDegree(response.degreeGroups[0].degrees);
      setSelectedDegree(response.degreeGroups[0].degrees[0].id);
      setLoading(false);
    });
  }, []);

  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  const handleDegreeGroupChange = (value: any) => {
    let result = degreeGroup?.degreeGroups.find((item) => item.id === value);
    setSubDegree(result?.degrees);
  };

  const onDegreeChange = (value: any) => {
    setSelectedDegree(value);
  };

  const [professions, setProfessions] = useState<any>();
  const [degreeInfo, setDegreeInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (selectedDegree) {
      informationService.getDegreeInfo(selectedDegree).then((response) => {
        let grouped = groupProfessions(response.professionGroups);
        informationService
          .getDegreeInfoImages(selectedDegree ? selectedDegree : "")
          .then((response) => {
            const { degreeInfos } = response;
            let degreeInformation = Object.entries(degreeInfos).map(
              ([key, value]) => ({ key, value })
            );
            setDegreeInfo(degreeInformation);
            console.log(grouped, "grouped");
            setProfessions(grouped);
          });
      });
    }
  }, [selectedDegree]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-7 align-items-center w-100">
        <CustomLoader />
      </div>
    );
  }

  return (
    <div className="container mb-5">
      <InfoStyle>
        <h1 className="mt-3 mb-4 font-weight-bold">
          Мэргэжил, төлбөрийн мэдээлэл
        </h1>
        <div className="d-flex align-items-center mobile-responsive">
          <h4 className="mr-3">Зэрэг:</h4>
          <Select
            defaultValue={
              degreeGroup?.degreeGroups ? degreeGroup?.degreeGroups[0].id : ""
            }
            style={{ width: 350 }}
            onChange={handleDegreeGroupChange}
            options={degreeGroup?.degreeGroups?.map((data: any) => ({
              label: data.name,
              value: data.id,
            }))}
          />
          <h4 className="mx-lg-3 mobile-responsive-select">Хөтөлбөр:</h4>
          <Select
            style={{ width: 350 }}
            defaultValue={subDegree ? subDegree[0].id : ""}
            onChange={onDegreeChange}
            options={subDegree?.map((val: any) => ({
              label: val.name,
              value: val.id,
            }))}
          />
        </div>
        <div className={"row mt-5"}>
          <div className="col-lg-4 col-xs-12">
            <Collapse onChange={onChange} accordion>
              {professions?.map((element: any, index: any) => {
                return (
                  <Panel
                    header={element.name}
                    key={index}
                    showArrow={false}
                    extra={
                      parseInt(activeKey) === index ? (
                        <RiSubtractLine size={24} />
                      ) : (
                        <RiArrowDownSLine size={24} />
                      )
                    }
                  ></Panel>
                );
              })}
              {degreeInfo?.map((element: any, index: any) => {
                return (
                  <Panel
                    header={element.key}
                    key={index + professions?.length}
                    showArrow={false}
                    extra={
                      parseInt(activeKey) === index + professions?.length ? (
                        <RiSubtractLine size={24} />
                      ) : (
                        <RiArrowDownSLine size={24} />
                      )
                    }
                  ></Panel>
                );
              })}
            </Collapse>
          </div>
          <div className="col-lg-8 col-xs-12 mobile-responsive-8">
            <>
              {professions?.[parseInt(activeKey)] && (
                <>
                  {professions[parseInt(activeKey)]?.data.map(
                    (profession: any) => {
                      return (
                        <ProfessionInformationCard
                          key={profession.id}
                          profession={profession}
                        />
                      );
                    }
                  )}
                </>
              )}
              {degreeInfo &&
                degreeInfo[
                  parseInt(activeKey) - (professions ? professions.length : 0)
                ] && (
                  <>
                    <RegistrationAccordionV1
                      data={
                        degreeInfo[
                          parseInt(activeKey) -
                            (professions ? professions.length : 0)
                        ].value
                      }
                      type={
                        degreeInfo[
                          parseInt(activeKey) -
                            (professions ? professions.length : 0)
                        ].key
                      }
                    />
                  </>
                )}
            </>
          </div>
        </div>
      </InfoStyle>
    </div>
  );
}
