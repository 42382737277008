import storeService from "./store";

const isLoggedIn = () => {
  const token = getJwt();
  return token !== undefined;
};

const getJwt = (): string | undefined => {
  if (storeService.getAuth() !== undefined) {
    return storeService.getAuth()!.token;
  } else {
    return undefined;
  }
};

export const helper = {
  isLoggedIn,
  getJwt,
};
