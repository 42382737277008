import {
  Auth,
  LoginCredentials,
  PasswordRecovery,
  PasswordReset, RegisterConfirm, RegisterUserUI,
} from "../entity/auth.model";
import {api} from "../../../../utils/helper/api";
import storeService from "../../../../utils/helper/store";

async function login(credentials: LoginCredentials): Promise<Auth> {
  let auth: Auth = await api.post("/login", credentials);
  storeService.setAuth(auth);
  return auth;
}

async function register(credentials: RegisterUserUI): Promise<void> {
  return api.post(
      "/register",
      credentials,
  );
}

const registerConfirm = (registerConfirm: RegisterConfirm): Promise<void> => {
  return api.post("/register/confirm", registerConfirm);
};

const recoverPassword = (passwordRecovery: PasswordRecovery): Promise<any> => {
  return api.post("/_security/otp", passwordRecovery);
};

const resetPassword = (passwordRecovery: PasswordReset): Promise<any> => {

  return api.post("/_security/password/reset", passwordRecovery);
};

async function admissionUserRegisteredList(): Promise<Auth> {
  return await api.get("/admission/register/user/list");
}


function logout() {
  // api.get("/auth/logout").then(() => {
  // });
  storeService.clear()
  window.location.href = '/login';
}

export const authService = {
  login,
  logout,
  register,
  recoverPassword,
  registerConfirm,
  resetPassword,
  admissionUserRegisteredList
};
