import logoWhite from "../../../../assets/images/logo-white.png";
import AppStore from "../../../../assets/images/app-store.png";
import PlayStore from "../../../../assets/images/play-store.png";
import {
  RiFacebookFill,
  RiTwitterFill,
  RiLinkedinFill,
  RiInstagramLine,
  RiYoutubeLine,
} from "react-icons/ri";
import { FooterStyle } from "./FooterStyle";

export default function Footer() {
  const currYear = new Date().getFullYear();

  return (
      <FooterStyle>
        <footer className="bg-primary bottom pt-4">
          <div className="footer-content">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-4 col-md-12">
                  <img width={200} height={50} alt="example" src={logoWhite} />
                  <div className="m">
                    <ul>
                      <li>
                        БЗД 1-р хороо, Энхтайваны өргөн чөлөө-5, 13381, Ш/H:
                        Улаанбаатар-49
                      </li>
                      <li>(976) 7000 8085</li>
                      <li>info@ufe.edu.mn</li>
                    </ul>
                    <div className="d-flex mb-2">
                      <a href="https://www.facebook.com/ufe.edu.mn" className="icon-body" target={'_blank'}>
                        <RiFacebookFill size={24} />
                      </a>
                      <a href="https://twitter.com/ufe_edu_mn" className="icon-body" target={'_blank'}>
                        <RiTwitterFill size={24} />
                      </a>

                      <a href="https://www.linkedin.com/school/ufemongolia/?challengeId=AQFJ9qqEC_2eIwAAAYBVHMh5KZJgxtfjCP7jIWerdXlrB1ZzLkDsRQQ7ABz19NW92EmLwKx8NOhTUxI6ujYL8D5JDTtNgVb8wg&submissionId=93b49963-b672-e816-c197-c8766d5cad1e" className="icon-body" target={'_blank'}>
                        <RiLinkedinFill size={24} />
                      </a>
                      <a href="https://www.instagram.com/ufe.edu.mn/" className="icon-body" target={'_blank'}>
                        <RiInstagramLine size={24} />
                      </a>
                      <a href="https://www.youtube.com/c/UFEMedia" className="icon-body" target={'_blank'}>
                        <RiYoutubeLine size={24} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 ">
                  <ul>
                    <a href="/information/instruction">
                      <li className="caption1 font-weight-bold text-white">
                        Элсэгчдэд өгөх зөвлөгөө{" "}
                      </li>
                    </a>
                    <a href="/information/vr">
                      <li className="caption1 font-weight-bold text-white">
                        Виртуал орчин{" "}
                      </li>
                    </a>
                    <a href="https://admission.ufe.edu.mn/Default.aspx?page=133">
                      <li className="caption1 font-weight-bold text-white">
                        Түгээмэл асуулт, хариулт{" "}
                      </li>
                    </a>
                    <a href="/information/contact">
                      <li className="caption1 font-weight-bold text-white">
                        Холбоо барих{" "}
                      </li>
                    </a>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-12">
                  <div className="body1 font-weight-bold">Апп татах</div>
                  <div className="mt-2">
                    <img
                        width={155}
                        height={48}
                        alt="example"
                        src={AppStore}
                        className="my-2 mr-2"
                    />
                    <img
                        width={155}
                        height={48}
                        alt="example"
                        src={PlayStore}
                        className="mr-2"
                    />
                  </div>
                </div>
              </div>
              <div className="copyright">
                <p className="footer-p">© {currYear} UFE. All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </FooterStyle>
  );
}
