import { Button, Modal, notification, Statistic, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SatScore, UserScoreTableUI } from "./entity/userScoreTable.model";
import { UserScoreTableStyle } from "./UserScoreTableStyle";
import Circle from "../circle/Circle";
import storeService from "../../../../../utils/helper/store";
import { useNavigate } from "react-router-dom";
import React from "react";
import { StarFilled } from "@ant-design/icons";

const { Countdown } = Statistic;

export default function UserScoreTable(props: {
  count: number;
  data: UserScoreTableUI[];
  name: string;
  userInfoTable: any;
  setUserInfoTable: any;
  setTableData: any;
  capacity: number;
  minScore: number;
}) {
  let userEmail = storeService.getAuth()?.email;
  let { count, data } = props;
  const navigate = useNavigate();

  const onFinish = (index: any) => {
    let tableData = props.data.map((val) => {
      if (val.index === index) {
        return {
          ...val,
          timeConfirmState: true,
        };
      }
      return {
        ...val,
      };
    });
    props.setTableData(tableData);
  };

  const onFinishUser = () => {
    props.setUserInfoTable({ ...props.userInfoTable, timeConfirmState: true });
  };

  let columns: ColumnsType<UserScoreTableUI> = [
    {
      title: "№",
      dataIndex: "index",
      key: "index",
      render: (index, record) => {
        let colStyle;
        if (record.satCode === undefined) {
          colStyle = {
            background: "#FFE5E5",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderTop: "3px solid #FDED72",
          };
        } else {
          if (userEmail === record.email) {
            colStyle = {
              background: "#CCD6ED",
            };
          } else {
            colStyle = {};
          }
        }
        return {
          props: {
            style: colStyle,
            colSpan: record.status ? 1 : 5,
          },
          children: (
            <>
              {record.status ? (
                <>{index}</>
              ) : (
                <>Хяналтын тоо: {props.capacity}</>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "БҮТ дугаар",
      dataIndex: "satCode",
      width: "40%",
      key: "satCode",
      render: (satCode, record) => {
        return {
          props: {
            colSpan: record.status ? 1 : 0,
            style: { background: userEmail === record.email ? "#CCD6ED" : "" },
          },
          children: <>{satCode}</>,
        };
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "index",
      align: "left",
      render: (status: string, record, index) => {
        let contentChild;
        contentChild = (
          <>
            {index > props.count ? (
              <>
                {status === "pending" ? (
                  record?.averageScore &&
                  props.minScore <= parseInt(record?.averageScore) ? (
                    <>
                      <div className="d-flex align-items-center">
                        <Circle size={"12px"} color={"#ffcc01"} />
                        Хүлээгдэж байгаа
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="d-flex align-items-center">
                        <Circle size={"12px"} color={"#8a8a8b"} />
                        Тэнцээгүй
                      </div>
                    </>
                  )
                ) : (
                  <div className="d-flex align-items-center">
                    <>
                      {record?.averageScore &&
                      props.minScore <= parseInt(record?.averageScore) ? (
                        <>
                          {" "}
                          <Circle size={"12px"} color={"#ffcc01"} />
                          Хүлээгдэж байгаа
                        </>
                      ) : (
                        <>
                          <Circle size={"12px"} color={"#8a8a8b"} />
                          Тэнцээгүй
                        </>
                      )}
                    </>
                  </div>
                )}
              </>
            ) : (
              <>
                {status === "pending" && (
                  <div className="d-flex align-items-center">
                    <Circle size={"12px"} color={"#0132a6"} />
                    Тэнцсэн
                  </div>
                )}
                {status === "accepted" && (
                  <div className="d-flex align-items-center">
                    <Circle size={"12px"} color={"#00a65f"} />
                    Элссэн
                  </div>
                )}
                {status === "declined" && (
                  <div className="d-flex align-items-center">
                    <Circle size={"12px"} color={"#ff0000"} />
                    Цуцласан
                  </div>
                )}
                {status === "canceled" && (
                  <div className="d-flex align-items-center">
                    <Circle size={"12px"} color={"#ff0000"} />
                    Цуцласан
                  </div>
                )}
              </>
            )}
          </>
        );

        return {
          props: {
            colSpan: status ? 1 : 0,
            style: { background: userEmail === record.email ? "#CCD6ED" : "" },
          },
          children: contentChild,
        };
      },
    },
    {
      title: "Мэргэжил баталгаажуулахад доорх хугацаа үлдсэн байна",
      width: "150px",

      dataIndex: "timeConfirm",
      key: "timeConfirm",
      render: (timeConfirm, record) => {
        return {
          props: {
            colSpan: 1,
            style: {
              padding: 0,
              background: userEmail === record.email ? "#CCD6ED" : "",
            },
          },
          children: (
            <div style={{ margin: record.satCode ? "4px" : "0" }}>
              <>
                {record.status ? (
                  timeConfirm ? (
                    record.timeConfirmState ? (
                      <>Нээгдсэн</>
                    ) : (
                      <Countdown
                        format={'HH:mm:00'}
                        value={timeConfirm}
                        onFinish={() => onFinish(record.index)}
                      />
                    )
                  ) : (
                    <StarFilled style={{ color: "#ffcc01" }} />
                  )
                ) : null}
              </>
            </div>
          ),
        };
      },
    },
  ];

  // option.forEach((element: any)=>{
  //     columns.splice(1,0, {
  //         title: element.name,
  //         dataIndex: element.name,
  //         key: element.name,
  //         render: (option: string, record) =>{
  //             let text = "-"
  //             record.satScore?.forEach((value)=>{
  //                 if(element.id === value.satName){
  //                     text = value.score
  //                 }
  //             })
  //             return(
  //                 <>{text}</>
  //             )
  //         }
  //     })
  // })

  return (
    <UserScoreTableStyle>
      <Table
        columns={columns}
        dataSource={data}
        className="table table-responsive"
        pagination={false}
        rowClassName="table-row"
        scroll={{ x: "max-content", y: "100vh" }}
        summary={() => {
          if (props.userInfoTable === undefined) {
            return <></>;
          }
          return (
            <Table.Summary fixed={"top"}>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  {props.userInfoTable.index}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={1}>
                  {props.userInfoTable.satCode}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={1} align="left">
                  <>
                    {props.userInfoTable.index > props.count ? (
                      <>
                        {props.userInfoTable.status === "pending" ? (
                          props.userInfoTable.averageScore &&
                          props.minScore <=
                            parseInt(props.userInfoTable.averageScore) ? (
                            <>
                              {" "}
                              <div className="d-flex align-items-center">
                                <Circle size={"12px"} color={"#ffcc01"} />
                                Хүлээгдэж байгаа
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <Circle size={"12px"} color={"#8a8a8b"} />
                                Тэнцээгүй
                              </div>
                            </>
                          )
                        ) : (
                          <div className="d-flex align-items-center">
                            <Circle size={"12px"} color={"#8a8a8b"} />
                            Тэнцээгүй
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {props.userInfoTable.status === "pending" && (
                          <div className="d-flex align-items-center">
                            <Circle size={"12px"} color={"#0132a6"} />
                            Тэнцсэн
                          </div>
                        )}
                        {props.userInfoTable.status === "accepted" && (
                          <div className="d-flex align-items-center">
                            <Circle size={"12px"} color={"#00a65f"} />
                            Элссэн
                          </div>
                        )}
                        {props.userInfoTable.status === "declined" && (
                          <div className="d-flex align-items-center">
                            <Circle size={"12px"} color={"#ff0000"} />
                            Цуцласан
                          </div>
                        )}
                        {props.userInfoTable.status === "canceled" && (
                          <div className="d-flex align-items-center">
                            <Circle size={"12px"} color={"#ff0000"} />
                            Цуцласан
                          </div>
                        )}
                      </>
                    )}
                  </>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <>
                    {props.userInfoTable.timeConfirm ? (
                      props.userInfoTable.timeConfirmState ? (
                        <>Нээгдсэн</>
                      ) : (
                        <Countdown
                          value={props.userInfoTable.timeConfirm}
                          onFinish={onFinishUser}
                        />
                      )
                    ) : (
                      <StarFilled style={{ color: "#ffcc01" }} />
                    )}
                  </>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </UserScoreTableStyle>
  );
}
