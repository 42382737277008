import React, { useEffect, useState } from "react";
import { informationService } from "../service/information.service";
import CustomLoader from "../../../public/components/CustomLoader";
import {useParams} from "react-router-dom";
import QAAccordion from "../components/QAAccordion";

export default function QA() {
  const [qa, setQa] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    informationService.getDegreeInfoQA(id?.toString()).then((res) => {
      setQa(res);
      setLoading(false);
    });

  }, [id]);
  return (
    <div className="mt-5">
      <h1 className="mt-5 mb-4 font-weight-bold">Түгээмэл асуулт, хариулт</h1>
      {loading ? <CustomLoader /> : <QAAccordion data={qa.qa}/>}
    </div>
  );
}
