import styled from "styled-components";

export const MoreButtonStyle = styled.div`
span{
padding: 10px;
}
a{    
color:#345BB8;
text-decoration:none;
padding-top:4px;

}
`;
